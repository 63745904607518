import { useContext, useState, useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import Database from "backendConnectors/database";
import { AuthContext } from 'backendConnectors/auth'

import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmContext from "ShowConfirmModal";
import SnackContext from "ShowSnackbar";


import Loader from "components/loader";
import EditIcon from "components/icons/Edit";
import ValidFilledIcon from "components/icons/ValidFilled";

import { useComputeDelay } from "components/callsList/callItem";

import { FormsContext } from "GetForms";
import { useDate } from "GetDate";

import { computeTabCompletion } from "components/participate";

const AccountCalls = () => {
    const theme = useTheme()

    const [openProjects, setOpenProjects] = useState(null);
    const [projectsRead, setOpenProjectsRead] = useState(false);

    const [submittedProjects, setSubmittedprojects] = useState(null);
    const [submittedProjectsRead, setSubmittedProjectsRead] = useState(false);

    useEffect(() => {
        const getOpenProjects = async () => {
            try {
                const projects = await Database.getOpenProjects(null, true);
                if ('open_projects' in projects) {
                    setOpenProjects(projects.open_projects)
                    setOpenProjectsRead(true)
                }
            } catch (error) {
                console.error(error)
            }
        }
        getOpenProjects();

        const getSubmittedProjects = async () => {
            try {
                const subProjects = await Database.getSubmittedProjects(null, true);
                if ('submitted_projects' in subProjects) {
                    setSubmittedprojects(subProjects.submitted_projects)
                    setSubmittedProjectsRead(true)
                }
            } catch (error) {
                console.error(error)
            }
        }
        getSubmittedProjects();
    }, [])

    const deleteProject = useCallback(async (projectId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const deletation = await Database.deleteProject(projectId)

                setOpenProjects(op => {
                    const newop = op.filter(k => k.status.projectId !== projectId)
                    return newop
                })
                resolve(deletation);
            }
            catch (e) {
                reject(e)
            }
        })
    }, [])

    return (
        <>
            <Typography variant="h2" color="secondary" >
                <FormattedMessage id="yourApplications" defaultMessage={"Your applications"} />
            </Typography>
            <Box className="dotted purple" sx={{ my: 2 }}></Box>

            {(!projectsRead) ?
                < Loader size={30} />
                :
                <>
                    <Typography color={theme.palette.secondary.main} sx={{ pb: '1rem', mb: '1rem', borderBoborderBottomWidth: '1px', borderBottomColor: theme.palette.gray.light, borderBottomStyle: 'solid' }}>
                        <FormattedMessage id="applicationsInProgress" defaultMessage={"Application(s) in progress"} />

                    </Typography>
                    {Object.keys(openProjects).length > 0 ?
                        <Grid container sx={{
                            mb: 4,
                            '& .MuiGrid-item': {
                                px: { xs: 0, sm: 'max(5px, 1.5%)' },
                                // "&:first-of-type": { pl: 0, pr: { xs: 0, lg: 'max(10px,3%)' } },
                                // "&:last-child": { pl: { xs: 0, lg: 'max(10px,3%)' }, pr: 0 }
                            }
                        }}>

                            {Object.entries(openProjects).sort(
                                ([_, p1], [__, p2]) => {
                                    return p2.status.projectId - p1.status.projectId
                                }
                            ).map(
                                ([pId, pData]) => {

                                    return <ProjectItem key={pData.status.projectId} projectId={pData.status.projectId} data={pData} deleteProject={deleteProject} />
                                })}
                        </Grid>
                        :
                        <Typography color={theme.palette.gray.main} sx={{ mb: 4 }}>
                            <FormattedMessage id="noApplicationsInProgress" defaultMessage={"You do not have any application in progress"} />
                        </Typography>
                    }

                    {
                        submittedProjectsRead && Object.keys(submittedProjects).length > 0 && <>
                            <Typography color={theme.palette.secondary.main} sx={{ pb: '1rem', mb: '1rem', borderBoborderBottomWidth: '1px', borderBottomColor: theme.palette.gray.light, borderBottomStyle: 'solid' }}>
                                <FormattedMessage id="applicationsCompleted" defaultMessage={"Application(s) completed"} />
                            </Typography>

                            <Grid container sx={{
                                '& .MuiGrid-item': {
                                    px: { xs: 0, sm: 'max(5px, 1.5%)' },
                                    // "&:first-of-type": { pl: 0, pr: { xs: 0, lg: 'max(10px,3%)' } },
                                    // "&:last-child": { pl: { xs: 0, lg: 'max(10px,3%)' }, pr: 0 }
                                }
                            }}>

                                {Object.entries(submittedProjects).sort(
                                    ([_, p1], [__, p2]) => {
                                        return (p2.status.submitted_date || 0) - (p1.status.submitted_date || 0)
                                    }
                                ).map(
                                    ([pId, pData]) => {
                                        return <ProjectItem submitted key={pData.status.projectId} projectId={pData.status.projectId} data={pData} />
                                    })}
                            </Grid>


                        </>
                    }
                </>
            }


        </>
    )
}

const ProjectItem = (props) => {
    const intl = useIntl();
    const [deleting, setDeleting] = useState(false);
    const { createConfirm } = useContext(ConfirmContext)
    const { createSnack } = useContext(SnackContext)
    const authContext = useContext(AuthContext);
    const computeDelay = useComputeDelay();

    const theme = useTheme();
    const { forms } = useContext(FormsContext);
    const date = useDate();

    const { data, projectId, submitted } = props;
    const { deleteProject } = props

    const validateDelete = useCallback(() => {
        const deleteCurrentProject = async () => {
            setDeleting(true)
            try {
                await deleteProject(projectId)
                // await Functions.DeleteProject('\\' + projectId + '/')
            }
            catch (e) {
                setDeleting(false)
                console.error(e)
                const message = e.error ?? intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' })
                createSnack(message, { severity: 'error' })
            }
        }

        createConfirm(
            deleteCurrentProject, {
            title: intl.formatMessage({ id: "deleteYourProject", defaultMessage: 'Delete your project' }),
            message: intl.formatMessage({ id: "areYouSure", defaultMessage: "Are you sure?" }),
            cancelText: intl.formatMessage({ id: "cancelDeleteProject", defaultMessage: "Cancel" }),
            confirmText: intl.formatMessage({ id: "confirmDeleteProject", defaultMessage: "Ok" }),
        }
        )
    }, [projectId, createConfirm, createSnack, deleteProject, intl])

    const viewSubmission = useCallback(() => {
        if (!authContext.init || !authContext.user) return;

        Database.viewSubmission(authContext.user.token, projectId);

    }, [projectId, authContext.init, authContext.user])

    const callId = data.status ? data.status.callId : null;

    const formData = callId ? forms.filter(f => f.status.formId === callId)[0] : null;

    const isOpened = formData ? (formData.status.deadline > date && formData.status.openingDate < date) : false
    const delay = isOpened ? computeDelay(formData.status.deadline, date) : ""

    let progress = 0;
    if (isOpened) {

        const tabCompleted = computeTabCompletion(formData, data.data || {}).flat()

        progress = 100 * tabCompleted.reduce((acc, v) => {
            if (v) return acc += 1
            return acc
        }, 0) / tabCompleted.length
    }

    const result = data.status.result ?? "submitted";

    let submittedbuttonColor = "secondary";
    if (result === "accepted") {
        submittedbuttonColor = "green"
    }
    if (result === "rejected") {
        submittedbuttonColor = "red"
    }

    const resultMessage = intl.formatMessage({ id: `result_${result}`, defaultMessage: result });


    return (
        <Grid item xs={12} sm={6} lg={4} sx={{ pb: '1rem' }}>
            <Box sx={{
                display: "flex", flexDirection: 'column', backgroundColor: "#ffffff", borderRadius: '2rem', overflow: 'hidden', pb: 3,
                borderWidth: '1px', borderColor: theme.palette.gray.light, borderStyle: 'solid',
                opacity: deleting ? '0.5' : 1, pointerEvents: deleting ? 'none' : 'all'
            }}>
                <Box sx={{ display: 'flex', pl: 3, alignItems: "center" }}>
                    <Typography color={theme.palette.orange.main} sx={{ flex: 1, fontSize: ".8rem" }}>
                        {delay}
                    </Typography>
                    <Box
                        bgcolor={isOpened ? theme.palette.green.main : theme.palette.pink.main}
                        color="#ffffff"
                        sx={{ px: 2, py: 2, borderRadius: '0 0 0 .5rem' }}
                    >
                        <Typography sx={{ flex: 1 }}>{isOpened ? intl.formatMessage({ id: "open", defaultMessage: 'Open' }) : intl.formatMessage({ id: "closed", defaultMessage: 'Closed' })}</Typography>
                    </Box>

                </Box>

                <Box sx={{ px: 3, pt: 1, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h2" color={theme.palette.secondary.main} sx={{ mb: 2, fontSize: '1.2rem' }}>
                        {formData && formData.status.title}
                    </Typography>
                    <Typography variant="h3" color={theme.palette.secondary.main} sx={{ mb: 2, fontSize: '1rem' }}>
                        ID: {projectId}
                    </Typography>

                    {
                        !submitted && isOpened &&
                        <>
                            <Box sx={{ display: "flex", alignItems: "center", width: '100%', my: 3 }}>

                                <Typography color={theme.palette.gray.main} sx={{ fontSize: '0.7rem', mr: "10px" }}>
                                    {Math.round(progress)}%
                                </Typography>
                                <Box flex="1">
                                    <LinearProgress color="green" variant="determinate" value={progress} sx={{
                                        height: "0.5rem",
                                        borderRadius: 5,
                                        '&.MuiLinearProgress-root': {
                                            backgroundColor: theme.palette.gray.light
                                        },
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: 5,
                                        }
                                        //   `&.${linearProgressClasses.colorPrimary}`: {
                                        //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                                        //   },
                                        //   [`& .${linearProgressClasses.bar}`]: {
                                        //     borderRadius: 5,
                                        //     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                                        //   },
                                    }} />
                                </Box>
                            </Box>

                            <NavLink to={`/participate/${projectId}`} style={{ alignSelf: 'center' }}>
                                <Button color="orange" disableElevation variant="contained" startIcon={<EditIcon />}
                                    sx={{ textTransform: 'none', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>
                                    <FormattedMessage id="edit" defaultMessage={"Edit"} />
                                </Button>
                            </NavLink>
                        </>
                    }
                    {!submitted &&
                        <Button onClick={validateDelete} color="secondary" disableElevation variant="contained" startIcon={<DeleteIcon />}
                            sx={{ alignSelf: 'center', mt: 2, textTransform: 'none', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>
                            <FormattedMessage id="delete" defaultMessage={"Delete"} />
                        </Button>
                    }
                    {submitted &&
                        <>
                            <Button type="button" color={submittedbuttonColor} disableElevation variant="contained" startIcon={result === "submitted" ? <ValidFilledIcon /> : null}
                                sx={{ pointerEvents: "none", alignSelf: 'center', mt: 2, textTransform: 'capitalize', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>
                                {resultMessage}
                            </Button>
                            <Button onClick={viewSubmission} type="button" color={submittedbuttonColor} disableElevation variant="text"
                                sx={{ alignSelf: 'center', mt: 2, textTransform: 'capitalize', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>

                                <FormattedMessage id="viewSubmission" defaultMessage={"View submission"} />
                            </Button>
                        </>
                    }

                </Box>

            </Box >
        </Grid >

    )
}
export default AccountCalls