// import { useState, useEffect } from 'react';
import Auth from './auth';

class backendDatabase {


    createNewProject = async (callId) => {
        const projectData = await Auth.makeAuthenticatedReqest('create_submission', { callId }, 'post');
        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }

    getNumberOfProjects = async () => {
        const projectData = await Auth.makeAuthenticatedReqest('get_number_of_open_projects', {}, 'get');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }
    getOpenProjects = async (callId = null, withData = false) => {
        const data = {}
        if (withData) {
            data.withData = true
        }
        if (callId) {
            data.callId = callId
        }

        const projectData = await Auth.makeAuthenticatedReqest('get_open_projects', data, 'get');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }
    getProject = async (projectId) => {
        const projectData = await Auth.makeAuthenticatedReqest('get_project', { projectId }, 'get');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }

    setProjectData = async (projectId, newData, formDate = null) => {
        const projectData = await Auth.makeAuthenticatedReqest('set_project_data', { projectId, newData, formDate }, 'post');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }
    getSubmittedProjects = async () => {
        const projectData = await Auth.makeAuthenticatedReqest('get_submitted_projects', {}, 'get');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }

    deleteProject = async (projectId) => {
        const projectData = await Auth.makeAuthenticatedReqest('delete_submission', { projectId }, 'post');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }

    uploadFile = async (projectId, questionId, fileElement) => {
        // console.log(questionId, fileElement)

        const fileUploaded = await Auth.makeAuthenticatedReqest('upload_file', { projectId, questionId, file: fileElement }, 'post', true);

        return new Promise((resolve, reject) => {
            if ((fileUploaded.success) && (fileUploaded.data)) {
                resolve(fileUploaded.data)
            }
            reject(fileUploaded)
        })
    }
    downloadFile = async (token, projectId, questionId) => {
        const a = document.createElement('a');
        a.href = process.env.REACT_APP_BACKEND_URL + `/viewpdf?token=${token}&projectId=${projectId}&questionId=${questionId}0`;
        // a.download = filename;
        a.setAttribute('target', "_blank");
        a.setAttribute('rel', "noreferrer");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    submitProject = async (projectId, formDate = null) => {
        const projectData = await Auth.makeAuthenticatedReqest('submit_project', { projectId, formDate }, 'post');

        return new Promise((resolve, reject) => {
            if ((projectData.success) && (projectData.data)) {
                resolve(projectData.data)
            }
            reject(projectData)
        })
    }

    viewSubmission = async (token, projectId) => {
        const a = document.createElement('a');
        a.href = process.env.REACT_APP_BACKEND_URL + `/viewsubmission?token=${token}&projectId=${projectId}`;
        // a.download = filename;
        a.setAttribute('target', "_blank");
        a.setAttribute('rel', "noreferrer");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

}
const Database = new backendDatabase();

export default Database;