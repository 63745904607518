import { useContext, useEffect, useState, memo } from "react";
import { FormattedMessage } from "react-intl";

import { useDate } from "GetDate";
import { AuthContext } from "backendConnectors/auth";
import { FormsContext } from "GetForms";
import Database from "backendConnectors/database";

import { useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import './style.scss'
import CallItem from './callItem';

import ValidationEmailSentFrame from "utilities/validationEmailSentFrame";
import ApplicationSubmittedFrame from "utilities/applicationSubmittedFrame";

const CallItemMemo = memo(CallItem);

const getCalls = (callForms, currentDate, filteredOpened = true) => {
    return callForms.filter(f => {
        const isOpened = filteredOpened ? f.status.deadline >= currentDate : f.status.deadline < currentDate;
        return (f.status.openingDate < currentDate) && isOpened;
    })
};
const getFutureCalls = (callForms, currentDate) => {
    return callForms.filter(f => {
        return (f.status.openingDate > currentDate);
    })
};

const CallsList = () => {
    const [filterCalls, setFilterCalls] = useState(false);
    const date = useDate();

    const { forms } = useContext(FormsContext);
    const location = useLocation();

    const locationState = location.state ?? {};

    const [openedCalls, setOpenedCalls] = useState(getCalls(forms, date, true));
    const [closedCalls, setClosedCalls] = useState(getCalls(forms, date, false));
    const [futureCalls, setFutureCalls] = useState(getFutureCalls(forms, date));

    const { init, user } = useContext(AuthContext)
    const [openProjects, setOpenProjects] = useState(null);
    const [openProjectsRead, setOpenProjectsRead] = useState(false);

    useEffect(() => {
        setOpenedCalls(getCalls(forms, date, true))
        setClosedCalls(getCalls(forms, date, false));
        setFutureCalls(getFutureCalls(forms, date));
    }, [date, forms])

    useEffect(() => {

        if (!init) return;

        if (!user) {
            setOpenProjects([])
            setOpenProjectsRead(true)
            return;
        }

        const getOpenProjects = async () => {
            try {
                const projects = await Database.getOpenProjects();

                if ('open_projects' in projects) {
                    setOpenProjects(projects.open_projects)
                    setOpenProjectsRead(true)
                }
            } catch (error) {
                console.error(error)
            }
        }
        getOpenProjects();
    }, [init, user])

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4 }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%" }}>
                {
                    locationState.showValidationEmailSent && < ValidationEmailSentFrame />
                }
                {
                    locationState.showApplicationSubmitted && < ApplicationSubmittedFrame />
                }
                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    <FormattedMessage id="callsForProposals" defaultMessage={"Calls for proposals"} />
                </Typography>
                <Box className="button-box" sx={{ display: "none", gap: '1rem', alignItems: 'center' }}>
                    <Box >
                        <Button
                            color="primary"
                            variant={filterCalls ? "text" : 'contained'}
                            onClick={() => { setFilterCalls(false) }}
                            sx={{ borderRadius: 2, textTransform: 'capitalize' }}
                        >
                            <FormattedMessage id="allCalls" defaultMessage={"All calls"} />

                        </Button>
                    </Box>
                    <Box sx={{ height: '1rem', width: '1px', background: 'white' }}></Box>
                    <Box >
                        <Button
                            color="primary"
                            variant={filterCalls ? 'contained' : "text"}
                            onClick={() => { setFilterCalls(true) }}
                            sx={{ borderRadius: 2, textTransform: 'capitalize' }}
                        >
                            <FormattedMessage id="openCalls" defaultMessage={"Open calls"} />

                        </Button>
                    </Box>
                </Box>
                <Box className="dotted" sx={{ my: 4 }}></Box>

                {/* Calls */}
                <Box sx={{ display: 'grid', gap: '2rem', gridTemplateColumns: { xs: '1fr', sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' } }}>
                    {openedCalls.map(call => {
                        return <CallItemMemo
                            key={call.status.formId}
                            formId={call.status.formId}
                            deadline={call.status.deadline}
                            date={date}
                            opened={true}
                            openProjects={openProjects}
                            projectsRead={openProjectsRead}
                            title={call.status.title}
                            description={call.status.shortDescription ?? call.status.description}
                        />
                    })}
                    {!filterCalls && futureCalls.map(call => {
                        return <CallItemMemo
                            key={call.status.formId}
                            formId={call.status.formId}
                            deadline={call.status.deadline}
                            openingDate={call.status.openingDate}
                            date={date}
                            opened={false}
                            future
                            title={call.status.title}
                            description={call.status.shortDescription ?? call.status.description}
                        />
                    })}

                    {!filterCalls && closedCalls.map(call => {
                        return <CallItemMemo
                            key={call.status.formId}
                            formId={call.status.formId}
                            deadline={call.status.deadline}
                            date={date}
                            opened={false}
                            title={call.status.title}
                            description={call.status.shortDescription ?? call.status.description}
                        />
                    })}

                </Box>
            </Container>
        </Container>
    )
}


export default CallsList;
export { getCalls };