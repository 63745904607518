import { useState, useEffect, memo, useCallback, Fragment, useRef, useMemo } from "react";

import DOMPurify from 'dompurify'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Loader from "components/loader";

import Question from "./questions/question";
import { MultiSelectSeparator } from "./questions/multiselect"
import { FormattedMessage } from "react-intl";



const QuestionMemo = memo(Question)

const computeQuestionTypeMap = (sec) => {
    const qMap = {}

    if (!sec.pages) return qMap

    sec.pages.forEach(page => {
        if (!page.questions) return

        page.questions.forEach(q => {
            if (!q.id) return
            qMap[q.id] = q.type
        })
    })

    return qMap
}

const isQuestionAnswered = (questionContent, answers, qMap) => {
    if (questionContent.optional) return true;

    if (questionContent.type === "instruction") return true;

    let answer = answers[questionContent.id]

    if (questionContent.type === "select_phone") {
        const phone = answers[`${questionContent.id}_phone`]
        const ext = answers[`${questionContent.id}_phoneExt`]
        if (!phone || !ext) answer = undefined
        else answer = ext + ";/" + phone
    }
    if (!questionContent.conditional) {
        // console.log(questionContent, questionContent.id, questionContent.type, answer)
        return answer !== undefined && answer !== "" && answer !== null
    }

    let isRequired = false
    questionContent.conditional.forEach(qc => {
        if (isRequired) return;

        const subAnswer = answers[qc.field]
        const cond_type = qMap[qc.field];
        const mapped_qc = qc.values.split('\r\n')

        if (cond_type === "multiselect") {

            if (!subAnswer) return false;

            const splittedAnswer = subAnswer.split(MultiSelectSeparator);
            for (const pfa of splittedAnswer) {
                const isin = mapped_qc.includes(pfa)
                if (isin) {
                    isRequired = true;
                    break
                }
            }
        }
        else {
            if (mapped_qc.includes(subAnswer)) isRequired = true;
        }


    })

    if (isRequired) {
        return answer !== undefined && answer !== "" && answer !== null
    }

    return true;
}

const isPageFilled = (currentPageContent, answers, qMap) => {
    const questionAnswered = {};
    for (const page of (currentPageContent.pages || [])) {
        if (!page.questions) continue;
        // repeated pages already handled in actualPagecontent
        for (const questionContent of page.questions) {
            if (!('id' in questionContent)) continue;
            const questionIsAnswered = isQuestionAnswered(questionContent, answers, qMap)
            questionAnswered[questionContent["id"]] = questionIsAnswered
        }

    }
    return questionAnswered;
}


const PageContent = (props) => {

    const { currentPageContent, projectId, setFormUpdated, pageRefresh, downloadFileFromDb, findQuestionType, loading, setLoading } = props
    const { currentAnswers } = props

    const [pageAnswers, setPageAnswers] = useState({})

    const [highlightMissing, setHightlightMissing] = useState(false);

    // const [loading, setLoading] = useState(true);
    const loadedSection = useRef();

    const [actualPageContent, setActualPageContent] = useState({});

    const computeContent = useCallback((pageContent, answers) => {
        const sectionPages = [];

        (pageContent.pages || []).forEach(sp => {
            if (!sp.repeated_page) {
                sectionPages.push(sp)
                return
            }

            if (sp.page_id in answers) {
                answers[sp.page_id].forEach(repeatedId => {
                    const newPage = {
                        ...sp,
                        questions: (sp.questions || []).map(q => {
                            const newQ = { ...q }
                            if (newQ.id) newQ.id = `${newQ.id}_${repeatedId}`
                            if (newQ.conditional) {
                                const conditions = newQ.conditional.map(cond => {
                                    return {
                                        ...cond,
                                        field: cond.field.replace("&", repeatedId)
                                    }
                                })
                                newQ.conditional = conditions;
                            }
                            return newQ
                        }),
                        repeater_id: repeatedId
                    }
                    sectionPages.push(newPage)
                })
            }
            sectionPages.push({
                repeated_page: true,
                aloneTitle: sp.title,
                add_page: true,
                add_page_button_text: sp.add_page_button_text,
                page_id: sp.page_id,
            })
        })

        const actualPageContent = {
            ...pageContent,
            pages: sectionPages
        }

        setActualPageContent(actualPageContent);

        loadedSection.current = pageContent.title || `${Math.floor(Math.random() * 100000)}`
        setLoading(false)
        setHightlightMissing(false);

    }, [setLoading])


    useEffect(() => {
        let timeoutValue = 0
        if (currentPageContent.title !== loadedSection.current) {
            setLoading(true)
            timeoutValue = 250
        }

        const timeout = setTimeout(() => {
            // get all page answers
            const answers = {};
            for (const page of (currentPageContent.pages || [])) {
                let page_repeater = [
                    { ind: null, page }
                ]
                if (page.repeated_page) {
                    answers[page.page_id] = currentAnswers[page.page_id] ? currentAnswers[page.page_id].split(MultiSelectSeparator) : [];
                    page_repeater = answers[page.page_id].map(p_ind => ({ ind: p_ind, page }))
                }

                for (const cur_page of page_repeater) {
                    for (const q of (cur_page.page.questions || [])) {
                        if (!q.id) continue;

                        const qtype = q.type
                        const qid = cur_page.ind ? `${q.id}_${cur_page.ind}` : q.id
                        if (qtype === "select_phone") {
                            const [phoneExt, phone] = (currentAnswers[qid] || ';').split(';')
                            answers[`${qid}_phone`] = phone
                            answers[`${qid}_phoneExt`] = phoneExt
                        }
                        else {
                            answers[qid] = currentAnswers[qid];
                        }
                    }
                }
            }
            // setLoadedSection(currentPageContent.title || `${Math.floor(Math.random() * 100000)}`)
            setPageAnswers(answers)

            computeContent(currentPageContent, answers);

        }, timeoutValue)

        return () => {
            clearTimeout(timeout);
        }
    }, [currentPageContent, projectId, pageRefresh, currentAnswers, computeContent, setLoading])

    const setAnswerAndUpdated = useCallback((value) => {
        setPageAnswers(value)
        setFormUpdated(true)
    }, [setFormUpdated])

    const addRepeatedPage = useCallback((repeated_page_id) => {
        setFormUpdated(true);
        let newPageAnswers = null;
        setPageAnswers(curPageAnswers => {
            const currentPagesId = curPageAnswers[repeated_page_id] || []

            let base = currentPagesId.length + 1
            while (currentPagesId.includes(`${base}`)) {
                base += 1
            }
            currentPagesId.push(`${base}`)

            newPageAnswers = {
                ...curPageAnswers,
                [repeated_page_id]: currentPagesId
            }
            return newPageAnswers
        })
        computeContent(currentPageContent, newPageAnswers);

    }, [setFormUpdated, computeContent, currentPageContent])

    const removeRepeatedPage = useCallback((repeated_page_id, repeater_id) => {
        setFormUpdated(true);
        let newPageAnswers = null;
        setPageAnswers(curPageAnswers => {
            const currentPagesId = curPageAnswers[repeated_page_id] || [];

            if (!(currentPagesId.includes(repeater_id))) {
                return curPageAnswers
            }

            currentPagesId.splice(currentPagesId.indexOf(repeater_id), 1)
            newPageAnswers = {
                ...curPageAnswers,
                [repeated_page_id]: currentPagesId
            }
            return newPageAnswers
        })
        computeContent(currentPageContent, newPageAnswers);
    }, [setFormUpdated, computeContent, currentPageContent])


    // highlight missing

    const questionMap = useMemo(() => computeQuestionTypeMap(actualPageContent), [actualPageContent]);
    const pageFilled = useMemo(() => isPageFilled(actualPageContent, pageAnswers, questionMap), [actualPageContent, pageAnswers, questionMap]);

    const showMissing = useCallback(() => {
        setHightlightMissing(true);
        for (const [questionId, questionAnswered] of Object.entries(pageFilled)) {
            if (questionAnswered) continue;

            const element = document.querySelector(`#${questionId}`);

            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
                break
            }
        }
    }, [pageFilled])


    // return of not loaded
    if (loading)
        return <Loader thickness={5} size={40} />

    return (
        <>
            {Object.values(pageFilled).indexOf(false) >= 0 &&
                <Box mb={"1rem"}>
                    <Button disableElevation type="button" color="orange" variant='contained'
                        sx={{
                            fontWeight: 700, px: '1rem', py: '.5rem', fontSize: '.7rem',
                            display: "block"
                        }}
                        onClick={showMissing}
                    >
                        <FormattedMessage id="showMissingAnswers" defaultMessage={"Highlight missing answers"} />
                    </Button>
                </Box >
            }

            {(actualPageContent.pages || []).filter(p => (p.repeated_page && p.add_page)).map((page) => {
                return <TextField
                    key={page.page_id}
                    id={page.page_id}
                    name={page.page_id}
                    sx={{ display: 'none' }}
                    inputProps={{
                        value: (pageAnswers[page.page_id] || []).join(MultiSelectSeparator)
                    }}
                />
            })
            }
            {
                (actualPageContent.pages || []).map((page, pageIndex) => {
                    return (
                        <Box key={pageIndex} display={"grid"} px={page.repeated_page ? 2 : 0} py={page.repeated_page ? 2 : 0} sx={(theme) => ({
                            gridColumnGap: '1rem', gridTemplateColumns: "repeat(12,1fr)",
                            "& > *:first-of-type": { mt: pageIndex === 0 ? 0 : 3, },
                            border: page.repeated_page && !page.add_page ? `1px solid ${theme.palette.gray.light}` : "none",
                        })}>

                            {page.repeated_page && page.add_page &&
                                <Box sx={{ gridColumn: "1/-1", }}>
                                    {page.aloneTitle && pageIndex === 0 &&
                                        <>
                                            <Box sx={{ display: "flex" }}>
                                                <Typography variant="h2" color="secondary" >{page.aloneTitle}</Typography>
                                            </Box>
                                            <Box className="dotted purple" sx={{ my: 2, mb: 2 }}></Box>
                                        </>
                                    }
                                    <Button disableElevation type="button" color="green" variant='contained'
                                        sx={{
                                            fontWeight: 700, px: '3rem', py: '.5rem', fontSize: '.7rem',
                                            margin: "auto", display: "block"
                                        }}
                                        onClick={() => { addRepeatedPage(page.page_id) }}
                                    > {page.add_page_button_text || "Add"}</Button>
                                </Box>
                            }
                            {(page.title || page.text) &&
                                <Box sx={{ gridColumn: "1/-1" }}>
                                    {page.title &&
                                        <>
                                            <Box sx={{ display: "flex" }}>
                                                <Typography variant="h2" color="secondary" >{page.title}</Typography>
                                            </Box>
                                            <Box className="dotted purple" sx={{ my: 2, mb: 0 }}></Box>
                                        </>
                                    }
                                    {page.text && <Typography sx={(theme) => {
                                        return {
                                            color: theme.palette.gray.main,
                                            mt: 2,
                                            fontSize: ".9rem",
                                            "& a": {
                                                color: theme.palette.secondary.main,
                                                textDecoration: 'underline'
                                            }
                                        }
                                    }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page.text) }}>

                                    </Typography>}
                                </Box>
                            }

                            {page.repeated_page && !page.add_page &&
                                <Box display="flex" sx={{ marginTop: 0, gridColumn: "1/-1" }}>
                                    <Button disableElevation type="button" color="red" variant='contained'
                                        sx={{
                                            fontWeight: 700, px: '3rem', py: '.5rem', fontSize: '.7rem',
                                            marginLeft: "auto"
                                        }}
                                        onClick={() => { removeRepeatedPage(page.page_id, page.repeater_id) }}
                                    > {page.remove_page_button_text || "Add"}</Button>
                                </Box>
                            }

                            {page.questions && page.questions.map((q, qind) => {
                                // console.log(q.id)
                                let conditionaly = true
                                if (q.conditional) {
                                    const conditionSatisified = q.conditional.map(cond => {
                                        const mapped_cond = cond.values.split('\r\n');

                                        const cond_type = findQuestionType(cond.field);

                                        const pageField = pageAnswers[cond.field] ?? null;
                                        const currentAnswerField = currentAnswers[cond.field] ?? null;

                                        const actualAnswer = pageField !== null ? pageField : currentAnswerField;


                                        if (actualAnswer) {
                                            if (cond_type === "multiselect") {
                                                const splittedAnswer = actualAnswer.split(MultiSelectSeparator);
                                                for (const pfa of splittedAnswer) {
                                                    const isin = mapped_cond.includes(pfa)
                                                    if (isin) return true
                                                }
                                                return false;
                                            }
                                            else if (cond_type === "checkbox") {
                                                if (actualAnswer) return true;
                                                return false;
                                            }
                                            else {
                                                return mapped_cond.includes(actualAnswer)
                                            }
                                        }

                                        return false
                                    })
                                    // console.log(conditionSatisified)
                                    // AND statement for conditions
                                    // conditionaly = conditionSatisified.indexOf(false) < 0
                                    // OR statement for conditions
                                    conditionaly = conditionSatisified.indexOf(true) >= 0
                                }
                                let answer = { answer: pageAnswers[q.id] ?? null }
                                if (q.type === "select_phone") {
                                    answer = {
                                        phone: pageAnswers[`${q.id}_phone`],
                                        ext: pageAnswers[`${q.id}_phoneExt`],
                                    }
                                }

                                const addProps = {};
                                if (q.type === "file") {
                                    addProps['downloadFileFromDb'] = downloadFileFromDb
                                }

                                // q.isFilled = AA.toString()
                                const qId = q.type === "instruction" ? qind : q.id;
                                if (conditionaly) {
                                    return <QuestionMemo key={qId} question={q} setAnswer={setAnswerAndUpdated} {...answer} {...addProps} error={highlightMissing && Object.keys(pageFilled).includes(q.id) && !pageFilled[q.id]} />
                                }
                                else { return <Fragment key={qId} /> }
                            }
                            )}
                        </Box >
                    )
                })
            }
        </>
    )
}

export default PageContent;
