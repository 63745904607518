
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import EnveloppeIcon from "components/icons/Enveloppe";


const PasswordResetEmailSentFrame = () => {
    const theme = useTheme()
    return <Box p={4} sx={{
        backgroundColor: '#ffffff', textAlign: 'center', borderRadius: '2rem',
        maxWidth: '500px',
        marginInline: 'auto'
    }}>
        <Box display={"inline-flex"} mb={2} borderRadius={'50%'} p={1.5} sx={{ backgroundColor: theme.palette.green.main }}>
            <EnveloppeIcon color={"white"} />
        </Box>
        <Typography variant="h2" color="secondary">
            <FormattedMessage id="passwordLinkSent" defaultMessage={"New password link sent!"} />
        </Typography>
        <Box className="dotted purple" sx={{ my: 3 }}></Box>
        <Typography color={theme.palette.gray.main}>
            <FormattedMessage id="passwordLinkMessage" defaultMessage={"We sent you a link to change your password."} />

        </Typography>
        <Typography color={theme.palette.gray.main}>
            <FormattedMessage id="checkSpam" defaultMessage={"If you didn’t receive it, please check your spam folder."} />
        </Typography>

    </Box>
}

export default PasswordResetEmailSentFrame

// {
//     locationState.message &&
//     <Box sx={{ px: 3, py: 2, mt: 2, mb: 3, backgroundColor: "#ffffff", borderRadius: '1rem', overflow: 'hidden' }}>
//         <Typography sx={{ whiteSpace: 'break-spaces' }}>{locationState.message}</Typography>
//     </Box>
// }