import { createContext, useState, useEffect } from 'react';

class BackendAuth {

    constructor() {
        this.user = null;
    }

    SignIn = async ({ email, password, name, surname, phone }) => {
        const signinRequest = await fetch(
            process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + "backend_create_user", {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ email, password, name, surname, phone }),
        })
        const request_status = signinRequest.status;
        const signingResponse = await signinRequest.json();

        return new Promise((resolve, reject) => {
            const responseData = signingResponse.data || {};
            if (request_status !== 200 || (signingResponse.statusCode && signingResponse.statusCode !== 200)) {
                console.error(signingResponse);
                reject({
                    code: responseData.errorCode || undefined,
                    message: responseData.errorMessage || "An error occured",
                })
                return
            }
            resolve(responseData);
        })
    }

    LogIn = async ({ email, password }) => {

        const fetchParams = new FormData();
        // fetchParams.append('action', 'backend_try_to_login');
        fetchParams.append('username', email);
        fetchParams.append("password", password);

        const loginRequest = await fetch(
            process.env.REACT_APP_BACKEND_REST_URL + "jwt-auth/v1/token", {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(fetchParams),
        })

        const request_status = loginRequest.status;
        const response = await loginRequest.json();

        return new Promise((resolve, reject) => {
            const responseData = response.data || {};

            if (request_status !== 200 || !response.success) {
                // console.log('throwing')
                this.user = null;
                this.__PropageUpdateUser(this.user);
                this.__deleteToken();

                let message = "An error occured";
                let code = undefined;
                if (response.code && response.code === "incorrect_password") {
                    message = "Invalid credentials";
                    code = "auth/invalid-email";
                }
                return reject({ code, message, })
            }

            this.user = response.data;
            this.__PropageUpdateUser(this.user);
            this.__saveToken(response.data.token);
            resolve(responseData);
        })
    }

    __saveToken = (token) => {
        localStorage.setItem('auth', token)
    }
    __getToken = () => {
        return localStorage.getItem('auth') || null;
    }
    __deleteToken = () => {
        localStorage.removeItem('auth');
    }

    __init = async () => {
        const token = this.__getToken();

        if (!token) {
            this.user = null;
        } else {
            this.user = { token: token };
            try {
                await this.makeAuthenticatedReqest("current_user");
            } catch (e) {
                console.error("in init", e);
            }
        }
        return this.user;
    }

    __cleanup = () => {
        this.user = null;
        this.__deleteToken();
        this.__PropageUpdateUser();
    }

    makeAuthenticatedReqest = async (endpoint, params, method = "get", isFileUpload = false) => {

        if (!this.user || !this.user.token) {
            throw new Error("Authenticated requests need a token")
        }

        const token = this.user.token;

        const requestParams = {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        };
        if (isFileUpload) {
            const formData = new FormData();
            Object.entries(params).forEach(([k, v]) => formData.append(k, v))

            requestParams.body = formData;
        }
        else {
            if (params) {
                requestParams.headers['Content-Type'] = 'application/x-www-form-urlencoded';

                if (method === "post") {
                    requestParams.body = new URLSearchParams(params)
                }
            }
        }

        let url = process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + endpoint;

        if (method === "get") {
            url += `?${new URLSearchParams(params)}`
        }

        const request = await fetch(url, requestParams)

        const request_status = request.status;
        const response = await request.json();

        // console.log(response)

        if (request_status !== 200 || (response.statusCode && response.statusCode !== 200)) {
            console.warn("Request identification failed")
            this.__cleanup();
            return {};
        }

        const api_response = response.response;
        const api_user = response.user;

        let hasChanged = false;
        Object.entries(api_user).forEach(([u_key, u_value]) => {
            if (this.user[u_key] !== u_value) {
                hasChanged = true;
                this.user[u_key] = u_value
            }
        })

        if (hasChanged) {
            this.__PropageUpdateUser();
        }
        return api_response;
    }

    SendValidationEmail = async () => {
        const response = await this.makeAuthenticatedReqest("request_validation_email", {}, "post");
        return response;
    }

    ApplyEmailValidation = async ({ uid, actionCode }) => {
        // const response = await this.makeAuthenticatedReqest("backend_validate_email", { uid, actionCode }, "post");
        // return response;
        const validationRequest = await fetch(
            process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + "backend_validate_email", {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ uid, actionCode }),
        })
        const request_status = validationRequest.status;
        const response = await validationRequest.json();

        return new Promise((resolve, reject) => {
            if (request_status !== 200 || (response.statusCode && response.statusCode !== 200)) {
                console.error(response);
                reject({
                    code: response.code || undefined,
                    message: response.message || "An error occured",
                })
                return
            }
            resolve(response);
        })
    }

    SendPasswordResetEmail = async ({ email }) => {
        // const response = await this.makeAuthenticatedReqest("backend_validate_email", { uid, actionCode }, "post");
        // return response;
        const passwordRequest = await fetch(
            process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + "backend_password_reset_email", {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ email }),
        })
        const request_status = passwordRequest.status;
        const response = await passwordRequest.json();

        return new Promise((resolve, reject) => {
            if (request_status !== 200 || (response.statusCode && response.statusCode !== 200)) {
                console.error(response);
                reject({
                    code: response.code || undefined,
                    message: response.message || "An error occured",
                })
                return
            }
            resolve(response);
        })
    }

    VerifyPasswordResetCode = async ({ uid, actionCode }) => {
        // const response = await this.makeAuthenticatedReqest("backend_validate_email", { uid, actionCode }, "post");
        // return response;
        const validationRequest = await fetch(
            process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + "backend_verify_password_request", {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ uid, actionCode }),
        })
        const request_status = validationRequest.status;
        const response = await validationRequest.json();

        return new Promise((resolve, reject) => {
            if (request_status !== 200 || (response.statusCode && response.statusCode !== 200)) {
                console.error(response);
                reject({
                    code: response.code || undefined,
                    message: response.message || "An error occured",
                })
                return
            }
            resolve(response);
        })
    }

    ConfirmPasswordReset = async ({ uid, actionCode, newPassword }) => {
        // const response = await this.makeAuthenticatedReqest("backend_validate_email", { uid, actionCode }, "post");
        // return response;
        const confirmRequest = await fetch(
            process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + "backend_confirm_password_request", {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ uid, actionCode, newPassword }),
        })
        const request_status = confirmRequest.status;
        const response = await confirmRequest.json();

        return new Promise((resolve, reject) => {
            if (request_status !== 200 || (response.statusCode && response.statusCode !== 200)) {
                console.error(response);
                reject({
                    code: response.code || undefined,
                    message: response.message || "An error occured",
                })
                return
            }
            resolve(response);
        })
    }

    UpdatePassword = async ({ currentPassword, newPassword }) => {
        const response = await this.makeAuthenticatedReqest("update_password", { currentPassword, newPassword }, "post");

        if (response.error) return response;


        if (!response.success || response.success !== true) {
            return {
                error: "An error occured, please try again later."
            }
        }
        if (response.success && response.token) {
            this.user.token = response.token;
            this.__saveToken(response.token);
            this.__PropageUpdateUser(this.user);
        }
        return response
    }


    UpdateProfile = async ({ name, surname, phone }) => {
        const response = await this.makeAuthenticatedReqest("update_profile", { name, surname, phone }, "post");

        return new Promise((resolve, reject) => {
            if (response.success) return resolve(response)
            return reject(response);
        })
    }

    __PropageUpdateUser = () => {
        const updateProfileEvent = new CustomEvent("updateUser",
            {
                bubbles: false,
                cancelable: true,
                composed: false,
                detail: this.user,
            });

        document.dispatchEvent(updateProfileEvent);
    }

    doSignOut = async () => {
        // await signOut(this.auth);
        this.__cleanup();
        this.__PropageUpdateUser();
        return;
    };

}
const Auth = new BackendAuth();

const AuthContext = createContext();

const AuthProvider = (props) => {

    const [authState, setAuthState] = useState({
        init: false,
        user: null
    });

    /* init */
    useEffect(() => {

        if (authState.init) return

        const internal_init = async () => {
            const user = await Auth.__init();

            setAuthState({
                init: true, user: user
            })
        }
        internal_init();


    }, [authState.init])

    /* auth state change */
    useEffect(() => {
        // const authSub = onAuthStateChanged(Auth.auth, (user) => {
        //     if (user) {
        //         setAuthState(
        //             (curAuth) => { return { ...curAuth, init: true, user } })
        //         Firestore.snapshotOpenProjects(user.uid);
        //         Firestore.snapshotSubmittedProjects(user.uid);
        //     } else {
        //         setAuthState(
        //             (curAuth) => { return { ...curAuth, init: true, user: null } })
        //         Firestore.cleanUp()
        //     }
        // });


        const triggerUpdateUser = (e) => {
            setAuthState(curAuth => {
                let newUser;
                if (e.detail) {
                    newUser = { ...curAuth.user, ...e.detail };
                } else {
                    newUser = null;
                }


                return { ...curAuth, user: newUser }
            })
        }
        document.addEventListener('updateUser', triggerUpdateUser)

        return () => {
            // authSub();
            document.removeEventListener('updateUser', triggerUpdateUser)
        }
    }, []);

    return <AuthContext.Provider value={authState}>{props.children}</AuthContext.Provider>;
};

export default Auth;
export { AuthProvider, AuthContext }