import { useTheme } from '@mui/material/styles';
// import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';

import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';


const InputSelect = (props) => {
    const { id, options, label, text, value, setValue, optional, error } = props

    const formattedOptions = options.split('\r\n');
    const inputValue = value || ""

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }

    return (
        <FormControl id={id} error={!!error} variant="standard" color="primary" fullWidth sx={{ mt: 2 }}>
            <FormControl fullWidth>
                {text && <Typography color={theme.palette.gray.main} fontSize={"0.8rem"} sx={{ mb: 1 }}>{text}</Typography>}
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <InputLabel className="question-title" htmlFor={`select-${id}`} sx={{ whiteSpace: "pre-line", transform: "none", position: 'relative', fontSize: "0.8rem", color: !!error ? theme.palette.error.main : theme.palette.gray.main }}>
                    {label}{!optional && "*"}
                </InputLabel>
                <Select
                    // labelId="demo-simple-select-label"
                    inputProps={{
                        id: `select-${id}`
                    }}

                    name={id}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                    // label="Age"
                    // onChange={handleChange}
                    className="input-border-radius"
                    sx={{
                        mt: 1,
                        // borderRadius: ".6rem",
                        '& .MuiSelect-select': {
                            px: '10px', py: '5px', pr: 'calc(24px + 2*8px)!important', position: 'relative',
                            '&:after': { content: `''`, position: 'absolute', top: '20%', right: "calc(24px + 8px)", width: "1px", height: '60%', backgroundColor: theme.palette.gray.light }
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.primary.main, borderWidth: '1px' }
                    }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: '70vh' } } }}
                >
                    {formattedOptions.map && formattedOptions.map(o => {
                        if (o.startsWith('LH-')) {
                            return <ListSubheader key={o}>{o.substr(3)}</ListSubheader>
                        }
                        else {
                            return <MenuItem key={o} value={o} sx={{ maxWidth: '50vw', whiteSpace: "break-spaces", margin: "auto" }}>{o}</MenuItem>
                        }

                    })
                    }

                </Select>
            </FormControl>

        </FormControl >
    )
}

export default InputSelect;