import DOMPurify from 'dompurify'
import { useTheme } from '@mui/material/styles';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';

const InputDate = (props) => {
    const { id, label, value, setValue, optional, error } = props

    const inputValue = value || null;

    const inputLabel = label + (!optional && "*")

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }

    const dateValue = dayjs(inputValue, "DD / MM / YYYY");
    // console.log({ value, dateValue })

    return (
        <FormControl error={!!error} variant="standard" fullWidth sx={{ mt: 2 }}>
            <FormLabel id={`label_${id}`}>
                <Typography className="question-title" color={!!error ? theme.palette.error.main : theme.palette.gray.main} fontSize={".8rem"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputLabel) }}></Typography>
            </FormLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DatePicker
                    name={`label_${id}`}
                    id={id}
                    value={dateValue}
                    onChange={(newValue) => setInputValue(newValue)}
                    slotProps={{
                        textField: {
                            inputProps: {
                                id,
                                name: id
                            }
                        }
                    }}
                    className="input-border-radius"
                    sx={{
                        pt: 1,
                        "& .MuiInputBase-root": {
                            border: `1px solid ${theme.palette.gray.light}`,
                            // borderRadius: ".6rem"
                        },
                        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                        "& .MuiInputBase-input": {
                            px: '10px', py: '5px',
                        }
                    }} />
                <Typography color={theme.palette.gray.main} textAlign={"right"} fontSize={".6rem"} mt={'4px'}>format: DD/MM/YYYY</Typography>
            </LocalizationProvider>

        </FormControl >
    )
}

export default InputDate;

