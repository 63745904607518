import SvgIcon from '@mui/material/SvgIcon';

const EnveloppeOutlinedIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M21.563,6H5.063A2.06,2.06,0,0,0,3.01,8.063L3,20.438A2.069,2.069,0,0,0,5.063,22.5h16.5a2.069,2.069,0,0,0,2.063-2.062V8.063A2.069,2.069,0,0,0,21.563,6Zm0,14.438H5.063V10.125l8.25,5.156,8.25-5.156Zm-8.25-7.219L5.063,8.063h16.5Z" transform="translate(-3.5 -5), scale(1.164)" fill="currentColor" />
        </SvgIcon>
    );
}

export default EnveloppeOutlinedIcon;