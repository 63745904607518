import { useCallback, useContext } from "react";
import { AuthContext } from 'backendConnectors/auth'
import { FormattedMessage, useIntl } from "react-intl";

import { Link, useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import UserBoldIcon from "components/icons/UserBold";
import EnveloppeOutlinedIcon from "components/icons/EnveloppeOutlined";
import EditIcon from "components/icons/Edit";
import UpArrowIcon from "components/icons/UpArrow";
import DoneIcon from '@mui/icons-material/Done';

import Database from "backendConnectors/database";

import BackdropContext from "ShowBackdrop";
import SnackContext from "ShowSnackbar";
import Loader from "components/loader";

const useComputeDelay = () => {
    const intl = useIntl();

    const computeDelay = useCallback((deadline, date, future = false) => {
        // min / hour(<40) / day(<7) / weeks(<12) / months
        const delay = deadline - date;

        if (delay < 0) return `${intl.formatMessage({ id: "closed", defaultMessage: "Closed" })}: ${deadline.toLocaleDateString()}`;

        let message;
        let gender = 'F';   // masculin-feminin
        let number = 'S';   // singulier-pluriel
        const minutes = delay / 1000 / 60;
        const hours = minutes / 60;
        const days = hours / 24
        const weeks = days / 7
        const months = days / 30

        if (minutes < 60) {
            message = `${Math.floor(minutes)} ${intl.formatMessage({ id: "min", defaultMessage: "min" })}}.`
            if (minutes > 1) number = 'P';
        }
        // return future?`Opening in ${Math.floor(minutes)} min.`:`${Math.floor(minutes)} min. left`

        else if (hours < 40) {
            message = `${Math.floor(hours)} ${intl.formatMessage({ id: "hour", defaultMessage: "hour" })}${hours >= 2 ? 's' : ''}`
            if (hours > 1) number = 'P';
        }
        // return `${Math.floor(hours)} hour${hours >= 2 ? 's' : ''} left`

        else if (days < 7) {
            message = `${Math.floor(days)} ${intl.formatMessage({ id: "day", defaultMessage: "day" })}${days >= 2 ? 's' : ''}`
            if (days > 1) number = 'P';
            gender = 'M'
        }
        // return `${Math.floor(days)} day${days >= 2 ? 's' : ''} left`

        else if (weeks < 12) {
            message = `${Math.floor(weeks)} ${intl.formatMessage({ id: "week", defaultMessage: "week" })}${weeks >= 2 ? 's' : ''}`
            if (weeks > 1) number = 'P'
        }
        // return `${Math.floor(weeks)} week${weeks >= 2 ? 's' : ''} left`

        else {
            message = `${Math.floor(months)} ${months >= 2 ? intl.formatMessage({ id: "months", defaultMessage: "months" }) : intl.formatMessage({ id: "month", defaultMessage: "month" })}`
            if (months > 1) number = 'P';
            gender = 'M'
        }
        // return `${Math.floor(months)} month${months >= 2 ? 's' : ''} left`
        const leftMessage = intl.formatMessage({ id: `left${gender}${number}`, defaultMessage: "left" })
        return future ? `${intl.formatMessage({ id: "openingIn", defaultMessage: "Opening in" })} ${message}` : `${message} ${leftMessage}`

    }, [intl])

    return computeDelay;
}


const useCreateNewCall = () => {
    const { setOpenBackdrop } = useContext(BackdropContext)
    const { createSnack } = useContext(SnackContext)
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const intl = useIntl();

    const createNewCall = async ({ formId }) => {
        if (authContext.user) {
            if (authContext.user.emailVerified) {
                setOpenBackdrop(true)

                try {
                    const project_created = await Database.createNewProject(formId);

                    if (!project_created.submission_id) {
                        throw new Error()
                    }
                    // const project_created = await Functions.CreateNewProject(formId);
                    // Firestore.updateProjectData(project_created.data.id, project_created.data.doc)
                    navigate(`/participate/${project_created.submission_id}`)
                }
                catch (e) {
                    console.error(e)
                    // let message;
                    // switch (e.code) {
                    //     case ('functions/invalid-argument'):
                    //         message = e.message;
                    //         break
                    //     default:
                    //         message = 'An unexpected error occured.\nPlease try again'
                    // }
                    const message = e.error ?? intl.formatMessage({ id: "somethingWrong", defaultMessage: "An unexpected error occured.\nPlease try again" })
                    createSnack(message, { severity: 'error' })
                }
                finally {
                    setOpenBackdrop(false)
                }
            }
            else {
                navigate('/requestValidateEmail')
            }
        }
        else {
            navigate('/login')
        }
    }

    return createNewCall;
}

const StartCallButton = (props) => {

    const authContext = useContext(AuthContext);
    const createNewCall = useCreateNewCall();
    const navigate = useNavigate();
    const intl = useIntl();

    const { openProjects, projectsRead } = props;

    const getButtonTextFromStatus = useCallback((user, myProjects) => {

        if (!myProjects) return <Loader size={20} />//<Loader startColor="#ffffffbb" stopColor="#ffffff50" size={20} />

        if (!user) return intl.formatMessage({ id: 'loginToApply', defaultMessage: "Login to apply" })
        if (!user.emailVerified) return intl.formatMessage({ id: "confirmYourEmail", defaultMessage: "Confirm your email" })

        if (myProjects.exists) return intl.formatMessage({ id: "editSubmission", defaultMessage: "Edit submission" })

        return intl.formatMessage({ id: "applyNow", defaultMessage: "Apply now" })
    }, [intl])

    if (!authContext.init) return

    const appliedProjects = Object.entries(openProjects).filter(
        ([pId, pData]) => (pData.status ?? {}).callId === props.formId
    )

    const createProjectOrContinue = () => {
        if (!projectsRead) return;
        const firstProjectId = appliedProjects.length > 0 ? ((appliedProjects[0][1] || {}).status || {}).projectId || null : null
        appliedProjects.length <= 0 ?
            createNewCall({ formId: props.formId }) :
            navigate(`/participate/${firstProjectId}`);
    }

    let buttonColor = "secondary"
    let buttonIcon = <DoneIcon />;
    if (!authContext.user) {
        buttonIcon = <UserBoldIcon />
    }
    else if (!authContext.user.emailVerified) {
        buttonColor = "pink"
        buttonIcon = <EnveloppeOutlinedIcon />
    }
    else if (appliedProjects.length <= 0) {
        buttonIcon = <UpArrowIcon />
    }
    else {
        buttonColor = "orange"
        buttonIcon = <EditIcon />
    }
    if (!projectsRead) {
        buttonIcon = null;
    }

    return <Button
        // onClick={() => createNewCall({ formId: props.formId })}
        onClick={createProjectOrContinue}
        color={buttonColor}
        variant="contained"
        startIcon={buttonIcon}
        disableElevation
        sx={{ maxWidth: '80%', textTransform: 'none', px: 4, py: 1, borderRadius: '.8rem', ...props.sx }}
    >
        {getButtonTextFromStatus(authContext.user, projectsRead ? (appliedProjects.length > 0 ? { exists: true } : {}) : null)}
    </Button>
}

const CallItem = (props) => {
    // const authContext = useContext(AuthContext);
    const computeDelay = useComputeDelay();
    const intl = useIntl()

    const theme = useTheme();

    const delay = props.future ? computeDelay(props.openingDate, props.date, true) : computeDelay(props.deadline, props.date);

    return (
        <Box className={[
            "bordered",
            "call-item",
            props.opened ? "" : "disabled"
        ].join(' ')} sx={{ display: "flex", flexDirection: 'column', backgroundColor: "#ffffff", borderRadius: '1rem', overflow: 'hidden', pb: 3 }}>
            <Box sx={{ display: 'flex', pl: 3, alignItems: "center" }}>
                <Typography color={props.future ? theme.palette.primary.main : theme.palette.orange.main} sx={{ flex: 1, fontSize: ".8rem" }}>{delay}</Typography>
                <Box
                    bgcolor={props.opened ? theme.palette.green.main : theme.palette.pink.main}
                    color="#ffffff"
                    sx={{ px: 3, py: 3, borderRadius: '0 0 0 .5rem' }}
                >
                    <Typography sx={{ flex: 1 }}>{props.opened ? intl.formatMessage({ id: "open", defaultMessage: "Opened" }) : intl.formatMessage({ id: "closed", defaultMessage: "Closed" })}</Typography>
                </Box>

            </Box>

            <Box sx={{ px: 3, pt: 1 }}>
                <Typography variant="h2" color={theme.palette.secondary.main} sx={{ mb: 2 }}>{props.title}</Typography>
                <Typography color={theme.palette.gray.main} sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}>{props.description}</Typography>
                <Link to={"/call/" + props.formId} >
                    <Button color='secondary' variant="text" sx={{ mb: 3, pl: 0, textAlign: 'left' }}> <FormattedMessage id="readMore" defaultMessage={"Read More"} /> {'>'}</Button>
                </Link>
            </Box>
            {
                props.opened &&
                <Box sx={{ textAlign: 'center', mt: 'auto' }}>
                    <StartCallButton formId={props.formId} openProjects={props.openProjects ?? []} projectsRead={props.projectsRead ?? false} />
                </Box>
            }

        </Box >

    )
}


export default CallItem;
export { useComputeDelay, useCreateNewCall, StartCallButton }