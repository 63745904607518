import { useEffect, useState } from 'react';
// const GetDateContext = createContext();

class ServerDateClass {

    serverTime = new Date();
    serverFetched = false;

    clockInterval = null;
    refreshInterval = null;


    stopClock = () => {
        // console.log('stopping the clock')
        if (this.clockInterval) {
            clearTimeout(this.clockInterval);
            this.clockInterval = null;
        }
    }
    startClock = () => {
        this.stopClock();
        // console.log('starting the clock')
        this.clockInterval = setInterval(() => {
            this.serverTime.setSeconds(this.serverTime.getSeconds() + 1);
        }, 1000)
    }

    getCurrentDate = async () => {
        // console.log('getting current date from server')
        this.serverFetched = true;
        if (this.refreshInterval) clearInterval(this.refreshInterval)

        try {
            // const server_time_request = await fetch(
            //     process.env.REACT_APP_BACKEND_AJAX_URL, {
            //     method: "get",
            //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            //     body: "action=get_date"
            // })
            const server_time_request = await fetch(
                process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + 'get_date', {
                method: "get",
            })
            const request_status = server_time_request.status;
            const server_time = await server_time_request.json();

            if (request_status !== 200) {
                throw new Error((server_time.data || {}).errorMessage || "An error occured")
            }

            this.serverTime = new Date(server_time.date) ?? new Date();
            // console.log(this.serverTime)
        }
        catch (e) {
            console.error(e)
            this.serverTime = new Date();
        }

        this.refreshInterval = setInterval(this.getCurrentDate, 1000 * 60 * 10)
        this.startClock()
        return this.serverTime
    }
}

const ServerDate = new ServerDateClass();
// export default ServerDate;
const useDate = () => {
    const [date, setDate] = useState(ServerDate.serverTime);

    useEffect(() => {
        if (!ServerDate.serverFetched) {
            const fetchServer = async () => {
                const currentDate = await ServerDate.getCurrentDate();
                setDate(currentDate);
            }
            fetchServer()
        }
    }, []);

    return date;
};

export { useDate };
