import { useState, useContext, useCallback } from 'react';
import { NavLink, useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import Auth, { AuthContext } from 'backendConnectors/auth'

import Loader from "components/loader";
import Logo from 'media/img/logo.png';
import LogoEU from "media/img/Logo_UE.jpg";
import LogoBDT from "media/img/bdt_logo.jpg";

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import ListItemIcon from '@mui/material/ListItemIcon';

import MenuIcon from '@mui/icons-material/Menu';

import PersonIcon from '@mui/icons-material/Person';
import ModeIcon from '@mui/icons-material/Mode';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


const Header = () => {
    const navigate = useNavigate();
    const intl = useIntl();

    const authContext = useContext(AuthContext);
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const [anchorAccountMenuDesktop, setAnchorAccountMenuDesktop] = useState(null);
    const AccountMenuDesktopOpen = Boolean(anchorAccountMenuDesktop);
    const handleClickAccountMenuDesktop = (event) => {
        setAnchorAccountMenuDesktop(event.currentTarget);
    };
    const handleCloseAccountMenuDesktop = () => {
        setAnchorAccountMenuDesktop(null);
    };


    const doLogout = useCallback(() => {
        Auth.doSignOut()
        handleCloseAccountMenuDesktop();
        handleCloseNavMenu()
        navigate('/', { replace: true })
    }, [navigate])

    return (
        <nav>
            <AppBar elevation={0} position="static" color="white" sx={{ px: 3, pt: 3, pb: 0, borderBottom: '1px solid #494d7a', mb: 3 }} >
                <Container maxWidth="xl" >
                    <Toolbar disableGutters>
                        <Box display="flex">
                            <NavLink to="/">
                                <Box sx={{
                                    display: 'flex',

                                    width: { xs: '35vw', sm: '30vw', md: '20vw' },
                                    maxWidth: "350px"
                                }}>
                                    <img src={Logo} alt="PVD+" style={{ maxHeight: '80px', width: '100%', objectFit: 'contain' }} />
                                    {/* <Typography pb={1} fontWeight={500} textAlign="center" lineHeight={1.2} fontSize={"2rem"} sx={{ color: "#1d4293" }}>Petites Villes de Demain</Typography> */}

                                </Box>
                            </NavLink>

                            <Box sx={{ ml: "auto", mr: { xs: 'auto', md: 'initial' }, width: '60vw', maxWidth: "200px", display: 'grid', placeItems: 'center' }} >
                                <img src={LogoBDT} alt="" />
                            </Box>

                            <Box sx={{ ml: "auto", mr: { xs: 'auto', md: 'initial' }, width: '60vw', maxWidth: "200px", display: 'grid', placeItems: 'center' }} >
                                <img src={LogoEU} alt="" />
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "flex-end" }}>
                            <IconButton
                                size="large"
                                aria-label="Menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >

                                <MenuItem >
                                    <NavElement to='/' text={<FormattedMessage id="calls" defaultMessage="Calls" />} onClick={handleCloseNavMenu} className="fullwidth" />
                                    {/* <Typography sx={{ fontSize: '.875rem' }}>Logout</Typography> */}
                                </MenuItem>

                                {!authContext.init ?
                                    <MenuItem disabled>
                                        <Loader size={20} thickness={6} />
                                    </MenuItem>
                                    : authContext.user ?
                                        [{
                                            text: intl.formatMessage({ id: "myaccount", defaultMessage: "My account" }),
                                            to: "/account",
                                            icon: <ManageAccountsIcon sx={{ fontSize: '1rem' }} />,
                                            // onClick: () => { navigate('/account', { replace: true }) },
                                            type: 'navItem'
                                        }, {
                                            text: intl.formatMessage({ id: "logout", defaultMessage: "Logout" }),
                                            icon: <LogoutIcon sx={{ fontSize: '1rem' }} />,
                                            onClick: doLogout,
                                        }].map(mi => {
                                            return mi.type === 'navItem' ?
                                                <MenuItem key={mi.text}>
                                                    <NavElement to={mi.to} text={mi.text} onClick={handleCloseNavMenu} startIcon={mi.icon} />
                                                </MenuItem>
                                                :
                                                <MenuItem key={mi.text} onClick={mi.onClick}>
                                                    {mi.icon &&
                                                        <ListItemIcon >
                                                            {mi.icon}
                                                        </ListItemIcon>
                                                    }
                                                    <Typography sx={{ fontSize: '.875rem' }}>{mi.text}</Typography>
                                                </MenuItem>

                                        })

                                        : [{
                                            key: 'signup',
                                            el: <NavElement className="fullwidth" to='/signup' text={<FormattedMessage id="signup" defaultMessage="Sign up" />} onClick={handleCloseNavMenu} startIcon={<ModeIcon />} />
                                        }, {
                                            key: "login",
                                            el: <NavElement className="fullwidth" to='/login' text={<FormattedMessage id="login" defaultMessage="Login" />} onClick={handleCloseNavMenu} startIcon={<PersonIcon />} />
                                        },].map(mi => {
                                            return <MenuItem key={mi.key}>
                                                {mi.el}
                                            </MenuItem>
                                        })

                                }

                            </Menu>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', }}>
                            <NavElement to='/' text={<FormattedMessage id="calls" defaultMessage="Calls" />} onClick={handleCloseNavMenu} />

                            {
                                !authContext.init ?
                                    <NavBox>
                                        <Loader size={20} thickness={6} />
                                    </NavBox>
                                    : authContext.user ?
                                        <>
                                            <NavBox>
                                                <Button
                                                    color='gray'
                                                    sx={{ borderRadius: 0, textTransform: authContext.user.displayName ? 'capitalize' : 'none' }}
                                                    startIcon={<PersonIcon />}
                                                    onClick={handleClickAccountMenuDesktop}
                                                    aria-controls={AccountMenuDesktopOpen ? 'account-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={AccountMenuDesktopOpen ? 'true' : undefined}
                                                >
                                                    <FormattedMessage id="hello" defaultMessage="Hello" />,&nbsp;<Typography variant="span" color={(theme) => theme.palette.secondary.main} sx={{ fontWeight: 700, maxWidth: '25vw', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{(authContext.user.displayName ?? authContext.user.email).replaceAll(';', ' ')}</Typography><i className="gg-chevron-down" style={{ "--ggs": .7 }}></i>
                                                </Button>
                                                <AccountMenu anchorEl={anchorAccountMenuDesktop} open={AccountMenuDesktopOpen} handleClose={handleCloseAccountMenuDesktop} doLogout={doLogout} />
                                            </NavBox>
                                        </> :
                                        <>
                                            <NavElement to='/signup' text={<FormattedMessage id="signup" defaultMessage="Sign up" />} onClick={handleCloseNavMenu} startIcon={<ModeIcon />} />

                                            <NavElement to='/login' text={<FormattedMessage id="login" defaultMessage="Login" />} onClick={handleCloseNavMenu} startIcon={<PersonIcon />} />
                                        </>
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar >
        </nav >
    )
}

const NavBox = (props) => {
    return <Box className={`nav-item ${props.className ?? ""}`} sx={{ display: 'flex', alignItems: 'center' }}>{props.children}</Box>
}

const NavElement = (props) => {
    return <NavBox className={props.className}>
        <NavLink to={props.to} >
            <Button
                onClick={props.onClick}
                color='gray'
                sx={{ borderRadius: 2, textTransform: 'capitalize', '.MuiButton-startIcon': { marginLeft: '0' } }}
                startIcon={props.startIcon}
            >
                {props.text}
            </Button>
        </NavLink>
    </NavBox>
}

const AccountMenu = ({ anchorEl, open, handleClose, doLogout }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem >
                <NavElement to='/account' text={<FormattedMessage id="myaccount" defaultMessage="My account" />} onClick={handleClose} startIcon={<ManageAccountsIcon sx={{ fontSize: '1rem' }} />} />
            </MenuItem>

            <MenuItem disabled>
                <Box className="dotted purple" sx={{ flex: 1 }}></Box>
            </MenuItem >

            <MenuItem onClick={doLogout}>
                <NavBox>
                    <Button
                        color='gray'
                        sx={{ borderRadius: 2, textTransform: 'capitalize', '.MuiButton-startIcon': { marginLeft: '0' } }}
                        startIcon={<LogoutIcon sx={{ fontSize: '1rem' }} />}
                    >
                        <FormattedMessage id="logout" defaultMessage="Logout" />
                    </Button>
                </NavBox>
                {/* <ListItemIcon >
                    <LogoutIcon sx={{ fontSize: '1rem' }} />
                </ListItemIcon>
                <Typography sx={{ fontSize: '.875rem' }}>Logout</Typography> */}
                {/* <NavElement to='/' text='Logout' onClick={handleClose} startIcon={<LogoutIcon sx={{ fontSize: '1rem' }} />} /> */}
            </MenuItem>
        </Menu>
    )
}


export default Header;

