import { useContext, useState, useCallback, memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";

import { useNavigate } from "react-router-dom"

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import EditIcon from "components/icons/Edit";

import Auth, { AuthContext } from "backendConnectors/auth";
import BackdropContext from "ShowBackdrop";
import SnackContext from "ShowSnackbar";

import Loader from "components/loader";

import InputSelectPhone from "components/participate/questions/selectPhone";
import InputText from "components/participate/questions/inputText";

const InputTextMemo = memo(InputText)
const SelectPhoneMemo = memo(InputSelectPhone)

const AccountProfile = () => {
    const intl = useIntl();

    const theme = useTheme()
    const [editProfile, setEditProfile] = useState(false);
    const authContext = useContext(AuthContext);
    const { setOpenBackdrop } = useContext(BackdropContext)
    const { createSnack } = useContext(SnackContext);

    const navigate = useNavigate();

    const [refreshing, setRefreshing] = useState(true);

    const [newName, setNewName] = useState({
        value: "",
        error: null,
    });
    const [newSurname, setNewSurname] = useState({
        value: "",
        error: null,
    });
    const [newPhone, setNewPhone] = useState({
        value: "",
        error: null,
    });
    const [newPhoneExt, setNewPhoneExt] = useState({
        value: "",
        error: null,
    });

    useEffect(() => {
        const refresh = async () => {
            const res = await Auth.makeAuthenticatedReqest("current_user");

            if (res.success) {
                setRefreshing(false)
            }
            else {
                navigate('/', { replace: true })
            }
        }
        refresh()
    }, [navigate])

    const doUpdateProfile = useCallback(async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target)

        const name = String(formData.get('name'))
        const surname = String(formData.get('surname'))
        const phone = String(formData.get('phone'))
        const phoneExt = String(formData.get('phoneExt'))

        let isError = false;
        // name is required
        if (!name) {
            isError = true;
            setNewName(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // surname is required
        if (!surname) {
            isError = true;
            setNewSurname(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        if (isError) return

        setOpenBackdrop(true)

        try {
            await Auth.UpdateProfile({
                name, surname, phone: phone ? `${phoneExt}-${phone}` : ""
            })
            setEditProfile(false)
        }
        catch (e) {
            const message = e.error ? e.error : intl.formatMessage({ id: 'errorOccured', defaultMessage: 'Something went wrong.' })
            createSnack(message, { severity: 'error' })
        }
        setOpenBackdrop(false)
    }, [setOpenBackdrop, createSnack, intl])

    if (!authContext.user || refreshing) {
        return <Loader size={30} />
    }

    // const [name, surname] = (authContext.user.displayName || '-').split('-')
    const [name, surname] = [authContext.user.firstName, authContext.user.lastName];
    const [phoneExtBase, phone] = (authContext.user.phone || '-').split('-')
    const phoneExt = phoneExtBase.replace('_', '_+');

    const openUpdateProfile = () => {

        setNewName({ value: name, error: null })
        setNewSurname({ value: surname, error: null })
        setNewPhone({ value: phone, error: null })
        setNewPhoneExt({ value: phoneExt, error: null })
        setEditProfile(true)
    }

    let phoneExtDialCode = null
    if (phoneExt && phoneExt.includes('_')) {
        const val = phoneExt.split('_');
        val.shift();
        phoneExtDialCode = val.join('_')
    }


    return (
        <>
            <Typography variant="h2" color="secondary" >
                <FormattedMessage id="yourProfile" defaultMessage="Your profile" />
            </Typography>
            <Box className="dotted purple" sx={{ my: 3 }}></Box>

            <Grid container>
                <Grid item xs={12} md={6} sx={{}}>
                    <Box color={theme.palette.gray.main} display="grid" sx={{
                        gridTemplateColumns: {
                            xs: '1fr',
                            sm: '10ch 1fr',
                            md: 'auto 1fr'
                        },
                        gridRowGap: '1rem',
                        gridColumnGap: {
                            xs: '1rem',
                            md: '2.5rem'
                        },
                        mb: { xs: '1rem', md: 0 },
                        '& :nth-of-type(2n)': {
                            pl: { xs: 2, sm: 0 }
                        }
                    }}>
                        <Typography>
                            <FormattedMessage id="name" defaultMessage="Name" />:
                        </Typography>
                        <Typography textTransform={'capitalize'} sx={{ wordBreak: 'break-all' }}>
                            {name}
                        </Typography>

                        <Typography>
                            <FormattedMessage id="surname" defaultMessage="Surname" />:
                        </Typography>
                        <Typography textTransform={'capitalize'} sx={{ wordBreak: 'break-all' }}>
                            {surname}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{}}>
                    <Box color={theme.palette.gray.main} display="grid" sx={{
                        pl: { xs: 0, md: '1.5rem' },
                        gridTemplateColumns: {
                            xs: '1fr',
                            sm: '10ch 1fr',
                            md: 'auto 1fr'
                        },
                        gridRowGap: '1rem',
                        gridColumnGap: {
                            xs: '1rem',
                            md: '2.5rem'
                        },
                        borderColor: theme.palette.gray.light,
                        borderWidth: '1px',
                        borderLeftStyle: { xs: 'none', md: 'solid' },
                        '& :nth-of-type(2n)': {
                            pl: { xs: 2, sm: 0 }
                        }
                    }}>
                        <Typography>
                            <FormattedMessage id="email" defaultMessage="Email" />:
                        </Typography>
                        <Typography sx={{ wordBreak: 'break-all', opacity: 0.5 }}>
                            {authContext.user.email}
                        </Typography>

                        <Typography>
                            <FormattedMessage id="phone" defaultMessage="Phone" />:
                        </Typography>
                        {authContext.user.phone ?
                            <Typography sx={{ wordBreak: 'break-all' }}>
                                {phoneExtDialCode} {phone.startsWith('0') ? phone.substr(1, phone.length - 1) : phone}
                            </Typography>
                            :
                            <Button color="gray" varian="text"
                                disabled={editProfile}
                                onClick={openUpdateProfile}
                                sx={{
                                    minWidth: 0,
                                    textTransform: 'none',
                                    textDecoration: 'underline',
                                    p: 0,
                                    justifyContent: 'start',
                                    fontWeight: 400,
                                }}>
                                <FormattedMessage id="addPhone" defaultMessage="Add phone number" />:

                            </Button>
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box className="dotted purple" sx={{ my: 3 }}></Box>
            {
                !editProfile ?
                    <Box textAlign={"right"}>

                        <Button
                            onClick={openUpdateProfile}
                            color="green" disableElevation variant="contained" startIcon={<EditIcon />}
                            sx={{ textTransform: 'none', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>

                            <FormattedMessage id="editProfileButton" defaultMessage="Edit profile" />:

                        </Button>

                    </Box>
                    :

                    <Box>
                        <Typography variant="h2" color="secondary" >
                            <FormattedMessage id="editProfileTitle" defaultMessage="Edit your profile" />:

                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={doUpdateProfile}
                        >
                            <>

                                <InputTextMemo
                                    id={"name"}
                                    label={<FormattedMessage id="name" defaultMessage="Name" />}
                                    type={"text"}
                                    value={newName.value}
                                    setValue={(v) => setNewName({ value: v, error: '' })}
                                    error={newName.error}
                                    autoComplete={"given-name"}
                                    hideCount
                                />
                                <InputTextMemo
                                    id={"surname"}
                                    label={<FormattedMessage id="surname" defaultMessage="Surname" />}
                                    type={"text"}
                                    value={newSurname.value}
                                    setValue={(v) => setNewSurname({ value: v, error: '' })}
                                    error={newSurname.error}
                                    autoComplete={"given-name"}
                                    hideCount
                                />

                                <SelectPhoneMemo
                                    label={<FormattedMessage id="phone" defaultMessage="Phone" />}
                                    phoneId={"phone"} phoneValue={newPhone.value} setPhoneValue={(v) => setNewPhone({ value: v, error: '' })}
                                    phoneExtId={"phoneExt"} phoneExtValue={newPhoneExt.value} setPhoneExt={(v) => setNewPhoneExt({ value: v, error: '' })}
                                    optional
                                    error={newPhone.error}
                                />

                                <Button
                                    color='green'
                                    sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                                    variant='contained'
                                    disableElevation
                                    type="submit"
                                    disabled={!newName.value || !newSurname.value}
                                >
                                    <FormattedMessage id="submit" defaultMessage="SUBMIT" />
                                </Button>

                            </>
                        </Box>
                    </Box>
            }
        </>
    )
}

export default AccountProfile;