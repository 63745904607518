import SvgIcon from '@mui/material/SvgIcon';

const UpArrowIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g transform="translate(-1.5 -1.5), scale(1.06)">
                <path d="M12.75,1.5a11.253,11.253,0,0,1,4.379,21.615A11.253,11.253,0,0,1,8.371,2.385,11.18,11.18,0,0,1,12.75,1.5Zm0,20.455a9.2,9.2,0,1,0-6.509-2.7A9.144,9.144,0,0,0,12.75,21.955Z" fill="currentColor" />
                <path d="M19.7,16.636a1.02,1.02,0,0,1-.723-.3l-3.368-3.368-3.368,3.368A1.023,1.023,0,1,1,10.8,14.89L14.89,10.8a1.023,1.023,0,0,1,1.446,0l4.091,4.091a1.023,1.023,0,0,1-.723,1.746Z" transform="translate(-2.864 -2.864)" fill="currentColor" />
                <path d="M17.523,20.727A1.023,1.023,0,0,1,16.5,19.7V11.523a1.023,1.023,0,0,1,2.045,0V19.7A1.023,1.023,0,0,1,17.523,20.727Z" transform="translate(-4.773 -2.864)" fill="currentColor" />
            </g>
        </SvgIcon>
    );
}

export default UpArrowIcon;