import SvgIcon from '@mui/material/SvgIcon';

const ExclamationMarkIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <circle cx="32" cy="32" r="32" transform="scale(0.375)" fill="currentColor" />
            <path id="Tracé_164" data-name="Tracé 164" d="M13.275-32.909l-.559,27.8H6.231l-.559-27.8Zm-3.8,44.351a4.843,4.843,0,0,1-3.55-1.481,4.843,4.843,0,0,1-1.481-3.55,4.843,4.843,0,0,1,1.481-3.55A4.843,4.843,0,0,1,9.473,1.38a4.843,4.843,0,0,1,3.55,1.481A4.843,4.843,0,0,1,14.5,6.411a4.813,4.813,0,0,1-.685,2.516A5.259,5.259,0,0,1,12,10.758,4.792,4.792,0,0,1,9.473,11.442Z" transform="translate(8.53 16.03), scale(0.375)" fill="#fff" />
        </SvgIcon>
    );
}

export default ExclamationMarkIcon;