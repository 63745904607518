import { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import SnackContext from "ShowSnackbar";

import Loader from "components/loader";
import Auth from "backendConnectors/auth";

import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const ValidateEmail = () => {
    const intl = useIntl();
    const [validated, setValidated] = useState(false);

    const validationTriggered = useRef(false);

    const location = useLocation();
    const navigate = useNavigate();

    const { createSnack } = useContext(SnackContext)
    // const authContext = useContext(AuthContext);

    useEffect(() => {
        // if (!authContext.init) return

        // if (authContext.user && authContext.user.emailVerified) {
        //     return navigate('/', { replace: true })
        // }

        const locationState = location.state;

        if (!locationState) {
            // you must pass state variables to validate the email
            return navigate('/', { replace: true })
        }

        // let redirectTimeout = null;

        if (locationState && !validationTriggered.current) {
            validationTriggered.current = true;
            if (!locationState.mode || locationState.mode !== "verifyEmail") {
                return navigate('/', { replace: true })
            }

            const actionCode = locationState.oobCode
            const uid = locationState.uid

            const applyValidation = async () => {
                try {
                    const validation_response = await Auth.ApplyEmailValidation({ uid, actionCode })

                    if (validation_response.success) {
                        setValidated(true)

                        await Auth.doSignOut()

                        setTimeout(() => {
                            return navigate('/login', {
                                replace: true, state: {
                                    showEmailValidatedMessage: true,
                                }
                            })
                        }, 0)
                    }
                    else {
                        throw new Error()
                    }
                } catch (e) {
                    createSnack(intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' }), { severity: 'error' })

                    return navigate('/', { replace: true })
                }

            }

            applyValidation()

        }

        // return () => {
        //     if (redirectTimeout) {
        //         clearTimeout(redirectTimeout)
        //     }
        // }
    }, [location, navigate, createSnack, intl])//authContext.init, authContext.user


    return (<>
        {!validated ?
            // <Loader startColor="#ffffffbb" stopColor="#ffffff50" />
            <Loader />
            :
            <ValidatedEmail />
        }
    </>)
}

export default ValidateEmail;


const ValidatedEmail = () => {
    const theme = useTheme()

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4, display: "flex", flexDirection: 'column' }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%", flex: 1, display: "flex", flexDirection: 'column' }}>
                <Typography variant="h1" color="primariy.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    <FormattedMessage id="emailValidation" defaultMessage={"Email validation"} />

                </Typography>
                <Box className="dotted" sx={{ my: 4, }}></Box>

                <Container disableGutters maxWidth="md">
                    <Box className="rounded-corner" color={theme.palette.gray.main} sx={{ backgroundColor: "#ffffff", px: "10%", py: 4 }}>
                        <Typography sx={{ lineHeight: 1.75, display: 'none' }}>
                            <FormattedMessage id="emailValidated" defaultMessage={"Your email has been validated and you can now login to your account"} />

                            <br />
                            <FormattedMessage id="emailValidationRedirect" defaultMessage={"You will be redirected"} />

                        </Typography>
                    </Box>
                </Container >
            </Container>
        </Container>
    )
}