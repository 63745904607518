import DOMPurify from 'dompurify'
import { useTheme } from '@mui/material/styles';
// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const MultiSelectSeparator = "/;";
const InputMultiSelect = (props) => {
    const { id, label, options, value, setValue, optional, error } = props

    const formattedOptions = options.split('\r\n');
    // const inputValue = !!+value || false;
    const currentValues = value ? value.split(MultiSelectSeparator) : [];

    const inputLabel = label + (!optional ? "*" : "")

    const theme = useTheme();

    const setInputValue = (fo, checked) => {
        if (checked) {
            if (currentValues.includes(fo)) return
            currentValues.push(fo);
            setValue(currentValues.join(MultiSelectSeparator));
        }
        else {
            if (!currentValues.includes(fo)) return
            const valIndex = currentValues.indexOf(fo);
            currentValues.splice(valIndex, 1);
            setValue(currentValues.join(MultiSelectSeparator));
        }
        // get all entries of multifields
        // formattedOptions.forEach(fo => {
        //     const foKey = fo.replaceAll(" ", "-");
        //     const inputElement = document.querySelector(`input[name="${foKey}"]`);
        //     console.log(inputElement)
        // })
        // setValue(value);
    }

    return (
        <Box id={id}>
            <Typography className="question-title" color={!!error ? theme.palette.error.main : theme.palette.gray.main} fontSize={".8rem"} sx={{ mt: 2 }}>{inputLabel}</Typography>
            <TextField
                name={id}
                sx={{ display: 'none' }}
                inputProps={{
                    value: value || ""
                }}
            />
            {formattedOptions && formattedOptions.map((fo) => {
                return <FormControl key={fo} error={!!error} variant="standard" color="primary" fullWidth sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={<Checkbox
                            size="small"
                            name={fo}
                            id={fo}
                            checked={currentValues.includes(fo)}
                            onChange={(event) => setInputValue(fo, event.target.checked)}
                            sx={{ pt: 0, }}
                        />
                        }
                        label={
                            <Typography color={theme.palette.gray.main} fontSize={"0.8rem"}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fo) }}
                            />
                        }
                        sx={{ alignItems: "flex-start" }}
                    />


                </FormControl >
            })}
        </Box>
    )
}

export default InputMultiSelect;
export { MultiSelectSeparator };