import { useIntl, FormattedMessage } from "react-intl";
import { useEffect, useState, useContext, useCallback, useRef, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SnackContext from "ShowSnackbar";

import Loader from "components/loader";
import Auth, { AuthContext } from "backendConnectors/auth";

import { SignupInput, minPasswordLength } from "components/signup";
import PasswordResetEmailSentFrame from "utilities/passwordResetEmailSentFrame";

import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


const SignupInputMemo = memo(SignupInput)

const ResetPassword = () => {
    const intl = useIntl();
    const [applied, setApplied] = useState(false);
    const [validated, setValidated] = useState(false);

    const resetTriggered = useRef(false);

    const location = useLocation();
    const navigate = useNavigate();

    const { createSnack } = useContext(SnackContext)
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!authContext.init) return

        if (authContext.user) {
            return navigate('/', { replace: true })
        }

        const locationState = location.state;

        if (!locationState) {
            setApplied(true)
            setValidated(false)
            return
        }
        let redirectTimeout = null;

        if (locationState && !resetTriggered.current) {
            resetTriggered.current = true;
            if (!locationState.mode || locationState.mode !== "resetPassword") {
                return navigate('/', { replace: true })
            }

            const actionCode = locationState.oobCode
            const uid = locationState.uid

            Auth.VerifyPasswordResetCode({ uid, actionCode }).then(
                async () => {
                    // action successfull
                    setApplied(true)
                    setValidated(true)
                },
                (e) => {
                    console.error('application error')
                    // action error
                    setApplied(true)
                    setValidated(false)
                    createSnack(intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' }), { severity: 'error' })
                }
            )

        }

        return () => {
            if (redirectTimeout) {
                clearTimeout(redirectTimeout)
            }
        }
    }, [location, navigate, createSnack, authContext.init, authContext.user, intl])


    return (<>
        {!applied ?
            // <Loader startColor="#ffffffbb" stopColor="#ffffff50" />
            <Loader />
            :
            validated ?
                <ResetPasswordForm uid={location.state.uid} actionCode={location.state.oobCode} /> :
                <RequestPasswordResetEmail />

        }
    </>)
}

export default ResetPassword;

const ResetPasswordForm = ({ uid, actionCode }) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate()
    const { createSnack } = useContext(SnackContext)

    const [changingPassword, setChangingPassword] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)

    const [password, setPassword] = useState({
        value: "",
        error: null,
    });
    const [confirmPassword, setConfirmPassword] = useState({
        value: "",
        error: null,
    });

    useEffect(() => {
        let navigateTimeout = null;
        if (passwordChanged) {
            navigateTimeout = setTimeout(() => {
                navigate('/login', {
                    replace: true, state: {
                        showPasswordChangedMessage: true,
                    }
                })
            }, 0)
        }

        return () => {
            if (navigateTimeout) clearTimeout(navigateTimeout)
        }
    }, [passwordChanged, navigate])

    const fields = [
        {
            value: password.value,
            setValue: setPassword,
            error: password.error,
            id: "password",
            label: intl.formatMessage({ id: "password", defaultMessage: "Password" }),
            required: true,
            type: "password",
            maxLength: -1,
            minLength: minPasswordLength,
            autoComplete: 'new-password'
        }, {
            value: confirmPassword.value,
            setValue: setConfirmPassword,
            error: confirmPassword.error,
            id: "confirmPassword",
            label: intl.formatMessage({ id: "confirmPassword", defaultMessage: "Confirm password" }),
            required: true,
            type: "password",
            autoComplete: 'new-password',
            maxLength: -1,
            minLength: minPasswordLength,
        }
    ]

    const changePassword = useCallback(async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target)

        const password = String(formData.get('password'))
        const confirmPassword = String(formData.get('confirmPassword'))

        let isError = false;
        // password validation
        const passwordHasNumber = password.match(/[0-9]/g)
        const passwordHasLowercase = password.match(/[a-z]/g)
        const passwordHasUppercase = password.match(/[A-Z]/g)
        const weakPasswordMessage = `${intl.formatMessage({ id: 'passwordMinBefore', defaultMessage: "Your password must be at least" })} ${minPasswordLength} ${intl.formatMessage({ id: 'passwordMinAfter', defaultMessage: "characters and contain at least one lower case letter, one upper case letter and one number" })}`
        if (!passwordHasNumber || !passwordHasLowercase || !passwordHasUppercase || password.length < minPasswordLength) {
            isError = true;
            setPassword(cur => ({ ...cur, error: weakPasswordMessage }))
        }

        // confirmPassword must be equal to password
        if (password !== confirmPassword) {
            isError = true;
            setConfirmPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'passwordMismatch', defaultMessage: "Password does not match" }) }))
        }

        if (isError) return

        setChangingPassword(true)
        try {
            await Auth.ConfirmPasswordReset({ uid, actionCode, newPassword: password })
            setPasswordChanged(true)
        } catch (e) {
            console.error('catched', e)
            const errorCode = e.code;

            switch (errorCode) {
                case ("auth/weak-password"):
                    setPassword(cur => ({ ...cur, error: weakPasswordMessage }))
                    break
                default:
                    createSnack(intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' }), { severity: 'error' })
            }

        }
        setChangingPassword(false)
    }, [uid, actionCode, createSnack, intl])

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4, display: "flex", flexDirection: 'column' }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%", flex: 1, display: "flex", flexDirection: 'column' }}>
                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    <FormattedMessage id="passwordResetTitle" defaultMessage={"Password reset"} />
                </Typography>
                <Box className="dotted" sx={{ my: 4, }}></Box>
                {changingPassword ?
                    // <Loader startColor="#ffffffbb" stopColor="#ffffff50" />
                    <Loader />
                    :
                    <Container disableGutters maxWidth="md">
                        <Box className="rounded-corner" color={theme.palette.gray.main} sx={{ backgroundColor: "#ffffff", px: "10%", py: 4 }}>
                            {passwordChanged ?
                                <Typography sx={{ lineHeight: 1.75, display: 'none' }}>
                                    <FormattedMessage id="passwordChanged" defaultMessage={"Your password has been changed, you can now connect to your account"} />
                                </Typography>
                                :
                                <>
                                    <Typography sx={{ lineHeight: 1.75 }}>
                                        <FormattedMessage id="chooseNewPassword" defaultMessage={"Choose your new password"} />
                                    </Typography>

                                    <Box
                                        component="form"
                                        noValidate
                                        onSubmit={changePassword}
                                    >

                                        {fields.map(f => {
                                            return <SignupInputMemo
                                                key={f.id}
                                                id={f.id}
                                                label={f.label}
                                                minLength={f.minLength}
                                                maxLength={f.maxLength}
                                                required={f.required}
                                                type={f.type}
                                                value={f.value}
                                                setValue={f.setValue}
                                                error={f.error}
                                                autoComplete={f.autoComplete}
                                            />
                                        })}

                                        <Button
                                            color='green'
                                            sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                                            variant='contained'
                                            disableElevation
                                            type="submit"
                                            disabled={!password.value || !confirmPassword.value}
                                        >
                                            SUBMIT
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Container>
                }
            </Container>
        </Container>
    )
}

const RequestPasswordResetEmail = () => {
    const intl = useIntl();
    const theme = useTheme()

    const [emailRequested, setEmailRequested] = useState(false);
    const [requestingEmail, setRequestingEmail] = useState(false);

    const [email, setEmail] = useState({
        value: "",
        error: null,
    });

    const { createSnack } = useContext(SnackContext)

    const requestEmail = async (event) => {
        event.preventDefault();

        setRequestingEmail(true)

        try {
            await Auth.SendPasswordResetEmail({ email: email.value });
            setEmailRequested(true)
        }
        catch (e) {
            console.error('request error', e)

            switch (e.code) {
                case ("auth/invalid-email"):
                case ("auth/email-not-found"):
                    setEmail({ value: email.value, error: 'Invalid email' })
                    break;
                default:
                    createSnack(intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' }), { severity: 'error' })
            }

        }
        setRequestingEmail(false)
    }

    const fields = [
        {
            value: email.value,
            setValue: setEmail,
            error: email.error,
            id: "email",
            label: intl.formatMessage({ id: "email", defaultMessage: "Email" }),
            required: true,
            type: "email",
            autoComplete: "email",
            maxLength: -1
        }
    ]

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4, display: "flex", flexDirection: 'column' }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%", flex: 1, display: "flex", flexDirection: 'column' }}>
                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    <FormattedMessage id="passwordResetTitle" defaultMessage={"Password reset"} />
                </Typography>
                <Box className="dotted" sx={{ my: 4, }}></Box>

                {requestingEmail ?
                    // <Loader startColor="#ffffffbb" stopColor="#ffffff50" /> :
                    <Loader /> :
                    <Container disableGutters maxWidth="md">

                        {emailRequested ?
                            <PasswordResetEmailSentFrame />
                            :
                            <Box className="rounded-corner" color={theme.palette.gray.main} sx={{ backgroundColor: "#ffffff", px: "10%", py: 4 }}>
                                <Typography sx={{ lineHeight: 1.75 }}>
                                    <FormattedMessage id="passwordResetMessage" defaultMessage={"If you can not connect to your account, we can send you a link to reset your password"} />

                                </Typography>

                                <Box
                                    component="form"
                                    noValidate
                                    onSubmit={requestEmail}
                                >

                                    {fields.map(f => {
                                        return <SignupInputMemo
                                            key={f.id}
                                            id={f.id}
                                            label={f.label}
                                            minLength={f.minLength}
                                            maxLength={f.maxLength}
                                            required={f.required}
                                            type={f.type}
                                            value={f.value}
                                            setValue={f.setValue}
                                            error={f.error}
                                            autoComplete={f.autoComplete}
                                        />
                                    })}

                                    <Button
                                        color='green'
                                        sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                                        variant='contained'
                                        disableElevation
                                        type="submit"
                                        disabled={!email.value}
                                    >
                                        <FormattedMessage id="submit" defaultMessage="Submit" />
                                    </Button>
                                </Box>
                            </Box>
                        }

                    </Container>
                }
            </Container>
        </Container>
    )
}
