import {
    Routes, Route
} from "react-router-dom";

import { useScrollToTop } from "utilities/scrollToTop";

/* Components */
import ErrorPage from './error';
import CallsList from "components/callsList";
import Login from "components/login";
import Signup from "components/signup";
import ValidateEmail from "components/validateEmail";
import RequestValidationEmail from "components/requestValidationEmail";
import ResetPassword from "components/resetPassword";
import Call from "components/call";
import Participate from "components/participate";
import MyAccount from "components/account";

const AppRoutes = () => {
    // force scroll to top on page change
    useScrollToTop();

    return (
        <Routes>
            <Route path="/" element={<CallsList />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="validateEmail" element={<ValidateEmail />} />
            <Route path="requestValidateEmail" element={<RequestValidationEmail />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="account" element={<MyAccount />} />
            <Route path="call/:callId" element={<Call />} />
            <Route path="participate/:projectId" element={<Participate />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
}

export default AppRoutes;

