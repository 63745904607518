import { FormattedMessage } from "react-intl";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { useRouteError } from "react-router-dom";

// import { useTheme } from '@mui/material/styles';

const ErrorPage = (props) => {
    // const error = useRouteError();
    // const theme = useTheme();

    return (
        <Container sx={{ flex: 1, display: 'grid', placeItems: 'center' }}>
            <Box id="error-page">
                <Typography variant="h1" color="black" sx={{ textAlign: 'center' }}>
                    <FormattedMessage id="sorry" defaultMessage={"Sorry,"} />
                    <br />
                    <FormattedMessage id="pageNotExist" defaultMessage={"this pages does not exists"} />
                </Typography>
            </Box>
        </Container>

    );
}

export default ErrorPage;