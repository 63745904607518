import { useEffect, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { FixedSizeList } from 'react-window';

import tzCitiesToCountries from 'media/data/tz-cities-to-countries.json';
import phoneExtList from 'media/data/phoneExt.json'
import phoneExtListWorldwide from 'media/data/phoneExt_worldwide.json'

const computeLocalRegion = () => {
    // let userRegion;
    let userCity;
    let userCountry;
    let userTimeZone;

    if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tzArr = userTimeZone.split("/");
        // userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        userCountry = tzCitiesToCountries[userCity];

        return userCountry;
    }
    else return null
}

// const renderFlag = ({ index, style, data }) => {

//     const pe = data[index]
//     return <MenuItem key={pe.code} value={pe.dial_code}>
//         <Box display="flex" alignItems={"center"} justifyContent={"center"} gap={".5rem"}>
//             <img src={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${pe.code}.png`} alt="" width={'1rem'} height={'1rem'} loading="lazy" style={{
//                 width: '1rem',
//                 height: '1rem',
//             }} /> <Typography variant="span">{pe.dial_code}</Typography>
//         </Box>
//     </MenuItem>
// }

const InputSelectPhone = (props) => {

    const { label, optional,
        phoneId, phoneValue, setPhoneValue,
        phoneExtId, phoneExtValue, setPhoneExt,
        error, worldwide
    } = props

    const inputValue = phoneValue || ""

    const inputExtValue = phoneExtValue || null;

    // console.log("rendering input phone", inputValue, inputExtValue)

    const countryList = useMemo(() => worldwide ? phoneExtListWorldwide : phoneExtList, [worldwide]);

    useEffect(() => {
        if (!phoneExtValue && !props.skipPhoneExtDetection && !props.forcedInitial) {
            const userCountry = computeLocalRegion()

            if (userCountry) {

                const countryCode = countryList.filter(pe => pe.name === userCountry)[0]

                if (countryCode) {
                    // const extValue = countryCode.code + '_' + countryCode.dial_code;
                    // setPhoneExt(extValue)

                    const extValue = countryCode.code + '_' + countryCode.dial_code;
                    setPhoneExt(extValue)
                }

            }
        }
        if (!phoneExtValue && !props.skipPhoneExtDetection && props.forcedInitial) {
            const countryCode = countryList.filter(pe => pe.code === props.forcedInitial)[0]
            if (countryCode) {
                const extValue = countryCode.code + '_' + countryCode.dial_code;
                setPhoneExt(extValue)
            }

        }
    }, [phoneExtValue, setPhoneExt, props.skipPhoneExtDetection, countryList, props.forcedInitial])

    const theme = useTheme();

    const setInputValue = (value) => {
        setPhoneValue(value);
    }
    const setInputExtValue = (value) => {
        setPhoneExt(value);
    }
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel className="question-title" htmlFor={`${phoneId}`} sx={{ width: '100%', whiteSpace: "pre-line", transform: "scale(0.75)", position: 'relative', fontSize: "1rem", color: !!error ? theme.palette.error.main : theme.palette.gray.main }}>
                {label}{!optional && "*"}
            </InputLabel>

            <Box
                className="input-border-radius"
                sx={{
                    display: "flex", flexWrap: "wrap", alignItems: 'center',
                    // borderRadius: '0.6rem',
                    border: `1px solid ${props.light ? theme.palette.gray.light : theme.palette.gray.main}`
                }}>


                <FormControl id={`${phoneId}Control`} error={!!error} variant="standard" color="primary" sx={{ width: 'max(20%,120px)', }}>
                    <Autocomplete
                        // id={`${phoneExtId}`}
                        // name={`${phoneExtId}`}
                        sx={{
                            width: '100%',
                            "& .MuiOutlinedInput-root": {
                                paddingTop: '4px',
                                paddingBottom: '5px',

                            },
                            "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
                                paddingRight: '24px',
                            },
                            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment ': {
                                right: '5px'
                            },
                            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                                padding: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            },
                        }}
                        value={inputExtValue}
                        onChange={(_, newValue) => setInputExtValue(newValue)}
                        options={countryList.map(pe => `${pe.code}_${pe.dial_code}`)}
                        disableClearable
                        // getOptionLabel={(option) => {return `${option.code}_${option.dial_code}`}}
                        renderOption={(props, option) => {
                            const [countryCode, countryExt] = option.split('_')
                            return (
                                <Box {...props} component="li" display="flex" alignItems={"center"} justifyContent={"center"} gap={".5rem"} >
                                    <img src={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${countryCode}.png`} alt="" width={'1rem'} height={'1rem'} loading="lazy" style={{
                                        width: '1rem',
                                        height: '1rem',
                                    }} /> <Typography variant="span">{Array.isArray(countryExt) ? countryExt.join('_') : countryExt}</Typography>
                                </Box>
                            )
                        }}
                        renderInput={(params) => {
                            let value = params.inputProps.value ?? ''
                            let country = null;
                            if (value.includes('_')) {
                                const values = value.split('_')
                                country = values.shift();
                                value = values.join('_');
                            }
                            return <>
                                <TextField
                                    id={`${phoneExtId}`}
                                    name={`${phoneExtId}`}
                                    sx={{ display: 'none' }}
                                    inputProps={{
                                        value: params.inputProps.value
                                    }}
                                />
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        value,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: country ? (
                                            <InputAdornment position="start" sx={{ mr: 0.5 }}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${country}.png`}
                                                    srcSet={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${country}.png`}
                                                    alt=""
                                                />
                                            </InputAdornment>) : null
                                    }}
                                />
                            </>
                        }}
                    />

                </FormControl >
                <Box sx={{ alignSelf: "stretch", width: '1px', backgroundColor: props.light ? theme.palette.gray.light : theme.palette.gray.main }}></Box>
                <FormControl id={`${phoneId}Control`} error={!!error} variant="standard" color="primary" sx={{ flex: 1 }}>
                    <Input
                        id={`${phoneId}`}
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                        disableUnderline
                        sx={{

                            pt: 0, mt: '0!important',
                            "&.Mui-focused .MuiInput-input": { borderColor: theme.palette.primary.main }
                        }}
                        type={"number"}

                        name={phoneId}

                        inputProps={{
                            autoComplete: "off",
                            maxLength: 50,
                            pattern: "[0-9]*",
                            inputMode: "numeric",
                            sx: { px: '10px', pt: '4px', pb: '5px', border: `none`, }
                        }}
                    />
                </FormControl >
            </Box>
        </Box>
    )
}

export default InputSelectPhone;