import React, { createContext, useState, useCallback } from 'react'
// import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmContext = createContext()

function RenderConfirm({ title, message, cancelText, confirmText, open, handleClose, fullWidthConfirm, hideCancel, confirmWithAction, confirmActionText }) {
    // const theme = useTheme()
    return (
        <Dialog onClose={() => handleClose(false)} open={open ?? false} PaperProps={{ elevation: 0, sx: { backgroundColor: 'transparent', } }}>
            <Box className="confirm-dialog-box" sx={{ margin: "13px" }}>
                <IconButton
                    variant="filled"
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{

                        position: 'absolute',
                        right: 0,
                        top: 0,
                        fontSize: '16px',
                        padding: '5px',
                        // marginRight: '-13px',
                        // marginTop: '-13px',
                        // background: "#ffffff",
                        background: (theme) => theme.palette.green.main,
                        // color: (theme) => theme.palette.gray.main,
                        color: "#ffffff",
                        "&:hover": {
                            background: (theme) => theme.palette.green.main,
                            color: "#666666"
                        }
                    }}
                >
                    <CloseIcon sx={{ color: "inherit" }} />
                </IconButton>
                <DialogTitle color={(theme) => theme.palette.primary.main}>
                    {title}

                </DialogTitle>
                <DialogContent sx={{ color: "#000000" }}>
                    {message}
                </DialogContent>
                <DialogActions sx={{ flexWrap: "wrap", justifyContent: "center", columnGap: 4, rowGap: 1 }}>
                    {!hideCancel &&
                        <Button variant="contained" autoFocus color="primary" onClick={() => handleClose(false)}>
                            {cancelText ?? "Cancel"}
                        </Button>
                    }
                    {confirmWithAction &&
                        <Button variant="contained" autoFocus color="green" onClick={() => { handleClose(true, confirmWithAction) }}>
                            {confirmActionText ?? "Ok"}
                        </Button>
                    }
                    <Button variant={fullWidthConfirm ? "text" : "contained"} color={fullWidthConfirm ? "secondary" : "secondary"} onClick={() => handleClose(true)}
                        sx={{
                            textTransform: fullWidthConfirm ? "none" : "uppercase",
                            textDecoration: fullWidthConfirm ? "underline" : "none",
                            fontSize: fullWidthConfirm ? '.8rem' : '.875rem',
                            width: fullWidthConfirm ? "100%" : 'auto',
                            justifyContent: "center", //fullWidthConfirm ? "flex-end" : "center",
                        }}>
                        {confirmText ?? 'Ok'}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog >
    );
}


export const ConfirmProvider = ({ children }) => {
    const [confirmState, setConfirmState] = useState({})
    const [opened, setOpened] = useState(false);

    const createConfirm = useCallback(async (onConfirmCallback, options) => {

        let resolver;
        const returnedPromise = new Promise((resolve) => {
            resolver = resolve;
        })
        const handleClose = (value, overwriteCallback) => {
            setOpened(false)
            if (value) {
                overwriteCallback ? overwriteCallback() : onConfirmCallback();
            }
            resolver(value)
        }
        setConfirmState({
            ...options,
            handleClose,
        })
        setOpened(true)

        return returnedPromise
    }, [])



    return (
        <ConfirmContext.Provider value={{ createConfirm }}>
            {children}
            <RenderConfirm {...confirmState} open={opened} />
        </ConfirmContext.Provider>
    )
}

export default ConfirmContext;