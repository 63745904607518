import { FormattedMessage, useIntl } from "react-intl";
import { useCallback, memo, useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import BackdropContext from "ShowBackdrop";
import SnackContext from "ShowSnackbar";
import { SignupInput } from "components/signup";
import ValidFilledIcon from "components/icons/ValidFilled";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Auth, { AuthContext } from 'backendConnectors/auth';


const SignupInputMemo = memo(SignupInput);

const Login = () => {
    const intl = useIntl();
    const theme = useTheme()
    // const location = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state ?? {};

    const { setOpenBackdrop } = useContext(BackdropContext);
    const { createSnack } = useContext(SnackContext);
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (user) {
            navigate('/account', { replace: true })
        }
    }, [user, navigate])

    const [email, setEmail] = useState({
        value: "",
        error: null,
    });
    const [password, setPassword] = useState({
        value: "",
        error: null,
    });

    const fields = [
        {
            value: email.value,
            setValue: setEmail,
            error: email.error,
            id: "email",
            label: intl.formatMessage({ id: "email", defaultMessage: "Email" }),
            required: true,
            type: "email",
            autoComplete: "email",
            maxLength: -1
        }, {
            value: password.value,
            setValue: setPassword,
            error: password.error,
            id: "password",
            label: intl.formatMessage({ id: "password", defaultMessage: "Password" }),
            required: true,
            type: "password",
            maxLength: -1,
            autoComplete: 'new-password'
        }
    ]

    const doLogin = useCallback(async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target)

        const email = String(formData.get('email')).toLowerCase()
        const password = String(formData.get('password'))

        setEmail(cur => ({ ...cur, error: null }))
        setPassword(cur => ({ ...cur, error: null }))

        let isError = false;
        // email is required
        if (!email) {
            isError = true;
            setEmail(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // password is required
        if (!password) {
            isError = true;
            setPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }

        if (isError) return

        setOpenBackdrop(true)
        // auth
        try {
            await Auth.LogIn({ email, password })
        }
        catch (error) {
            const errorCode = error.code;

            switch (errorCode) {
                case ("auth/invalid-email"):
                case ("auth/user-not-found"):
                case ("auth/wrong-password"):
                    const mess = error.message || intl.formatMessage({ id: 'invalidCredentials', defaultMessage: "Invalid credentials" })
                    setEmail(cur => ({ ...cur, error: mess }))
                    break
                case ("auth/user-disabled"):
                    setPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'accountDisabled', defaultMessage: `Your account has been disabled. You must change your password` }) }))
                    break
                default:
                    createSnack(intl.formatMessage({ id: 'errorOccured', defaultMessage: 'An error occured' }), { severity: 'error' })
            }
        }
        setOpenBackdrop(false)
    }, [createSnack, setOpenBackdrop, intl])

    return (
        <>
            {!user &&
                <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4 }}>
                    <Container disableGutters maxWidth="xl" sx={{ px: "10%" }}>
                        <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                            <FormattedMessage id="loginTitle" defaultMessage={"Login"} />
                        </Typography>
                        {/* <Box className="dotted" sx={{ my: 4 }}></Box> */}

                        <Container disableGutters maxWidth="sm">
                            <Box className="rounded-corner" color={theme.palette.gray.main} sx={{ backgroundColor: "#ffffff", px: "10%", py: 4 }}>
                                {
                                    locationState.showPasswordChangedMessage && <Box>
                                        <Box display={"flex"}>
                                            <Box display={"inline-flex"} >
                                                <ValidFilledIcon color={"green"} sx={{ fontSize: '3rem' }} />
                                            </Box>
                                            <Typography pl={2.5}>
                                                <FormattedMessage id="passwordChanged" defaultMessage={"Your password has been updated. Please login to apply to a call."} />
                                            </Typography>
                                        </Box>
                                        <Box className="dotted purple" sx={{ my: 3 }}></Box>
                                    </Box>
                                }
                                {
                                    locationState.showEmailValidatedMessage && <Box>
                                        <Box display={"flex"}>
                                            <Box display={"inline-flex"} >
                                                <ValidFilledIcon color={"green"} sx={{ fontSize: '3rem' }} />
                                            </Box>
                                            <Typography pl={2.5}>
                                                <FormattedMessage id="loginEmailValidated" defaultMessage={"Congratulations ! Your email address has been validated. Please login to apply to a call."} />
                                            </Typography>
                                        </Box>
                                        <Box className="dotted purple" sx={{ my: 3 }}></Box>
                                    </Box>
                                }

                                <Typography variant="h2" color={theme.palette.secondary.main} >
                                    <FormattedMessage id="loginMessage" defaultMessage={"Login to your account"} />
                                </Typography>
                                <Box className="dotted purple" sx={{ my: 3 }}></Box>

                                <Box
                                    component="form"
                                    noValidate
                                    onSubmit={doLogin}
                                >

                                    {fields.map(f => {
                                        return <SignupInputMemo
                                            key={f.id}
                                            id={f.id}
                                            label={f.label}
                                            minLength={f.minLength}
                                            maxLength={f.maxLength}
                                            required={f.required}
                                            type={f.type}
                                            value={f.value}
                                            setValue={f.setValue}
                                            error={f.error}
                                            autoComplete={f.autoComplete}
                                        />
                                    })}

                                    <Button
                                        color='green'
                                        sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                                        variant='contained'
                                        disableElevation
                                        type="submit"
                                        disabled={!email.value || !password.value}
                                    >
                                        <FormattedMessage id="submit" defaultMessage="Submit" />
                                    </Button>
                                </Box>

                                <Typography sx={{ mt: 4 }}>
                                    {/* If you can not connect to your account, you can <Typography variant="span" color={theme.palette.secondary.main} sx={{ fontWeight: 500 }}><Link to='/resetPassword'>reset your password.</Link></Typography> */}
                                    <Typography variant="span" color={theme.palette.secondary.main} sx={{ fontWeight: 500 }}>
                                        <Link to='/resetPassword'>
                                            <FormattedMessage id="loginForgotPassword" defaultMessage="Forgot your password?" />
                                        </Link>
                                    </Typography>
                                </Typography>

                                <Typography sx={{ mt: 2, background: '#f4f4f4', padding: '5px', paddingLeft: '15px', borderRadius: '5px' }}>
                                    <FormattedMessage id="loginNoAccountStart" defaultMessage="If you do not have an account, you can" />{" "}
                                    <Typography variant="span" color={theme.palette.secondary.main} sx={{ fontWeight: 500 }}><Link to='/signup'>
                                        <FormattedMessage id="loginNoAccountEnd" defaultMessage="register here." />
                                    </Link></Typography>
                                </Typography>

                            </Box>
                        </Container>
                    </Container>
                </Container>
            }</>
    )
}


export default Login;
