import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { AuthContext } from "backendConnectors/auth";
import Database from "backendConnectors/database";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Loader from "components/loader";
import EditIcon from "components/icons/Edit";
import EnveloppeIcon from "components/icons/Enveloppe";



const AccountDashboard = ({ setPageByName }) => {
    const intl = useIntl()
    const theme = useTheme();
    const authContext = useContext(AuthContext)

    const [projectsRead, setProjectsRead] = useState(false);
    const [nbOpenProjects, setNbOpenProjects] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const projectData = await Database.getNumberOfProjects();
                if ('nb_open_projects' in projectData) {
                    setNbOpenProjects(projectData.nb_open_projects)
                    setProjectsRead(true)
                }
            } catch (error) {
                console.error(error)
            }
        }
        getData();
    }, [])


    return (
        <>
            <Typography variant="h2" color="secondary" >

                <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
            </Typography>
            <Box className="dotted purple" sx={{ my: 2 }}></Box>
            <Typography color={theme.palette.secondary.main} sx={{ textTransform: authContext.user.displayName ? 'capitalize' : 'none' }}>
                <FormattedMessage id="hello" defaultMessage="Hello" />, {
                    (authContext.user.displayName ?? authContext.user.email).replaceAll(';', ' ')
                }.
            </Typography>
            <Grid container sx={{ mt: 1 }} rowSpacing={2.5} columnSpacing={2.5} >

                <Grid item xs={12} sm={6} lg={4}
                // sx={{ pb: '1rem', pr: { md: '10px' } }}
                >
                    <Box sx={{
                        borderRadius: '1rem',
                        borderWidth: '1px', borderStyle: "solid", borderColor: theme.palette.gray.light,
                        px: '1rem', py: '1rem',
                        height: '100%',
                    }}>
                        <Typography color={theme.palette.secondary.main} textAlign='center' fontWeight="700" sx={{ mb: '1rem', pb: '.5rem', borderBottomWidth: '1px', borderBottomColor: theme.palette.gray.light, borderBottomStyle: 'solid' }}>
                            <FormattedMessage id="yourApplications" defaultMessage="Your applications" />
                        </Typography>
                        {
                            !projectsRead ?
                                <Loader size={25} thickness={6} />
                                :
                                nbOpenProjects > 0 ?
                                    <>
                                        <Typography textAlign='center' color={theme.palette.gray.main} sx={{ mb: '1rem' }}>
                                            <FormattedMessage id="youHave" defaultMessage="You have" />{' '}
                                            <Typography variant='span' color={theme.palette.secondary.main} fontWeight="700">{nbOpenProjects}</Typography>{' '}
                                            {nbOpenProjects > 1 ?
                                                <FormattedMessage id="incompleteApplications" defaultMessage="incomplete applications" />
                                                :
                                                <FormattedMessage id="incompleteApplication" defaultMessage="incomplete applications" />
                                            }
                                        </Typography>

                                        <Box textAlign='center'>
                                            <Button color="orange" disableElevation variant="contained" startIcon={<EditIcon />}
                                                onClick={() => { setPageByName(intl.formatMessage({ id: "yourApplications", defaultMessage: "Your applications" })) }}
                                                sx={{ textTransform: 'none', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>

                                                <FormattedMessage id="editApplications" defaultMessage="Edit applications" />
                                            </Button>
                                        </Box>
                                    </> :
                                    <Typography textAlign='center' color={theme.palette.gray.main} sx={{}}>
                                        <FormattedMessage id="noOpenedApplication" defaultMessage="You don't have any opened application" />
                                    </Typography>
                        }
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}
                // sx={{ pb: '1rem', px: { md: '5px' } }}
                >
                    <Box sx={{
                        borderRadius: '1rem',
                        borderWidth: '1px', borderStyle: "solid", borderColor: theme.palette.gray.light,
                        px: '1rem', py: '1rem',
                        height: '100%',
                    }}>
                        <Typography color={theme.palette.secondary.main} textAlign='center' fontWeight="700" sx={{ mb: '1rem', pb: '.5rem', borderBottomWidth: '1px', borderBottomColor: theme.palette.gray.light, borderBottomStyle: 'solid' }}>
                            <FormattedMessage id="yourProfile" defaultMessage="Your profile" />
                        </Typography>

                        <Typography textAlign='center' color={theme.palette.gray.main} sx={{ mb: '1rem' }}>
                            <FormattedMessage id="editProfile" defaultMessage="Edit your profile with useful information" />

                        </Typography>

                        <Box textAlign='center'>
                            <Button color="green" disableElevation variant="contained" startIcon={<EditIcon />}
                                onClick={() => { setPageByName(intl.formatMessage({ id: "yourProfile", defaultMessage: "Your profile" })) }}
                                sx={{ textTransform: 'none', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>

                                <FormattedMessage id="editProfileButton" defaultMessage="Edit profile" />
                            </Button>
                        </Box>

                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}
                // sx={{ pl: { md: '10px' } }}
                >
                    <Box sx={{
                        borderRadius: '1rem',
                        borderWidth: '1px', borderStyle: "solid", borderColor: theme.palette.gray.light,
                        px: '1rem', py: '1rem',
                        height: '100%',
                    }}>
                        <Typography color={theme.palette.secondary.main} textAlign='center' fontWeight="700" sx={{ mb: '1rem', pb: '.5rem', borderBottomWidth: '1px', borderBottomColor: theme.palette.gray.light, borderBottomStyle: 'solid' }}>
                            <FormattedMessage id="contactus" defaultMessage="Contact us" />
                        </Typography>

                        <Typography textAlign='center' color={theme.palette.gray.main} sx={{ mb: '1rem' }}>
                            <FormattedMessage id="contactQuestion" defaultMessage="A question? Don’t hesitate to contact us" />
                        </Typography>

                        <Box textAlign='center'>
                            <Link href={`mailto:${intl.formatMessage({ id: 'contactEmail' })}`} target="_blank" rel="noreferrer">
                                <Button className="vertical_gradient-button" disableElevation variant="contained" startIcon={<EnveloppeIcon />}
                                    sx={{ textTransform: 'none', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>
                                    <FormattedMessage id="contactus" defaultMessage="Contact us" />
                                </Button>
                            </Link>
                        </Box>

                    </Box>
                </Grid>

            </Grid>
        </>
    )
}

export default AccountDashboard;