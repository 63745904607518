import { useCallback, memo, useState, useEffect, useContext } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useIntl, FormattedMessage } from "react-intl";

import BackdropContext from "ShowBackdrop";
import SnackContext from "ShowSnackbar";

import InputSelectPhone from "components/participate/questions/selectPhone";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

import Auth, { AuthContext } from 'backendConnectors/auth';


const SignupInput = (props) => {
    const theme = useTheme();

    const setInputValue = (value) => {
        props.setValue({ ...props.value, value, error: '' });
    }

    return (
        <FormControl error={!!props.error} variant="standard" color="primary" fullWidth sx={{ mt: 2 }}>
            {["text", "email", "password"].includes(props.type) &&
                <FormGroup>
                    <InputLabel shrink htmlFor={`${props.id}`}>{props.label}{props.required && "*"}</InputLabel>
                    <Input
                        id={`${props.id}`}
                        value={props.value}
                        onChange={(event) => setInputValue(event.target.value)}
                        disableUnderline
                        sx={{ pt: 1, "&.Mui-focused .MuiInput-input": { borderColor: theme.palette.primary.main } }}
                        type={props.type}
                        name={props.id}
                        autoComplete={props.autoComplete}


                        inputProps={{
                            minLength: props.minLength ?? -1,
                            maxLength: props.maxLength ?? 50,
                            className: "input-border-radius bordered",
                            sx: { px: 2, }
                        }}
                    />
                </FormGroup>
            }
            {props.type === "checkbox" &&
                <FormControlLabel
                    control={<Checkbox
                        name={props.id}
                        id={`${props.id}`}
                        checked={props.value}
                        onChange={(event) => setInputValue(event.target.checked)}
                        sx={{ pt: 0 }}
                    />
                    }
                    label={props.label()}
                    sx={{ alignItems: "flex-start" }}
                />}

            {props.error &&
                <FormHelperText id={`${props.id}-error`}>{props.error}</FormHelperText>
            }
        </FormControl >
    )
}

const SignupInputMemo = memo(SignupInput);
const InputSelectPhoneMemo = memo(InputSelectPhone);

const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "gm");

const minPasswordLength = 8;

const Signup = () => {
    const intl = useIntl();
    const theme = useTheme()
    // const location = useLocation();
    const navigate = useNavigate();

    const { setOpenBackdrop } = useContext(BackdropContext);
    const { createSnack } = useContext(SnackContext);
    const { user, init } = useContext(AuthContext)

    useEffect(() => {
        if (!init) return

        if (user) {
            navigate('/', {
                replace: true
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init, navigate])

    const [name, setName] = useState({
        value: "",
        error: null,
    });
    const [surname, setSurname] = useState({
        value: "",
        error: null,
    });
    const [email, setEmail] = useState({
        value: "",
        error: null,
    });
    const [newPhone, setNewPhone] = useState({
        value: "",
        error: null,
    });
    const [newPhoneExt, setNewPhoneExt] = useState({
        value: "",
        error: null,
    });
    const [password, setPassword] = useState({
        value: "",
        error: null,
    });
    const [confirmPassword, setConfirmPassword] = useState({
        value: "",
        error: null,
    });
    const [gdpr, setGdpr] = useState({
        value: false,
        error: null,
    });


    const GDPRText = useCallback(() => {
        return (
            <Typography>

                <FormattedMessage id="privacyBefore" defaultMessage="By ticking the box you are complying with the" /> {" "}
                <Typography color={theme.palette.primary.main} variant="span">
                    <Link href={intl.formatMessage({ id: 'privacyLink' })} target="_blank" rel="noreferrer">
                        <FormattedMessage id="privacyPolicyName" defaultMessage="Privacy Policy" />
                    </Link>
                </Typography>
            </Typography>)
    }, [theme, intl])

    const fields = [
        {
            value: name.value,
            setValue: setName,
            error: name.error,
            id: "name",
            label: intl.formatMessage({ id: "name", defaultMessage: "Name" }),
            required: true,
            type: "text",
            autoComplete: "given-name"
        }, {
            value: surname.value,
            setValue: setSurname,
            error: surname.error,
            id: "surname",
            label: intl.formatMessage({ id: "surname", defaultMessage: "Surname" }),
            required: true,
            type: "text",
            autoComplete: "family-name"
        }, {
            value: email.value,
            setValue: setEmail,
            error: email.error,
            id: "email",
            label: intl.formatMessage({ id: "email", defaultMessage: "Email" }),
            required: true,
            type: "email",
            autoComplete: "username email",
            maxLength: -1
        }, {
            type: "phone",
            id: 'phone'
        }, {
            value: password.value,
            setValue: setPassword,
            error: password.error,
            id: "password",
            label: intl.formatMessage({ id: "password", defaultMessage: "Password" }),
            required: true,
            type: "password",
            maxLength: -1,
            minLength: minPasswordLength,
            autoComplete: 'new-password'
        }, {
            value: confirmPassword.value,
            setValue: setConfirmPassword,
            error: confirmPassword.error,
            id: "confirmPassword",
            label: intl.formatMessage({ id: "confirmPassword", defaultMessage: "Confirm password" }),
            required: true,
            type: "password",
            autoComplete: 'new-password',
            maxLength: -1,
            minLength: minPasswordLength,
        }, {
            fullWidth: true,
            value: gdpr.value,
            setValue: setGdpr,
            error: gdpr.error,
            label: GDPRText,
            id: "gdpr",
            required: true,
            type: "checkbox"
        }
    ]

    const doSignup = useCallback(async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target)

        const name = String(formData.get('name'))
        const surname = String(formData.get('surname'))
        const email = String(formData.get('email')).toLowerCase()

        const phone = String(formData.get('phone'))
        const phoneExt = String(formData.get('phoneExt'))

        const password = String(formData.get('password'))
        const confirmPassword = String(formData.get('confirmPassword'))
        const gdpr = String(formData.get('gdpr'))

        let isError = false;
        setName(cur => ({ ...cur, error: null }));
        setSurname(cur => ({ ...cur, error: null }));
        setEmail(cur => ({ ...cur, error: null }));
        setPassword(cur => ({ ...cur, error: null }));
        setConfirmPassword(cur => ({ ...cur, error: null }));
        setGdpr(cur => ({ ...cur, error: null }));
        setNewPhone(cur => ({ ...cur, error: null }));

        // name is required
        if (!name) {
            isError = true;
            setName(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // surname is required
        if (!surname) {
            isError = true;
            setSurname(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // email is required
        if (!email) {
            isError = true;
            setEmail(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // password is required
        if (!password) {
            isError = true;
            setPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // confirmPassword is required
        if (!confirmPassword) {
            isError = true;
            setConfirmPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // gdpr is required
        if (!gdpr) {
            isError = true;
            setGdpr(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }

        // email must be valid
        const isValidEmail = email.match(emailRegex)
        if (!isValidEmail) {
            isError = true;
            setEmail(cur => ({ ...cur, error: intl.formatMessage({ id: 'invalidEmail', defaultMessage: "Invalid email" }) }))
        }

        // password validation
        const passwordHasNumber = password.match(/[0-9]/g)
        const passwordHasLowercase = password.match(/[a-z]/g)
        const passwordHasUppercase = password.match(/[A-Z]/g)
        if (!passwordHasNumber || !passwordHasLowercase || !passwordHasUppercase || password.length < minPasswordLength) {
            isError = true;
            setPassword(cur => ({ ...cur, error: `${intl.formatMessage({ id: 'passwordMinBefore', defaultMessage: "Your password must be at least" })} ${minPasswordLength} ${intl.formatMessage({ id: 'passwordMinAfter', defaultMessage: "characters and contain at least one lower case letter, one upper case letter and one number" })}` }))
        }

        // confirmPassword must be equal to password
        if (password !== confirmPassword) {
            isError = true;
            setConfirmPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'passwordMismatch', defaultMessage: "Password does not match" }) }))
        }

        if (isError) return

        setOpenBackdrop(true)
        // auth
        try {
            await Auth.SignIn({ email, password, name, surname, phone: phone ? `${phoneExt}-${phone}` : '' })
            await Auth.LogIn({ email, password });

            navigate('/', {
                replace: true, state: {
                    showValidationEmailSent: true,
                }
            })
        }
        catch (error) {
            // console.error('catched', error)
            const errorCode = error.code;

            switch (errorCode) {
                case ("auth/email-already-in-use"):
                case ("auth/invalid-email"):
                    setEmail(cur => ({ ...cur, error: intl.formatMessage({ id: 'invalidEmail', defaultMessage: `Invalid email` }) }))
                    break
                case ("auth/weak-password"):
                    setPassword(cur => ({ ...cur, error: `${intl.formatMessage({ id: 'passwordMinBefore', defaultMessage: "Your password must be at least" })} ${minPasswordLength} ${intl.formatMessage({ id: 'passwordMinAfter', defaultMessage: "characters and contain at least one lower case letter, one upper case letter and one number" })}` }))
                    break
                default:
                    createSnack(intl.formatMessage({ id: 'errorOccured', defaultMessage: 'An error occured' }), { severity: 'error' })
            }
        }
        setOpenBackdrop(false)
    }, [createSnack, setOpenBackdrop, navigate, intl])

    return (
        <>
            <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4 }}>
                <Container disableGutters maxWidth="xl" sx={{ px: "10%" }}>
                    <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                        <FormattedMessage id="signup" defaultMessage="Sign up" />
                    </Typography>
                    <Box className="dotted" sx={{ my: 4 }}></Box>

                    <Container disableGutters maxWidth="md">
                        <Box className="rounded-corner" color={theme.palette.gray.main} sx={{ backgroundColor: "#ffffff", px: "10%", py: 4 }}>

                            <Typography variant="h2" color={theme.palette.secondary.main} mb={5}>

                                <FormattedMessage id="createAccount" defaultMessage="Create an account" />
                            </Typography>
                            {/* <Box className="dotted purple" sx={{ my: 3 }}></Box> */}

                            <Typography sx={{ lineHeight: 1.75 }}>
                                <FormattedMessage id="accountNeeded" defaultMessage="You’ll need an account to apply to our calls." />
                                <br />
                                <FormattedMessage id="fillForm" defaultMessage="Please, fill in the form below." />

                            </Typography>

                            <Box
                                component="form"
                                noValidate
                                onSubmit={doSignup}

                                sx={{
                                    display: { xs: 'block', md: 'grid' },
                                    gridTemplateColumns: "repeat(2,1fr)",
                                    columnGap: "1rem",
                                }}
                            >

                                {fields.map(f => {
                                    return <Box key={f.id} sx={{ gridColumn: f.fullWidth ? '1/-1' : 'auto' }}>
                                        {f.type === "phone" ?
                                            <InputSelectPhoneMemo
                                                label={<FormattedMessage id="phone" defaultMessage="Phone" />}
                                                phoneId={"phone"} phoneValue={newPhone.value} setPhoneValue={(v) => setNewPhone({ value: v, error: '' })}
                                                phoneExtId={"phoneExt"} phoneExtValue={newPhoneExt.value} setPhoneExt={(v) => setNewPhoneExt({ value: v, error: '' })}
                                                optional
                                                error={newPhone.error}
                                                forcedInitial={"FR"}
                                            />
                                            :
                                            <SignupInputMemo
                                                id={f.id}
                                                label={f.label}
                                                minLength={f.minLength}
                                                maxLength={f.maxLength}
                                                required={f.required}
                                                type={f.type}
                                                value={f.value}
                                                setValue={f.setValue}
                                                error={f.error}
                                                autoComplete={f.autoComplete}
                                            />
                                        }
                                    </Box>
                                })}

                                {/* <SignupInputMemo id="gdpr" required={true} type="checkbox" label={GDPRText} 
                                    value={gdpr.value}
                                    setValue={setGdpr}
                                    error={gdpr.error} /> */}

                                <Box sx={{ gridColumn: '1/-1' }}>
                                    <Button
                                        color='green'
                                        sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                                        variant='contained'
                                        disableElevation
                                        type="submit"
                                        disabled={!name.value || !surname.value || !email.value || !password.value || !confirmPassword.value || !gdpr.value}
                                    >
                                        <FormattedMessage id="submit" defaultMessage="Submit" />

                                    </Button>
                                </Box>
                            </Box>

                            <Typography display={"inline-block"} sx={{ mt: 4, background: '#f4f4f4', padding: '5px', paddingInline: '25px', borderRadius: '5px' }}>
                                <FormattedMessage id="accountAlready" defaultMessage="Already have an account?" /> <Typography variant="span" color={theme.palette.secondary.main} sx={{ fontWeight: 500 }}><RouterLink to='/login'>
                                    <FormattedMessage id="goToLogin" defaultMessage="Go to the login page." />
                                </RouterLink></Typography>
                            </Typography>
                        </Box>
                    </Container>
                </Container>
            </Container>
        </>
    )
}

export default Signup;
export { SignupInput, minPasswordLength };
