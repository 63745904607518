import SvgIcon from '@mui/material/SvgIcon';

const ValidFilledIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M20.662,10.612A10.05,10.05,0,1,1,10.612.563,10.05,10.05,0,0,1,20.662,10.612ZM9.45,15.933l7.456-7.456a.648.648,0,0,0,0-.917l-.917-.917a.648.648,0,0,0-.917,0L8.991,12.724,6.152,9.885a.648.648,0,0,0-.917,0l-.917.917a.648.648,0,0,0,0,.917l4.214,4.214a.648.648,0,0,0,.917,0Z" transform="translate(-0.563 -0.563), scale(1.15)" />
        </SvgIcon>
    );
}

export default ValidFilledIcon;