import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import Auth from 'backendConnectors/auth'

const AccountMenu = ({ accountPage, setAccountPage, pages }) => {
    const intl = useIntl();
    const theme = useTheme()
    const navigate = useNavigate();

    const doLogout = useCallback(() => {
        Auth.doSignOut()
        navigate('/', { replace: true })
    }, [navigate])

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', mb: '2rem', px: '.5rem', py: '.5rem', borderRadius: '1rem', overflow: 'hidden' }}>
            <nav aria-label="account">
                <List sx={{ color: theme.palette.gray.main, p: 0, }}>
                    {pages && pages.map((p, p_ind) => {
                        return (
                            <ListItem key={p} disablePadding>
                                <ListItemButton onClick={() => { setAccountPage(p_ind) }}>
                                    <ListItemText disableTypography primary={p} sx={{ fontSize: ".9rem", color: accountPage === p_ind ? theme.palette.secondary.main : 'inherity', fontWeight: accountPage === p_ind ? 700 : 400 }} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                    <Box className="dotted purple" sx={{ mt: 3, mx: '1rem' }}></Box>
                    <ListItem disablePadding>
                        <ListItemButton onClick={doLogout}>
                            <ListItemIcon sx={{ minWidth: 0, mr: '10px' }}>
                                <LogoutIcon sx={{ fontSize: '.8rem' }} />
                            </ListItemIcon>
                            <ListItemText disableTypography primary={intl.formatMessage({ id: "logout", defaultMessage: "Logout" })} sx={{ fontSize: ".9rem" }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </nav>
        </Box>
    )
}
export default AccountMenu;