import SvgIcon from '@mui/material/SvgIcon';

const EnveloppeIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M19.807,6H4.867A1.865,1.865,0,0,0,3.009,7.867L3,19.072a1.873,1.873,0,0,0,1.867,1.867H19.807a1.873,1.873,0,0,0,1.867-1.867V7.867A1.873,1.873,0,0,0,19.807,6Zm0,3.735L12.337,14.4,4.867,9.735V7.867l7.47,4.669,7.47-4.669Z" transform="translate(-3 -6), scale(1.25)" />
        </SvgIcon>
    );
}

export default EnveloppeIcon;