import DOMPurify from 'dompurify'
import { useTheme } from '@mui/material/styles';
// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Instructions = (props) => {

    const { label, text } = props
    const theme = useTheme();

    return (
        <Box >
            {label && <Typography className="question-title" color={theme.palette.gray.main} fontSize={"1rem"} sx={{ mt: 1 }}>{label}</Typography>}
            {text && <Box color={theme.palette.gray.main} fontSize={"0.8rem"} sx={{ mb: 1 }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></Box>}
        </Box>
    )
}

export default Instructions;