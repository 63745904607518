import { useContext, useState, useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from "react-intl";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Loader from "components/loader";

import Auth, { AuthContext } from "backendConnectors/auth";
import BackdropContext from "ShowBackdrop";
import SnackContext from "ShowSnackbar";

import InputText from "components/participate/questions/inputText";

const InputTextMemo = memo(InputText)

const AccountChangePassword = () => {
    const intl = useIntl();
    const authContext = useContext(AuthContext);
    const { setOpenBackdrop } = useContext(BackdropContext)
    const { createSnack } = useContext(SnackContext)

    const [currentPassword, setCurrentPassword] = useState({
        value: "",
        error: null,
    });
    const [newPassword, setNewPassword] = useState({
        value: "",
        error: null,
    });
    const [confirmNewPassword, setConfirmNewPassword] = useState({
        value: "",
        error: null,
    });

    const doUpdatePassword = useCallback(async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target)

        const currentPassword = String(formData.get('currentPassword'))
        const newPassword = String(formData.get('newPassword'))
        const confirmNewPassword = String(formData.get('confirmNewPassword'))

        let isError = false;
        // current password is required
        if (!currentPassword) {
            isError = true;
            setCurrentPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // new password is required
        if (!newPassword) {
            isError = true;
            setNewPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }
        // confirm new password is required
        if (!confirmNewPassword) {
            isError = true;
            setConfirmNewPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'required', defaultMessage: "Required" }) }))
        }

        if (newPassword !== confirmNewPassword) {
            isError = true;
            setNewPassword(cur => ({ ...cur, error: intl.formatMessage({ id: 'passwordMismatch', defaultMessage: "Password mismatch" }) }))
        }

        // password validation
        const minPasswordLength = 8;
        const passwordHasNumber = newPassword.match(/[0-9]/g)
        const passwordHasLowercase = newPassword.match(/[a-z]/g)
        const passwordHasUppercase = newPassword.match(/[A-Z]/g)
        if (!passwordHasNumber || !passwordHasLowercase || !passwordHasUppercase || newPassword.length < minPasswordLength) {
            isError = true;
            const message = `${intl.formatMessage({ id: 'passwordMinBefore', defaultMessage: "Your password must be at least" })} ${minPasswordLength} ${intl.formatMessage({ id: 'passwordMinAfter', defaultMessage: "characters and contain at least one lower case letter, one upper case letter and one number" })}`
            setNewPassword(cur => ({ ...cur, error: message }))
        }
        if (isError) return

        setOpenBackdrop(true)

        // const reauthResult = await Auth.ReauthenticateUser(currentPassword);

        // if (reauthResult.error) {
        //     setCurrentPassword(cur => ({ ...cur, error: reauthResult.error }))
        //     setOpenBackdrop(false)
        //     return
        // }

        const changePasswordResult = await Auth.UpdatePassword({ currentPassword, newPassword })

        if (changePasswordResult.error) {
            setCurrentPassword(cur => ({ ...cur, error: changePasswordResult.error }))
            createSnack(changePasswordResult.error, { severity: 'error' })
        }
        else {
            createSnack(
                intl.formatMessage({ id: 'passwordUpdated', defaultMessage: "Your password has been updated!" })

            )
            setCurrentPassword({ value: "", error: null })
            setNewPassword({ value: "", error: null })
            setConfirmNewPassword({ value: "", error: null })
        }

        setOpenBackdrop(false)

    }, [createSnack, setOpenBackdrop, intl])

    if (!authContext.user) {
        return <Loader size={30} />
    }

    return (
        <>
            <Typography variant="h2" color="secondary" >
                <FormattedMessage id="changePassword" defaultMessage={"Change your password"} />
            </Typography>
            <Box className="dotted purple" sx={{ my: 3, mb: 1 }}></Box>

            <Box>
                <Box
                    component="form"
                    noValidate
                    onSubmit={doUpdatePassword}
                >
                    <>

                        <InputTextMemo
                            id={"currentPassword"}
                            label={<FormattedMessage id="currentPassword" defaultMessage={"Current Password"} />}
                            type={"password"}
                            value={currentPassword.value}
                            setValue={(v) => setCurrentPassword({ value: v, error: '' })}
                            error={currentPassword.error}
                            autoComplete={"password"}
                            hideCount
                        />
                        <InputTextMemo
                            id={"newPassword"}
                            label={<FormattedMessage id="newPassword" defaultMessage={"New Password"} />}
                            type={"password"}
                            value={newPassword.value}
                            setValue={(v) => setNewPassword({ value: v, error: '' })}
                            error={newPassword.error}
                            autoComplete={"new-password"}
                            // maxLength={50}
                            hideCount
                        />
                        <InputTextMemo
                            id={"confirmNewPassword"}
                            label={<FormattedMessage id="confirmNewPassword" defaultMessage={"Confirm your new password"} />}
                            type={"password"}
                            value={confirmNewPassword.value}
                            setValue={(v) => setConfirmNewPassword({ value: v, error: '' })}
                            error={confirmNewPassword.error}
                            autoComplete={"new-password"}
                            // maxLength={50}
                            hideCount
                        />


                        <Button
                            color='green'
                            sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                            variant='contained'
                            disableElevation
                            type="submit"
                        // disabled={!currentPassword.value || !newPassword.value || !confirmNewPassword}
                        >
                            <FormattedMessage id="submit" defaultMessage="SUBMIT" />
                        </Button>

                    </>
                </Box>
            </Box>
        </>
    )
}

export default AccountChangePassword;