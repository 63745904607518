import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
// import './index.scss';

import { IntlProvider } from 'react-intl';

import App from './App';
import CssBaseline from '@mui/material/CssBaseline';

import MaterialTheme from './MaterialTheme';
import { FormsProvider } from "./GetForms";
import { AuthProvider } from './backendConnectors/auth';
import { SnackProvider } from "ShowSnackbar";
import { ConfirmProvider } from "ShowConfirmModal";
import { BackdropProvider } from "ShowBackdrop";
import DOMPurify from 'dompurify'

import lang_fr from './lang/fr';

const root = ReactDOM.createRoot(document.getElementById('root'));

// allow target blank
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});


root.render(
  // <React.StrictMode>
  <MaterialTheme>
    <CssBaseline />
    <IntlProvider locale="fr" defaultLocale="en" messages={lang_fr}>
      <SnackProvider>
        <ConfirmProvider>
          <BackdropProvider>
            <FormsProvider>
              <AuthProvider>
                <BrowserRouter basename={process.env.REACT_APP_DIRECTORY}>
                  <App />
                </BrowserRouter>
              </AuthProvider>
            </FormsProvider>
          </BackdropProvider>
        </ConfirmProvider>
      </SnackProvider>
    </IntlProvider>
  </MaterialTheme>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
