import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';


const Loader = (props) => {
    const { size, thickness, startColor, stopColor } = props;

    return (
        <Container disableGutters maxWidth="xl" sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'grid', placeItems: 'center' }}>
                <svg style={{ position: 'absolute', height: '1px', width: '1px' }} >
                    <defs>
                        <linearGradient id='my_gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                            <stop offset='0%' stopColor={startColor ?? '#494d7a'} />
                            <stop offset='100%' stopColor={stopColor ?? '#9cb94d'} />
                        </linearGradient>
                    </defs>
                </svg>
                <CircularProgress size={size ?? 60} thickness={thickness ?? 7} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
            </Box>
        </Container>
    )
}
export default Loader;
