// import { useRef } from "react";
import { useCallback } from "react";
import { useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';


const InputText = (props) => {
    const { id, type, label, value, setValue, optional, minLength, maxLength, min, max, autoComplete, error, hideCount } = props

    // console.log('\t ##rendering', id, "with value", value)
    const inputValue = value ?? ""

    const inputMinLength = (minLength !== "" && minLength !== undefined) ? +minLength : -1
    const inputMaxLength = (maxLength !== "" && maxLength !== undefined) ? +maxLength : (type === "textarea" ? 1000 : 100)

    const inputMinValue = min !== "" ? +min : undefined;
    const inputMaxValue = max !== "" ? +max : undefined;

    const theme = useTheme();

    // const inputReference = useRef();

    // console.log(value, inputValue, type)
    const setInputValue = (value) => {

        let newValue = value;
        if (type === "number") {
            newValue = newValue.replace(/[^\d.,-]/g, '');
            newValue = newValue.replace(" ", "");
        }

        setValue(newValue);
    }
    const formatInputValue = useCallback((value) => {
        if (type !== "number") return value
        else {
            const baseValue = parseFloat(value);
            if (isNaN(baseValue)) return value

            const [integerValue, decimals] = value.split(".");

            let newValue = ""
            let baseInteger = parseInt(integerValue);
            if (baseInteger > 1000) {
                while (baseInteger > 1000) {
                    const adder = baseInteger % 1000;

                    newValue = newValue ? `${adder} ${newValue}` : adder;

                    baseInteger = Math.floor(baseInteger / 1000);
                }
                newValue = `${baseInteger} ${newValue}`
            } else {
                newValue = baseInteger
            }

            if (decimals) newValue = `${newValue}.${decimals}`

            return `${newValue}`
        }
    }, [type])

    const updateOnBlur = (event) => {
        let updateValue = event.target.value;

        if (type === "number") {
            const parsedValue = parseFloat(updateValue);
            if (isNaN(parsedValue)) {
                updateValue = ""
            }
            else {
                updateValue = parsedValue
                if (inputMinValue !== undefined) {
                    updateValue = Math.max(updateValue, inputMinValue)
                }
                if (inputMaxValue !== undefined) {
                    updateValue = Math.min(updateValue, inputMaxValue)
                }
            }
            updateValue = formatInputValue(`${updateValue}`)

        }
        setValue(updateValue);
    }

    const inputTypeProps = {}
    if (type === "number") {
        inputTypeProps.pattern = "[0-9]*";
        inputTypeProps.inputMode = "numeric";
        inputTypeProps.min = inputMinValue;
        inputTypeProps.max = inputMaxValue;
    }



    return (
        <FormControl id={`${id}`} error={!!error} variant="standard" color="primary" fullWidth sx={{ mt: 2 }}>
            <FormGroup>
                <InputLabel className="question-title" htmlFor={`control-${id}`} sx={{ whiteSpace: "pre-line", transform: "none", position: 'relative', fontSize: "0.8rem" }}>{label}{!optional && "*"}</InputLabel>
                {/* {type === "number" &&
                    <NumericFormat id={`${id}`}
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                        decimalSeparator="."
                        thousandSeparator=" "
                    />
                } */}
                <Input
                    id={`control-${id}`}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                    disableUnderline
                    sx={{ pt: 1, mt: '0!important', "&.Mui-focused .MuiInput-input": { borderColor: theme.palette.primary.main } }}
                    type={type === "number" ? "text" : type}

                    onBlur={type === "number" ? updateOnBlur : null}
                    onFocus={type === "number" ? () => { setInputValue(inputValue) } : null}

                    name={id}
                    autoComplete={autoComplete}
                    multiline={type === 'textarea'}
                    minRows={4}
                    maxRows={8}
                    className="input-border-radius"

                    // inputRef={inputReference}
                    inputProps={{
                        minLength: Number(inputMinLength),
                        maxLength: Number(inputMaxLength),
                        // value: formatInputValue(inputValue),
                        ...inputTypeProps,
                        sx: {
                            px: '10px', py: '5px', border: `1px solid ${theme.palette.gray.light}`,
                            "&:invalid": {
                                border: "1px solid #e85957"
                            }
                            // borderRadius: ".6rem" 
                        }
                    }}
                />
                {!hideCount && (type !== "number") &&
                    <Typography color={theme.palette.gray.main} textAlign={"right"} fontSize={".6rem"} mt={'4px'}>{inputValue.length}/{inputMaxLength}</Typography>
                }
                {props.error && props.errorMessage &&
                    <FormHelperText id={`${props.id}-error`}>{props.errorMessage}</FormHelperText>
                }
            </FormGroup>

        </FormControl >
    )
}

export default InputText;