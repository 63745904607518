import React, { createContext, useState } from 'react'

import Loader from "components/loader";
import Backdrop from '@mui/material/Backdrop';

const BackdropContext = createContext()

export const BackdropProvider = ({ children }) => {
    const [openBackdrop, setOpenBackdrop] = useState(false);

    return (
        <BackdropContext.Provider value={{ setOpenBackdrop }}>
            {children}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <Loader />
            </Backdrop>

        </BackdropContext.Provider>
    )
}

export default BackdropContext;