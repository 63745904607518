import { useMemo } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { FixedSizeList } from 'react-window';

// import tzCitiesToCountries from 'media/data/tz-cities-to-countries.json';
import phoneExtList from 'media/data/phoneExt.json'
import phoneExtListWorldwide from 'media/data/phoneExt_worldwide.json'


const sortedPhoneExtList = [...phoneExtList].sort((c1, c2) => {
    if (c1.name > c2.name) return 1
    if (c2.name > c1.name) return -1
    return 0
})
const sortedPhoneExtListWorldwide = [...phoneExtListWorldwide].sort((c1, c2) => {
    if (c1.name > c2.name) return 1
    if (c2.name > c1.name) return -1
    return 0
})

const InputSelectCountry = (props) => {

    const { id, label,
        value, setValue,
        optional, worldwide,
        error } = props

    const inputValue = value || null

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }

    const phoneList = useMemo(() => worldwide ? sortedPhoneExtListWorldwide : sortedPhoneExtList, [worldwide]);

    // console.log('rendering country')

    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel className="question-title" htmlFor={`${id}`} sx={{ width: '100%', whiteSpace: "pre-line", transform: "scale(0.75)", position: 'relative', fontSize: "1rem", color: !!error ? theme.palette.error.main : theme.palette.gray.main }}>
                {label}{!optional && "*"}
            </InputLabel>

            <Box
                className="input-border-radius"
                sx={{
                    display: "flex", flexWrap: "wrap", alignItems: 'center',
                    // borderRadius: '0.6rem',
                    border: `1px solid ${props.light ? theme.palette.gray.light : theme.palette.gray.main}`
                }}>


                <FormControl id={`${id}Control`} error={!!error} variant="standard" color="primary" sx={{ width: '100%', }}>
                    <Autocomplete
                        // id={`${phoneExtId}`}
                        // name={`${phoneExtId}`}
                        sx={{
                            width: '100%',
                            "& .MuiOutlinedInput-root": {
                                paddingTop: '4px',
                                paddingBottom: '5px',

                            },
                            "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
                                paddingRight: '24px',
                            },
                            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment ': {
                                right: '5px'
                            },
                            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                                padding: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            },
                        }}
                        value={inputValue}
                        onChange={(_, newValue) => setInputValue(newValue)}
                        options={phoneList.map(pe => `${pe.code}_${pe.name}`)}
                        disableClearable
                        // getOptionLabel={(option) => {return `${option.code}_${option.dial_code}`}}
                        renderOption={(props, option) => {
                            const [countryCode, countryExt] = option.split('_')
                            return (
                                <Box {...props} component="li" display="flex" alignItems={"center"} justifyContent={"center"} gap={".5rem"} >
                                    <img src={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${countryCode}.png`} alt="" width={'1rem'} height={'1rem'} loading="lazy" style={{
                                        width: '1rem',
                                        height: '1rem',
                                    }} /> <Typography variant="span">{Array.isArray(countryExt) ? countryExt.join('_') : countryExt}</Typography>
                                </Box>
                            )
                        }}
                        renderInput={(params) => {
                            let value = params.inputProps.value ?? ''
                            let country = null;
                            if (value.includes('_')) {
                                const values = value.split('_')
                                country = values.shift();
                                value = values.join('_');
                            }
                            return <>
                                <TextField
                                    id={`${id}`}
                                    name={`${id}`}
                                    sx={{ display: 'none' }}
                                    inputProps={{
                                        value: params.inputProps.value
                                    }}
                                />
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        value,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: country ? (
                                            <InputAdornment position="start" sx={{ mr: 0.5 }}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${country}.png`}
                                                    srcSet={`${process.env.REACT_APP_DIRECTORY}/media/img/flags/${country}.png`}
                                                    alt=""
                                                />
                                            </InputAdornment>) : null
                                    }}
                                />
                            </>
                        }}
                    />

                </FormControl >

            </Box>
        </Box>
    )
}

export default InputSelectCountry;