import DOMPurify from 'dompurify'
import { useTheme } from '@mui/material/styles';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const InputRadio = (props) => {
    const { id, label, value, setValue, options, optional, error } = props


    const formattedOptions = options.split('\r\n');

    const inputValue = value || false;
    // const inputMinLength = minLength ?? -1
    // const inputMaxLength = maxLength ?? (type === "textarea" ? 1000 : 100)

    const inputLabel = label + (!optional && "*")

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }
    return (
        <FormControl id={id} error={!!error} variant="standard" fullWidth sx={{ mt: 2 }}>
            <FormLabel id={`label_${id}`}>
                <Typography className="question-title" color={!!error ? theme.palette.error.main : theme.palette.gray.main} fontSize={".8rem"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputLabel) }}></Typography>
            </FormLabel>
            <RadioGroup
                sx={{ color: theme.palette.gray.main }}
                row
                aria-labelledby={`label_${id}`}
                name={id}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
            >
                {formattedOptions && formattedOptions.map && formattedOptions.map(o => {
                    return <FormControlLabel key={o} sx={{ color: 'inherit', }} value={o} control={<Radio />}
                        disableTypography
                        label={<Typography variant="span" fontSize={"0.8rem"} color={theme.palette.gray.main}>{o}</Typography>} />
                })}

            </RadioGroup>

        </FormControl >
    )
}

export default InputRadio;

