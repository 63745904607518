import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ValidFilledIcon from "components/icons/ValidFilled";
import { Link } from "react-router-dom";

const ApplicationSubmittedFrame = () => {
    const theme = useTheme()
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box p={4} sx={{
            backgroundColor: '#ffffff', textAlign: 'left', borderRadius: '2rem',
            // maxWidth: '500px',
            display: 'inline-flex',
            px: 8,
            marginInline: 'auto',
            mb: 3,
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <Typography variant="h2" color="secondary" display={"flex"} alignItems={"center"}
                sx={{ gap: ".5rem", flexDirection: { xs: "column", sm: "row" }, textAlign: { xs: "center", sm: "left" } }}>
                <Box display={"inline-flex"} borderRadius={'50%'} p={1.5} >
                    <ValidFilledIcon color={"green"} sx={{ fontSize: "2.5rem" }} />
                </Box>
                <FormattedMessage id="applicationSubmitted" defaultMessage={"Your application has been submitted!"} />
            </Typography>
            <Box className="dotted purple" sx={{ my: 3 }}></Box>
            <Typography color={theme.palette.gray.main}>
                <FormattedMessage id="applicationMessage" defaultMessage={"Your application form has been sent to our team."} />
            </Typography>
            <Typography color={theme.palette.gray.main}>
                <FormattedMessage id="applicationInfo" defaultMessage={"We will review your application and we will send you an email with our response."} />
            </Typography>
            <Typography color={theme.palette.gray.main} sx={{ mb: 3 }}>
                <FormattedMessage id="processLength" defaultMessage={"The review process may take up to 3 weeks"} />
            </Typography>
            <Link to="/account" style={{ alignSelf: "center" }} >
                <Button color="green" variant="contained" disableElevation sx={{ textTransform: "none", px: 4, borderRadius: '.6rem' }}>
                    <FormattedMessage id="goDashboard" defaultMessage={"Go to my dashboard"} />
                </Button>
            </Link>
        </Box>
    </Box>
}

export default ApplicationSubmittedFrame
