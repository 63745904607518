import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import AppRoutes from './router';
import './App.scss';

import { FormsContext } from "GetForms";
import { AuthContext } from "backendConnectors/auth";

import Header from 'components/header';
import Footer from "components/footer";
import Loader from "components/loader";

// import TopWave from 'components/topWave';


function App() {

  const formsContext = useContext(FormsContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const location = useLocation();

  useEffect(() => {
    const _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
    const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = 'https://data.arctik.eu/js/container_ZrkgsX28.js'; s.parentNode.insertBefore(g, s);
  }, [location])


  useEffect(() => {
    const urlParams = {}
    searchParams.forEach((value, key) => {
      urlParams[key] = value;
    })

    // if (urlParams.inAppNav === 'validateEmail') {
    //   return navigate('/validateEmail', { replace: true, state: urlParams })
    // }

    // if (urlParams.inAppNav === 'resetPassword') {
    //   return navigate('/resetPassword', { replace: true, state: urlParams })
    // }

    if (urlParams.inAppNav) {
      return navigate(`/${urlParams.inAppNav}`, { replace: true, state: urlParams })
    }

  }, [navigate, searchParams])

  return (
    <>

      <Header />

      <main className="main_background">
        {/* <TopWave /> */}
        {
          (formsContext.read && authContext.init) ? <AppRoutes /> : <Loader />
        }

      </main>

      <Footer />

    </>
  );
}

export default App;
