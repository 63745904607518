
import { FormattedMessage, useIntl } from "react-intl";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// import LogoFooter from "media/img/Logo_UE.jpg";
// import LogoLeftFooter from "media/img/bdt_logo.jpg";
import logoTechnopolis from "media/img/technopolis.svg";
// import logoArctik from "media/img/arctik-logo.svg";
import logoArctik from "media/img/Arctik_logo_RGB.png";

const Footer = () => {
    const intl = useIntl();

    return (
        <footer>
            <Container disableGutters color="primary" maxWidth="xl" sx={{ px: 8, py: 3 }}>
                <Toolbar disableGutters >
                    <Grid container sx={{ alignItems: 'center' }}>

                        <Grid item xs={12} md={3} sx={{ mb: { xs: 2, md: 0 } }}>
                            <Box sx={{ ml: { xs: 'auto', md: 0 }, mr: { xs: 'auto', md: 'initial' }, width: '60vw', maxWidth: "200px", display: 'grid', placeItems: 'center' }} >
                                <img src={logoTechnopolis} alt="" />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ px: 2, mb: { xs: 2, md: 0 } }} >
                            <Box mb={1} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                <FooterLink href={`mailto:${intl.formatMessage({ id: 'contactEmail' })}`} target="_blank" rel="noreferrer">
                                    <FormattedMessage id="contactus" defaultMessage={"Contact\u00A0us"} />
                                </FooterLink>
                                <FooterLink href={intl.formatMessage({ id: 'privacyLink' })} target="_blank" rel="noreferrer">

                                    <FormattedMessage id="privacyPolicies" defaultMessage={"Privacy\u00A0policies"} />
                                </FooterLink>
                            </Box>
                            <Box sx={{ textAlign: 'center', background: 'white' }}>
                                <Typography color="gray.main" fontSize={'0.8rem'}>
                                    <FormattedMessage id="copyright" defaultMessage={"Copyright\u00A0\u00A92022 All\u00A0rights\u00A0Reserved"} /></Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mb: { xs: 2, md: 0 } }}>
                            <Box sx={{ ml: "auto", mr: { xs: 'auto', md: 'initial' }, width: '40vw', maxWidth: "150px", display: 'grid', placeItems: 'center' }} >
                                <a href="https://www.arctik.eu/" target="_blank" rel="noreferrer" style={{ 'display': "flex" }}>
                                    <img src={logoArctik} alt="" />
                                </a>
                            </Box>
                        </Grid>
                    </Grid>




                </Toolbar>
            </Container>

        </footer >
    )
}

const FooterLink = (props) => {
    return (
        <Link className="footer__link" href={props.href} sx={{ textAlign: "center" }} underline="none" target={props.target} rel={props.rel}>
            {props.children}
        </Link>
    )
}
export default Footer