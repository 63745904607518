import { useContext, useRef, useMemo, useState, useCallback, useEffect, memo } from 'react';
import { FormattedMessage, useIntl } from "react-intl";

import { prepareFormDate, FormsContext } from "GetForms";
import Database from "backendConnectors/database";
import { AuthContext } from 'backendConnectors/auth'

import { useParams, useNavigate } from "react-router-dom"

import BackdropContext from "ShowBackdrop";
import SnackContext from "ShowSnackbar";
import ConfirmContext from "ShowConfirmModal";
import { useTheme } from '@mui/material/styles';

import Loader from "components/loader";
import PageContent from "./pageContent";
import ParticipateMenu from "./participateMenu";

import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import CheckBox from "./questions/checkBox";
import { MultiSelectSeparator } from "./questions/multiselect"

import { acceptedFileExtensions } from "./questions/file";

// import PreviousIcon from "components/icons/Previous";
// import NextIcon from "components/icons/Next";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ValidFilledIcon from "components/icons/ValidFilled";

import useConfirmExit from "router/navigationBlocker";

const isQuestionAnswered = (questionContent, answers, qMap) => {
    if (questionContent.optional) return true;

    if (questionContent.type === "instruction") return true;

    const answer = answers[questionContent.id]
    if (!questionContent.conditional) {
        return answer !== undefined && answer !== "" && answer !== null
    }

    let isRequired = false
    questionContent.conditional.forEach(qc => {
        if (isRequired) return;

        const subAnswer = answers[qc.field]
        const cond_type = qMap[qc.field];
        const mapped_qc = qc.values.split('\r\n')

        if (cond_type === "multiselect") {

            console.log(subAnswer)
            const splittedAnswer = subAnswer.split(MultiSelectSeparator);
            for (const pfa of splittedAnswer) {
                const isin = mapped_qc.includes(pfa)
                if (isin) {
                    isRequired = true;
                    break
                }
            }
        }
        else {
            if (mapped_qc.includes(subAnswer)) isRequired = true;
        }


    })

    if (isRequired) {
        return answer !== undefined && answer !== "" && answer !== null
    }

    return true;
}

const isPageFilled = (page, answers, qMap) => {
    if (!page.questions) return true;

    if (page.repeated_page) {
        const added_pages = answers[page.page_id];
        if (added_pages) {

            for (const page_id of added_pages.split(MultiSelectSeparator)) {
                for (const questionContent of page.questions) {
                    if (!('id' in questionContent)) continue;

                    const editedQuestion = { ...questionContent }
                    editedQuestion["id"] = `${editedQuestion.id}_${page_id}`
                    const questionIsAnswered = isQuestionAnswered(editedQuestion, answers, qMap)
                    if (!questionIsAnswered) return false
                }
            }

        }
    }
    else {
        for (const questionContent of page.questions) {
            const questionIsAnswered = isQuestionAnswered(questionContent, answers, qMap)
            if (!questionIsAnswered) return false
        }
    }
    return true
}

const isSectionFilled = (section, answers, qMap) => {
    for (const page of (section.pages || [])) {

        const pageIsAnswered = isPageFilled(page, answers, qMap)
        if (!pageIsAnswered) return false
    }
    return true
}

const computeTabCompletion = (formContent, answers) => {
    const tabAnswered = [];

    const qMap = computeQuestionTypeMap(formContent);
    formContent.content.tabs.forEach(
        t => {
            const sectionsAnswered = [];
            const sections = t.sections ?? [];

            sections.forEach(s => sectionsAnswered.push(isSectionFilled(s, answers, qMap)))
            tabAnswered.push(sectionsAnswered)
        }
    )
    return tabAnswered
}

const computeQuestionTypeMap = (formContent) => {
    const qMap = {}
    formContent.content.tabs.forEach(tab => {
        if (!tab.sections) return

        tab.sections.forEach(sec => {
            if (!sec.pages) return

            sec.pages.forEach(page => {
                if (!page.questions) return

                page.questions.forEach(q => {
                    qMap[q.id] = q.type
                })
            })
        })
    })
    return qMap
}

const scrollTop = () => {
    document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // smooth ??
    });
}

const checkFileExists = async (selected_file) => {
    const readProm = new Promise((resolve, reject) => {
        // check the file exists
        const reader = new FileReader();
        reader.readAsText(selected_file);

        reader.onerror = function () {
            reject(-1);
        };

        reader.onload = function () {
            resolve(0);
        };
    });
    return readProm;
}

const getFileExtension = (fName) => {
    return fName.slice((Math.max(0, fName.lastIndexOf(".")) || Infinity) + 1);
}

// const PageContentMemo = memo(PageContent)
const Participate = () => {
    const intl = useIntl();
    const { setOpenBackdrop } = useContext(BackdropContext);
    const { createSnack } = useContext(SnackContext)
    const { createConfirm } = useContext(ConfirmContext)
    const authContext = useContext(AuthContext);

    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const [section, setSection] = useState(0);
    const [pageRefresh, setPageRefresh] = useState(Date.now())

    const { projectId } = useParams();
    const formRef = useRef()

    // page loading
    const [loading, setLoading] = useState(true);

    // const formUpdated = useRef(false);
    const [formUpdated, _setFormUpdated] = useState({ current: false });

    const [currentProject, setCurrentProject] = useState({});

    // const [repeatedPages, setRepeatedPages] = useState({});

    const setFormUpdated = useCallback((value) => {
        if (formUpdated.current === value) return
        _setFormUpdated({ current: !!value })
    }, [formUpdated])

    const formsContext = useContext(FormsContext);
    const { setForm } = formsContext;

    // useEffect(() => {
    //     // check that the project exist on each render
    //     if (!(projectId in Firestore.projects)) {
    //         setFormUpdated(false)
    //         navigate('/', { replace: true })
    //     }
    // },); //, [projectId, navigate, setFormUpdated])

    useEffect(() => {
        if (!authContext.init) return
        if (!authContext.user) {
            navigate('/', { replace: true })
        }
    }, [authContext.init, authContext.user, navigate])

    useEffect(() => {

        if (!authContext.init) return;
        if (!authContext.user) return;

        const getOpenProjects = async () => {
            try {
                const project = await Database.getProject(projectId);

                if ('project_data' in project) {
                    setCurrentProject(project.project_data);

                    if ('form' in project && project.form) {
                        const form = project.form;
                        prepareFormDate(form);
                        setForm(form);
                    }
                }
                else {
                    navigate('/', { replace: true })
                }
            } catch (error) {
                console.error(error)
                navigate('/', { replace: true })
            }
        }
        getOpenProjects();
    }, [projectId, authContext.init, authContext.user, navigate, setForm])

    useEffect(() => {
        if (currentProject && currentProject.status && currentProject.status.submitted) {
            navigate('/', { replace: true })
        }

    }, [currentProject, navigate])

    // const currentProject = Firestore.projects[projectId] ?? {}
    const currentAnswers = currentProject.data || {}

    const currentStatus = currentProject.status || {}

    const callid = currentStatus.callId;

    const formContent = useMemo(() => {
        if (!formsContext.read) return null
        if (!pageRefresh) return null;

        return formsContext.forms.filter((f) => f.status.formId === callid)[0] ?? null;
    }, [formsContext.read, formsContext.forms, callid, pageRefresh])

    const currentPageContent = useMemo(() => {
        if (!formContent) return null
        if (tab < 0) return null
        return formContent.content.tabs[tab].sections[section] ?? null
    }, [formContent, tab, section])

    const submitApplication = useCallback(async () => {
        setOpenBackdrop(true)
        const formUpdateDate = ((formContent || {}).status || {}).updated_at || null;

        try {
            await Database.submitProject(projectId, formUpdateDate)

            navigate('/', {
                replace: true, state: {
                    showApplicationSubmitted: true,
                }
            })
        }
        catch (e) {
            console.error(e)
            if ('data' in e && "form" in e.data && e.data.form) {
                const form = e.data.form;
                prepareFormDate(form);
                setForm(form);
            }

            let message = e.message ?? intl.formatMessage({ id: "somethingWrong", defaultMessage: "Something went wrong.\nPlease try again" });
            createSnack(message, { severity: 'error' })
        }
        setOpenBackdrop(false)
    }, [navigate, createSnack, projectId, setOpenBackdrop, formContent, setForm, intl])

    const submitAnswers = useCallback(async (event) => {
        if (event) event.preventDefault()

        if (!formRef.current) return

        if (!formUpdated.current) return

        setOpenBackdrop(true)

        const allQuestions = (currentPageContent.pages || []).filter(
            p => !p.repeated_page
        ).map(p => p.questions || []).flat()

        const formAnswers = new FormData(formRef.current);

        const outputData = {};
        const filesToUpload = {};

        for (const p of (currentPageContent.pages || []).filter(p => p.repeated_page)) {
            if (!p.questions) continue

            const page_id = p.page_id
            const created_page = formAnswers.get(page_id) ?? ""

            outputData[page_id] = created_page

            if (!created_page) {
                continue;
            }

            for (const created_id of created_page.split(MultiSelectSeparator)) {
                for (const q of p.questions) {
                    if (!("id" in q)) continue
                    const upQ = { ...q }
                    upQ["id"] = `${upQ.id}_${created_id}`
                    allQuestions.push(upQ)
                }
            }
        }

        allQuestions.forEach(q => {
            // console.log(q.id, JSON.stringify(outputData));
            switch (q.type) {
                case ('text'):
                case ("textarea"):
                case ("radio"):
                case ("email"):
                    outputData[q.id] = formAnswers.get(q.id) ?? ""
                    break
                case ("number"):
                    let answer = formAnswers.get(q.id);
                    // if (answer) {
                    //     answer = answer.replace(" ", "");
                    // }
                    outputData[q.id] = answer ? answer : ""
                    break
                case ("checkbox"):
                    const v = !!formAnswers.get(q.id)
                    outputData[q.id] = v ? v : ""
                    break
                case ("select"):
                    outputData[q.id] = formAnswers.get(q.id) ?? ""
                    break
                case ("autoselect"):
                    outputData[q.id] = formAnswers.get(q.id) ?? ""
                    break
                case ("multiselect"):
                    outputData[q.id] = formAnswers.get(q.id) ?? ""
                    break
                case ("file"):
                    const f = formAnswers.get(q.id)
                    if (f.size > 0) {
                        filesToUpload[q.id] = f
                    } else {
                        const fValue = formAnswers.get(`${q.id}_value`);
                        if (fValue === "null" || fValue == null) {
                            outputData[q.id] = "";
                        }
                        else {
                            outputData[q.id] = JSON.parse(fValue);
                        }
                    }
                    break
                case ("date"):
                    // console.log(q.id, formAnswers.get(q.id));
                    const ans = formAnswers.get(q.id).replace(/[\s\W]/g, '');
                    outputData[q.id] = ans !== "DDMMYYYY" ? formAnswers.get(q.id) : "";
                    break
                case ("select_country"):
                    outputData[q.id] = formAnswers.get(q.id) ?? ""
                    break
                case ("select_phone"):
                    outputData[q.id] = `${formAnswers.get(`${q.id}_phoneExt`)};${formAnswers.get(`${q.id}_phone`)}`
                    break
                case ("instruction"):
                    break
                default:
                    // console.error('UNKNOWN FIELD TYPE')
                    // setOpenBackdrop(false)
                    console.error(`UNKNOWN FIELD TYPE ${q.type}`);
            }
        })

        // console.log(outputData)
        // setOpenBackdrop(false)
        // return


        // upload files
        if (Object.keys(filesToUpload).length) {

            // check the files exist
            for (const f of Object.values(filesToUpload)) {
                try {
                    await checkFileExists(f);
                } catch (e) {
                    // console.error("file does not exists")
                    createSnack(`File "${f.name}" does not exists`, { severity: "error" })
                    setOpenBackdrop(false)
                    throw Error(e)
                }
            }

            // upload the files
            for (const [qId, f] of Object.entries(filesToUpload)) {
                try {
                    const fExt = getFileExtension(f.name);
                    if (!fExt || !acceptedFileExtensions.includes(fExt)) {
                        createSnack(`Invalid file "${f.name}"`, { severity: "error" })
                        setOpenBackdrop(false)
                        return
                    }
                    // const fileRefPath = `projects/${authContext.user.uid}/${projectId}/${qId}.${fExt}`
                    await Database.uploadFile(projectId, qId, f)
                    outputData[qId] = f.name
                }
                catch (e) {
                    console.error(e);
                    createSnack(`File "${f.name}" could not be uploaded`, { severity: "error" })
                    setOpenBackdrop(false)
                    throw Error(e.error || "")
                }
            }
        }

        // write to database
        try {
            const formUpdateDate = ((formContent || {}).status || {}).updated_at || null;

            const project = await Database.setProjectData(projectId, JSON.stringify(outputData), formUpdateDate);

            if ('project_data' in project) {
                setCurrentProject(project.project_data);

                if ('form' in project && project.form) {
                    const form = project.form;
                    prepareFormDate(form);
                    setForm(form);
                }
                // formUpdated.current = false;
                setFormUpdated(false)
                setOpenBackdrop(false)
                setPageRefresh(Date.now())
            }
            else {
                navigate('/', { replace: true })
            }
        }
        catch (e) {
            if ('data' in e && "form" in e.data && e.data.form) {
                const form = e.data.form;
                prepareFormDate(form);
                setForm(form);
            }

            console.error(e)
            createSnack(
                intl.formatMessage({ id: "somethingWrongWhileSaving", defaultMessage: "Something went wrong while saving your form." })
                , { severity: "error" })
            setOpenBackdrop(false)
            throw Error(e)
        }

    }, [navigate, setForm, formContent, createSnack, currentPageContent, formUpdated, projectId, setFormUpdated, setOpenBackdrop, intl])


    const questionTypeMap = useMemo(() => {
        if (!formContent || !formContent.content) return
        if (!formContent.content.tabs) return

        const qMap = computeQuestionTypeMap(formContent);
        return qMap;
    }, [formContent])

    const findQuestionType = useCallback((questionId) => {
        return questionTypeMap[questionId] || null
    }, [questionTypeMap])

    /* Validation before leaving page */
    const confirmBeforeLeave = useCallback(async (applyLeave, overwriteConfirmWithAction) => {
        const response = await createConfirm(() => {
            applyLeave()
        }, {
            title: intl.formatMessage({ id: "unsavedModifications", defaultMessage: "You have unsaved modifications" }),
            message: intl.formatMessage({ id: "sureLeaving", defaultMessage: "Are you sure you want to leave this page?" }),
            confirmText: intl.formatMessage({ id: "leaveWithoutSave", defaultMessage: "Leave page without saving" }),
            fullWidthConfirm: true,
            hideCancel: true,
            confirmWithAction: overwriteConfirmWithAction ? overwriteConfirmWithAction : async () => {
                try {
                    await submitAnswers()
                    applyLeave()
                } catch {
                    // submit Failed, we do not leave
                }
            },
            confirmActionText: intl.formatMessage({ id: "saveAndLeave", defaultMessage: "Save & Leave" }),
        })
        return response
    }, [createConfirm, submitAnswers, intl])

    const handleBlockedNavigation = useCallback(
        async () => {
            if (!formUpdated.current) return true;

            let saveAndLeave = false;

            const response = await confirmBeforeLeave(() => {
            }, async () => {
                saveAndLeave = true
            })

            if (saveAndLeave && response) {
                try {
                    await submitAnswers()
                }
                catch {
                    // submit Failed, we do not leave
                }
            }

            // return false;
            return response
        },
        [formUpdated, confirmBeforeLeave, submitAnswers]
    )
    useConfirmExit(handleBlockedNavigation, true)

    const applyPageNav = useCallback(({ tab, section }) => {

        if ((typeof tab !== 'number') || (typeof section !== "number")) {
            console.warn("invalid tab/section", tab, section)
            return
        }

        const availableTabs = formContent.content.tabs || [];
        if (tab > availableTabs.length || tab < 0) {
            console.warn("invalid tab", tab)
            return
        }
        const availableSections = formContent.content.tabs[tab].sections || [];
        if (section > availableSections.length || section < 0) {
            console.warn("invalid section", section)
            return
        }

        if (formUpdated.current) {
            confirmBeforeLeave(() => {
                setFormUpdated(false)

                setTab(tab)
                setSection(section)
                scrollTop()
            })
            return
        }

        setTab(tab)
        setSection(section)
        scrollTop()
    }, [confirmBeforeLeave, formContent, formUpdated, setFormUpdated])

    const goToSubmit = useCallback(() => {
        if (formUpdated.current) {
            confirmBeforeLeave(() => {
                setFormUpdated(false)

                setTab(-1)
                setSection(0)
                scrollTop()
            })
            return
        }

        setTab(-1)
        setSection(0)
        scrollTop()
    }, [confirmBeforeLeave, formUpdated, setFormUpdated])

    const downloadFileFromDb = useCallback(async (id) => {
        setOpenBackdrop(true)
        try {
            await Database.downloadFile(authContext.user.token, projectId, id);
        }
        catch (e) {
            console.error(e)
            createSnack("Something went wrong while downloading your file.", { severity: "error" })
        }
        setOpenBackdrop(false)
    }, [projectId, createSnack, setOpenBackdrop, authContext.user])

    if (!formContent || !authContext.init || !authContext.user) {
        return <Loader />//<Loader startColor="#ffffffbb" stopColor="#ffffff50" />
    }

    const tabCompletion = computeTabCompletion(formContent, currentAnswers)

    const allTabCompleted = tabCompletion.map(tc => tc.indexOf(false)).reduce(
        (prev, current) => {
            if (!prev) return prev;
            return current < 0
        }, true
    )

    const findNext = () => {
        if (tab < 0) {
            return null
        }
        // next in current page
        const content = formContent.content
        const sections = content.tabs[tab].sections
        if (section < (sections.length - 1)) {
            return { tab: tab, section: section + 1 }
        }

        let tabIndex = tab + 1
        while (tabIndex < content.tabs.length) {
            if (content.tabs[tabIndex].sections) {
                return { tab: tabIndex, section: 0 }
            }
            tabIndex += 1
        }
        return null
    }
    const nextPage = findNext()

    const findPrevious = () => {
        if (tab < 0) {
            return null
        }
        // next in current page
        const content = formContent.content
        if (section > 0) {
            return { tab: tab, section: section - 1 }
        }

        let tabIndex = 0
        while (tabIndex < tab) {
            if (content.tabs[tabIndex].sections) {
                return { tab: tabIndex, section: content.tabs[tabIndex].sections.length - 1 }
            }
            tabIndex += 1
        }

        return null
    }
    const previousPage = findPrevious()

    const formNavigationProps = {
        submitAnswers,
        previousPage,
        nextPage,
        applyPageNav,
        formUpdated,
        allTabCompleted,
        goToSubmit,
        tab
    }

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4 }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "8%", position: 'relative', pl: "max(8%, calc(1.5rem + 20px))" }}>

                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    {formContent.status.title}
                </Typography>
                {/* <Typography variant="h2" color="secondary.light" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    Application Form &#124; ID: {projectId}
                </Typography> */}
                <Box className="dotted" sx={{ my: 3 }}></Box>

                <Grid container>
                    <Grid item xs={0} md={3} sx={{
                        position: { xs: 'absolute', md: 'relative' }, left: 0, top: { xs: '5rem', md: 0 },
                        paddingRight: { xs: 0, md: '1rem' },
                        maxWidth: 'min(25%, 300px)!important'
                    }}>
                        <ParticipateMenu tabCompletion={tabCompletion} formContent={formContent}
                            applyPageNav={applyPageNav} tab={tab} section={section} allTabCompleted={allTabCompleted} goToSubmit={goToSubmit} />
                    </Grid>
                    <Grid item xs={12} md={9} sx={{
                        paddingLeft: { xs: 0, md: '1rem' }
                    }}>

                        <FormNavigation {...formNavigationProps} top />

                        <Box
                            ref={formRef}
                            component="form"
                            noValidate
                            sx={{
                                background: "#ffffff",
                                borderRadius: "2rem",
                                padding: {
                                    xs: "2rem",
                                    md: "min(8%, 2rem) min(8%, 4rem)",
                                }
                            }}
                            onSubmit={submitAnswers}
                        >

                            {tab >= 0 ?
                                <PageContent
                                    loading={loading}
                                    setLoading={setLoading}
                                    setFormUpdated={setFormUpdated}
                                    currentAnswers={currentAnswers}
                                    currentPageContent={currentPageContent}
                                    projectId={projectId}
                                    pageRefresh={pageRefresh}
                                    downloadFileFromDb={downloadFileFromDb}
                                    findQuestionType={findQuestionType}
                                // repeatedPages={repeatedPages}
                                />
                                :
                                <SubmitContent submitApplication={submitApplication} />
                            }


                        </Box>

                        {!loading &&
                            <FormNavigation {...formNavigationProps} />
                        }

                    </Grid>
                </Grid>

            </Container>
        </Container >
    )
}

const FormNavigation = memo((props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { submitAnswers, previousPage, nextPage, applyPageNav, formUpdated, allTabCompleted, goToSubmit, top, tab } = props;

    return <>
        {tab >= 0 &&
            <Box display={"flex"} sx={{ mt: top ? 0 : 3, mb: top ? 3 : 0 }}>
                <Box flex={1} display={'flex'} alignItems={"center"} sx={{ gap: "1rem", flexWrap: "wrap" }}>

                    {/* {previousPage && */}
                    <Box mr={"auto"}>
                        <Button disabled={!previousPage} disableElevation type="button" color="primary" variant='contained'
                            sx={{
                                fontWeight: 700, px: '4rem', py: '.5rem', fontSize: '.7rem',
                                display: { xs: 'none', lg: 'block' }
                            }}
                            onClick={() => { applyPageNav(previousPage) }}
                        >
                            <FormattedMessage id="previous" defaultMessage={"previous"} />

                        </Button>
                        <IconButton
                            disabled={!previousPage}
                            color="white" aria-label="next" component="label" sx={{
                                display: { xs: 'inline-flex', lg: 'none' },
                                lineHeight: 1,
                                backgroundColor: theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.light,
                                    color: "white",
                                }
                            }}
                            onClick={() => { applyPageNav(previousPage) }}
                        >
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Box>
                    {/* } */}

                    <Box ml={"auto"} mr={"auto"} display="flex" gap="1rem">
                        <Button
                            disabled={formUpdated && !formUpdated.current}
                            disableElevation type="submit"
                            onClick={submitAnswers}
                            color="green" variant='contained'
                            sx={{
                                fontWeight: 700, px: '4rem', py: '.5rem', fontSize: '.7rem',
                                "&.Mui-disabled": { color: "#ffffff", backgroundColor: theme.palette.green.main, opacity: .5 }
                            }}
                        >
                            <FormattedMessage id="save" defaultMessage={"save"} />

                        </Button>

                        <Tooltip title={!allTabCompleted ? intl.formatMessage({ id: "missingTab", defaultMessage: "Some required questions have not been answered." }) : ""} placement="top">
                            <Box>
                                <Button
                                    disabled={!allTabCompleted}
                                    disableElevation type="button"
                                    onClick={goToSubmit}
                                    color="orange" variant='contained'
                                    sx={{
                                        fontWeight: 700, px: '2rem', py: '.5rem', fontSize: '.7rem',
                                        "&.Mui-disabled": { color: "#ffffff", backgroundColor: theme.palette.orange.main, opacity: .5 }
                                    }}
                                >
                                    <FormattedMessage id="submitParticipate" defaultMessage={"Submit"} />
                                </Button>
                            </Box>
                        </Tooltip>

                    </Box>
                    {/* {nextPage && */}
                    <Box ml={"auto"}>
                        <Button disabled={!nextPage} disableElevation type="button" color="primary" variant='contained'
                            sx={{
                                fontWeight: 700, px: '4rem', py: '.5rem', fontSize: '.7rem',
                                display: { xs: 'none', lg: 'block' }
                            }}
                            onClick={() => { applyPageNav(nextPage) }}
                        >
                            <FormattedMessage id="next" defaultMessage={"next"} />

                        </Button>
                        <IconButton
                            disabled={!nextPage}
                            color="white" aria-label="next" component="label" sx={{
                                display: { xs: 'inline-flex', lg: 'none' },
                                lineHeight: 1,
                                backgroundColor: theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.light,
                                    color: "white",
                                }
                            }} onClick={() => { applyPageNav(nextPage) }}
                        >
                            <NavigateNextIcon />
                        </IconButton>
                    </Box>
                    {/* } */}
                </Box>
            </Box>
        }
    </>
})

// const FormNavigation = (props) => {
//     const theme = useTheme();
//     const intl = useIntl();
//     const { submitAnswers, previousPage, nextPage, applyPageNav, formUpdated, allTabCompleted, goToSubmit, top, tab } = props;

//     return <>
//         {tab >= 0 &&
//             <Box display={"flex"} sx={{ mt: top ? 0 : 3, mb: top ? 3 : 0 }}>
//                 <Box display={'flex'} sx={{ gap: "1rem", flexWrap: "wrap" }}>

//                     <Button
//                         disabled={formUpdated && !formUpdated.current}
//                         disableElevation type="submit"
//                         onClick={submitAnswers}
//                         color="green" variant='contained'
//                         sx={{
//                             fontWeight: 700, px: '4rem', py: '.5rem', fontSize: '.7rem',
//                             "&.Mui-disabled": { color: "#ffffff", backgroundColor: theme.palette.green.main, opacity: .5 }
//                         }}
//                     >
//                         <FormattedMessage id="save" defaultMessage={"save"} />

//                     </Button>

//                     <Tooltip title={!allTabCompleted ? intl.formatMessage({ id: "missingTab", defaultMessage: "Some required questions have not been answered." }) : ""} placement="top">
//                         <Box>
//                             <Button
//                                 disabled={!allTabCompleted}
//                                 disableElevation type="button"
//                                 onClick={goToSubmit}
//                                 color="orange" variant='contained'
//                                 sx={{
//                                     fontWeight: 700, px: '2rem', py: '.5rem', fontSize: '.7rem',
//                                     "&.Mui-disabled": { color: "#ffffff", backgroundColor: theme.palette.orange.main, opacity: .5 }
//                                 }}
//                             >
//                                 <FormattedMessage id="submitParticipate" defaultMessage={"Submit"} />
//                             </Button>
//                         </Box>
//                     </Tooltip>
//                 </Box>
//                 <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: "center", gap: '1rem' }}>

//                     {previousPage &&
//                         <>
//                             <Button disableElevation type="button" color="primary" variant='contained'
//                                 sx={{
//                                     fontWeight: 700, px: '4rem', py: '.5rem', fontSize: '.7rem',
//                                     display: { xs: 'none', md: 'block' }
//                                 }}
//                                 onClick={() => { applyPageNav(previousPage) }}
//                             >
//                                 <FormattedMessage id="previous" defaultMessage={"previous"} />

//                             </Button>
//                             <IconButton color="white" aria-label="next" component="label" sx={{
//                                 display: { xs: 'inline-flex', md: 'none' },
//                                 lineHeight: 1,
//                                 backgroundColor: theme.palette.primary.main
//                             }}
//                                 onClick={() => { applyPageNav(previousPage) }}
//                             >
//                                 <NavigateBeforeIcon />
//                             </IconButton>
//                         </>
//                     }
//                     {nextPage &&
//                         <>
//                             <Button disableElevation type="button" color="primary" variant='contained'
//                                 sx={{
//                                     fontWeight: 700, px: '4rem', py: '.5rem', fontSize: '.7rem',
//                                     display: { xs: 'none', md: 'block' }
//                                 }}
//                                 onClick={() => { applyPageNav(nextPage) }}
//                             >
//                                 <FormattedMessage id="next" defaultMessage={"next"} />

//                             </Button>
//                             <IconButton color="white" aria-label="next" component="label" sx={{
//                                 display: { xs: 'inline-flex', md: 'none' },
//                                 lineHeight: 1,
//                                 backgroundColor: theme.palette.primary.main
//                             }} onClick={() => { applyPageNav(nextPage) }}
//                             >
//                                 <NavigateNextIcon />
//                             </IconButton>
//                         </>
//                     }
//                 </Box>
//             </Box>
//         }
//     </>
// }

const SubmitContent = memo((props) => {
    const intl = useIntl();
    const { submitApplication } = props
    const [checkBoxValue, setCheckBoxValue] = useState(false);

    return <Box display="flex" flexDirection={"column"} justifyContent={"center"}>
        <Typography variant="h2" color="secondary" display={"flex"} sx={{ alignItems: "center" }}>
            <Box display={"inline-flex"} borderRadius={'50%'} p={1.5} >
                <ValidFilledIcon color={"green"} sx={{ fontSize: "2.5rem" }} />
            </Box>
            <FormattedMessage id="submitYourApplication" defaultMessage="Submit your application" />
        </Typography>

        <Box sx={{ pl: "calc(2.5rem + 2*12px)", }}>
            <Typography sx={(theme) => {
                return {
                    color: theme.palette.gray.main,
                    mt: 2, mb: 3,
                    fontSize: ".9rem",
                    "& a": {
                        color: theme.palette.secondary.main,
                        textDecoration: 'underline'
                    }
                }
            }} >
                <FormattedMessage id="submitFormMessage" defaultMessage="Your application form is now complete and ready to be submitted. Once you have submitted your application, you will be able to download it in .pdf format. Our team will review your application and let you know the answer by email." />
            </Typography>
            <Typography sx={(theme) => {
                return {
                    color: theme.palette.secondary.main,
                    mt: 2,
                    fontSize: "1rem",
                    fontWeight: 700
                }
            }} >
                <FormattedMessage id="warning" defaultMessage="Warning" />
            </Typography>
            <Typography sx={(theme) => {
                return {
                    color: theme.palette.gray.main,
                    mb: 3,
                    fontSize: ".9rem",
                    "& a": {
                        color: theme.palette.secondary.main,
                        textDecoration: 'underline'
                    }
                }
            }} >
                <FormattedMessage id="submitFormWarning" defaultMessage="Before submitting your application, be sure all information is correct. You will not be able to edit your submission once sent." />
            </Typography>

            <CheckBox
                id="validInformation"
                label={intl.formatMessage({ id: "submitConfirm", defaultMessage: "I confirm all my information is correct and complete" })}
                value={checkBoxValue}
                setValue={setCheckBoxValue}
                optional
            />
        </Box>
        <Button
            disabled={!checkBoxValue}
            disableElevation type="button"
            onClick={submitApplication}
            color="green" variant='contained'
            sx={{
                display: "block",
                margin: 'auto',
                mt: 2,
                fontWeight: 700, px: '2.5rem', py: '.8rem', fontSize: '.9rem',
            }}
        >
            <FormattedMessage id="submitMyApplication" defaultMessage="Submit my application" />

        </Button>
    </Box >
})

export default Participate;
export { computeTabCompletion }