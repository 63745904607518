import { useCallback } from "react";

import InputText from "./inputText";
import CheckBox from "./checkBox";
import InputRadio from "./radio";
import InputFile from "./file";
import InputSelect from "./select";
import InputMultiSelect from "./multiselect";
import InputDate from "./date";
import InputSelectCountry from "./selectCountry";
import InputSelectPhone from "./selectPhone";
import Instructions from "./instructions";
import InputAutoselect from "./Autoselect";
import Box from '@mui/material/Box';


const QuestionContent = (props) => {
    const { question, setAnswer, answer, error } = props;

    const setValue = useCallback((value) => {
        setAnswer(curAnswer => ({ ...curAnswer, [question.id]: value }))
    }, [question.id, setAnswer])

    if (["text", "textarea", "number", "email"].includes(question.type)) {
        return <InputText {...question} value={answer} setValue={setValue} error={error} />
    }
    if (["instruction"].includes(question.type)) {
        return <Instructions {...question} value={answer} setValue={setValue} />
    }
    if (["checkbox"].includes(question.type)) {
        return <CheckBox {...question} value={answer} setValue={setValue} error={error} />
    }
    if (["radio"].includes(question.type)) {
        return <InputRadio {...question} value={answer} setValue={setValue} error={error} />
    }
    if (["file"].includes(question.type)) {
        return <InputFile {...question} value={answer} setValue={setValue} downloadFileFromDb={props.downloadFileFromDb} error={error} />
    }
    if (["select"].includes(question.type)) {
        return <InputSelect {...question} value={answer} setValue={setValue} error={error} />
    }
    if (["autoselect"].includes(question.type)) {
        return <InputAutoselect {...question} value={answer} setValue={setValue} light error={error} />
    }
    if (["multiselect"].includes(question.type)) {
        return <InputMultiSelect {...question} value={answer} setValue={setValue} error={error} />
    }
    if (["date"].includes(question.type)) {
        return <InputDate {...question} value={answer} setValue={setValue} error={error} />
    }
    if (["select_country"].includes(question.type)) {
        return <InputSelectCountry {...question} value={answer} setValue={setValue} light error={error} />
    }
    if (["select_phone"].includes(question.type)) {
        // label,
        // phoneId, phoneValue, setPhoneValue,
        // phoneExtId, phoneExtValue, setPhoneExt,
        // error

        const phoneId = `${question.id}_phone`;
        const phoneExtId = `${question.id}_phoneExt`;

        const setPhoneExt = (value) => {
            setAnswer(curAnswer => ({ ...curAnswer, [phoneExtId]: value }))
        }
        const setPhoneValue = (value) => {
            setAnswer(curAnswer => ({ ...curAnswer, [phoneId]: value }))
        }

        const phoneValue = props.phone;
        const phoneExtValue = props.ext;


        return <InputSelectPhone {...question} skipPhoneExtDetection light
            {...{ phoneId, phoneValue, setPhoneValue, phoneExtId, phoneExtValue, setPhoneExt }}
            error={error}
        />
    }

    return (
        <div> Unknown question {question.type}</div>
    )
}
const Question = (props) => {
    const { question } = props;

    return <Box sx={{
        gridColumn: {
            xs: "1/-1",
            sm: question.half ? "span 6" : "span 12"
        }
    }}>
        <QuestionContent question={question} {...props} />
    </Box>
}

export default Question