import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import SnackContext from "ShowSnackbar";

import Loader from "components/loader";
import Auth, { AuthContext } from "backendConnectors/auth";

import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ValidFilledIcon from "components/icons/ValidFilled";


const RequestValidationEmail = () => {
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!authContext.init) return

        if (!authContext.user) {
            return navigate('/login', { replace: true })
        }
        if (authContext.user && authContext.user.emailVerified) {
            return navigate('/', { replace: true })
        }
        setLoaded(true)
    }, [navigate, authContext.init, authContext.user])


    return (<>
        {!loaded ?
            // <Loader startColor="#ffffffbb" stopColor="#ffffff50" />
            <Loader />
            :
            <RequestValidationFrame />

        }
    </>)
}

export default RequestValidationEmail;

const RequestValidationFrame = () => {
    const intl = useIntl();
    const theme = useTheme()
    const navigate = useNavigate();
    const [emailRequested, setEmailRequested] = useState(false);
    const [requestingEmail, setRequestingEmail] = useState(false);

    const { user } = useContext(AuthContext);

    const { createSnack } = useContext(SnackContext)

    const requestEmail = useCallback(async () => {
        setRequestingEmail(true)
        if (!user) {
            return navigate('/login', { replace: true })
        }
        try {
            const response = await Auth.SendValidationEmail();
            if (response.success) {
                setEmailRequested(true)
            }
            else {

                const mess = response.message || intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' })
                createSnack(mess, { severity: 'error' })
            }
        }
        catch (e) {
            console.error(e)
            createSnack(intl.formatMessage({ id: "somethingWrong", defaultMessage: 'Something went wrong\nPlease try again' }), { severity: 'error' })
        }
        setRequestingEmail(false)
    }, [createSnack, user, navigate, intl])

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4, display: "flex", flexDirection: 'column' }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%", flex: 1, display: "flex", flexDirection: 'column' }}>
                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    <FormattedMessage id="emailValidation" defaultMessage={"Email validation"} />
                </Typography>
                <Box className="dotted" sx={{ my: 4, }}></Box>

                {requestingEmail ? <Loader /> ://<Loader startColor="#ffffffbb" stopColor="#ffffff50" /> :
                    <Container disableGutters maxWidth="md">
                        <Box className="rounded-corner" color={theme.palette.gray.main} sx={{ backgroundColor: "#ffffff", px: "10%", py: 4 }}>
                            {emailRequested ?
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box display={"inline-flex"} >
                                        <ValidFilledIcon color={"green"} sx={{ fontSize: '3rem' }} />
                                    </Box>
                                    <Typography sx={{ lineHeight: 1.75, pl: 2 }}>
                                        <FormattedMessage id="emailValidationMessage" defaultMessage={"A validation email has been sent to your email address."} />
                                        <br />
                                        <FormattedMessage id="emailValidationCheckSpam" defaultMessage={"Please check your spam folder before making a new request."} />

                                    </Typography>
                                </Box>
                                :
                                <>
                                    <Typography sx={{ lineHeight: 1.75 }}>

                                        <FormattedMessage id="emailValidationRequired" defaultMessage={"You need to validate your email address to apply to our calls."} />
                                        <br />
                                        <FormattedMessage id="emailValidationRequest" defaultMessage={"If you have not received a validation email, you can request one."} />
                                    </Typography>

                                    <Button
                                        color='green'
                                        sx={{ mt: 4, borderRadius: 2, textTransform: 'uppercase', fontWeight: 700, px: 8 }}
                                        variant='contained'
                                        disableElevation
                                        type="submit"
                                        onClick={requestEmail}
                                    >
                                        <FormattedMessage id="emailValidationRequestButton" defaultMessage={"Send me an email"} />

                                    </Button>
                                </>
                            }
                        </Box>
                    </Container>
                }
            </Container>
        </Container>
    )
}
