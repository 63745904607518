import { useRef, useContext, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import SnackContext from "ShowSnackbar";

import { useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";

import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';


const byteSize = 1048576;

export const acceptedFileExtensions = ["xlsx", "xls", "doc", "docx", "ppt", "pptx", "pdf"];

const InputFile = (props) => {
    const intl = useIntl();
    const { id, type, label, value, setValue, optional, error, downloadFileFromDb } = props
    const { createSnack } = useContext(SnackContext)

    const [downloading, setDownloading] = useState(false);

    const buttonRef = useRef();
    const inputRef = useRef();

    const inputValue = value || null

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }

    const checkFile = (event) => {
        if (inputValue && inputValue.storageRef) {
            // file cant not be changed when already set
            return
        }

        const file = event.target.files[0]

        if (file && file.size > (10 * byteSize)) {
            createSnack(intl.formatMessage({ id: "fileSizeTooLarge", defaultMessage: "File size too large" }), { severity: 'error' });
            return
        };
        setInputValue(file)
    }

    const clickButton = () => {
        if (inputValue) return

        if (buttonRef.current) {
            buttonRef.current.click()
        }
    }

    const deleteFile = () => {
        if (inputRef.current && inputRef.current.files) {
            const emptyFiles = new DataTransfer()
            inputRef.current.files = emptyFiles.files;

            setInputValue(null)
        }

    }

    const downloadFile = async () => {
        // if (!inputValue.fileref) return;
        if (typeof (inputValue) !== "string") return;
        if (downloading) return;

        setDownloading(true)
        try {
            downloadFileFromDb(id);
        }
        catch {
            // something went wrong
        }
        setDownloading(false)
    }

    return (
        <FormControl error={!!error} variant="standard" color="primary" fullWidth sx={{ mt: 2 }}>
            <FormGroup>
                <InputLabel className="question-title" htmlFor={`${id}`} sx={{ whiteSpace: "pre-line", transform: "none", position: 'relative', fontSize: "0.8rem", mb: "8px" }}>{label}{!optional && "*"}</InputLabel>

                <Box width={'100%'} display="grid" gridAutoFlow={{ xs: 'row', md: 'column' }} gridTemplateColumns={{ xs: '1fr', md: '1fr auto' }} alignItems={"center"}>

                    <Box flex="1" sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        height: "calc((1.75 * 0.8rem) + (2 * 6px)) ",
                        cursor: inputValue ? 'initial' : 'pointer', alignSelf: "stretch",
                        borderWidth: "1px", borderColor: theme.palette.gray.main, borderStyle: 'solid',
                        borderRadius: { xs: "0.6rem 0.6rem 0 0", md: "0.6rem 0 0 0.6rem" }
                    }} onClick={clickButton}>
                        {inputValue &&
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                gap={4}
                                sx={{ px: 2, height: '100%' }}>

                                {typeof (inputValue) == "string" ?
                                    <Button type="button" variant="text" sx={{ padding: 0, textTransform: "none", color: "#ffffff" }}
                                        onClick={downloadFile}
                                    >
                                        <Typography sx={{
                                            bgcolor: theme.palette.primary.main,
                                            fontSize: '.8rem',
                                            px: 4,
                                            py: '4px',
                                            lineHeight: 1.2,
                                            borderRadius: '8px',
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            {inputValue}
                                        </Typography>
                                    </Button>
                                    :
                                    <Typography sx={{
                                        bgcolor: theme.palette.gray.light,
                                        fontSize: '.8rem',
                                        px: 4,
                                        py: '4px',
                                        lineHeight: 1.2,
                                        borderRadius: '8px',
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {inputValue.name}
                                    </Typography>
                                }
                                <IconButton onClick={deleteFile} sx={{
                                    // bgcolor: theme.palette.gray.light,
                                    bgcolor: typeof (inputValue) == "string" ? theme.palette.primary.main : theme.palette.gray.light,
                                    color: typeof (inputValue) == "string" ? "#ffffff" : theme.palette.gray.main,
                                    "&:hover": {
                                        bgcolor: typeof (inputValue) == "string" ? theme.palette.primary.light : theme.palette.gray.main,
                                        color: "#ffffff"
                                    },
                                    p: '4px',
                                }} >
                                    <CloseIcon sx={{ fontSize: '.8rem' }} />
                                </IconButton>
                            </Box>
                        }
                    </Box>

                    <Box >
                        <TextField
                            id={`${id}_value`}
                            name={`${id}_value`}
                            sx={{ display: 'none' }}
                            inputProps={{
                                value: JSON.stringify(inputValue)
                            }}
                        />
                        <Button variant="contained" component="label" disableElevation ref={buttonRef}
                            disabled={!!inputValue}
                            sx={{
                                bgcolor: theme.palette.primary.light,
                                fontSize: "0.8rem",
                                textTransform: 'none',
                                px: "2rem",
                                width: "100%",
                                borderRadius: { xs: "0 0 0.6rem 0.6rem", md: '0 0.6rem 0.6rem 0' }
                            }}>
                            <Input
                                id={`${id}`}

                                // value={inputValue ? (inputValue.storageRef ? inputValue.filename : inputValue.name) : ''}
                                onChange={(event) => checkFile(event)}
                                disableUnderline
                                sx={{ pt: 1, mt: '0!important', "&.Mui-focused .MuiInput-input": { borderColor: theme.palette.primary.main } }}
                                type={type}


                                name={id}
                                className="input-border-radius"
                                inputProps={{
                                    ref: inputRef,
                                    accept: (acceptedFileExtensions.map(af => `.${af}`)).join(", "),
                                    hidden: true,
                                    sx: {
                                        display: 'none', px: '10px', py: '5px', border: `1px solid ${theme.palette.gray.main}`,
                                        // borderRadius: ".6rem" 
                                    }
                                }}
                            />
                            <FormattedMessage id="selectFile" default="Select file" />
                        </Button>

                    </Box>
                </Box>
            </FormGroup>

        </FormControl >
    )
}

export default InputFile;