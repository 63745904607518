import React, { createContext, useState, useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SnackContext = createContext()

function RenderSnack({ id, message, open, handleClose, options }) {
    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={options.severity ?? "success"} sx={{ width: '100%', whiteSpace: "pre-line" }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

let uniqueId = 2

export const SnackProvider = ({ children }) => {
    const [{ current, queue }, setState] = useState({ current: null, queue: [] })

    const createSnack = useCallback((message, options) => {
        const id = uniqueId++
        const snack = {
            id, message, open: true, options: options ?? {}
        }

        setState(curSnacks => {
            if (curSnacks.current) {
                return { current: curSnacks.current, queue: curSnacks.queue.concat(snack) }
            }
            else {
                return { current: snack, queue: curSnacks.queue }
            }
        })
        return id
    }, []);

    const openNext = () => {
        if (queue.length) {
            setState({ current: queue[0], queue: queue.slice(1) })
        } else {
            setState({ current: null, queue: [] })
        }
    }

    const handleClose = () => {
        setState((currentState) => ({
            ...currentState,
            current: { ...currentState.current, open: false },
        }))
        // time to snack close animation
        setTimeout(openNext, 500)
    }



    return (
        <SnackContext.Provider value={{ createSnack }}>
            {current && <RenderSnack key={current.id} {...current} handleClose={handleClose} />}
            {children}
        </SnackContext.Provider>
    )
}

export default SnackContext;