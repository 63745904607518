import { useEffect, useState, createContext, useCallback } from "react";

const FormsContext = createContext();

const prepareFormDate = (form) => {
    form.status.deadline = form.status.deadline ? new Date(Date.parse(form.status.deadline)) : null
    form.status.openingDate = form.status.openingDate ? new Date(Date.parse(form.status.openingDate)) : null
}
const fetchForms = async () => {


    // const fetchParams = new FormData();
    // fetchParams.append('action', 'get_forms');

    // const formsResponse = await fetch(
    //     process.env.REACT_APP_BACKEND_AJAX_URL, {
    //     method: "post",
    //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     body: new URLSearchParams(fetchParams),
    // })
    const formsResponse = await fetch(
        process.env.REACT_APP_BACKEND_REST_URL + process.env.REACT_APP_BACKEND_REST_NAMESPACE + 'get_forms', {
        method: "get",
    })

    const formsJSON = await formsResponse.json()

    // map date
    formsJSON.forEach(f => prepareFormDate(f))

    // filter
    const forms = formsJSON.filter(f => !isNaN(f.status.openingDate) && !isNaN(f.status.deadline))

    // return forms;
    return forms.sort((f1, f2) => f1.status.deadline.getTime() - f2.status.deadline.getTime())
}
const FormsProvider = (props) => {

    const setForm = useCallback((formContent) => {

        if (!formContent.status || !formContent.status.formId) return;

        setForms((curForms) => {
            const newForms = curForms.forms || [];
            const fIndex = newForms.findIndex(f => f.status.formId === formContent.status.formId);

            if (fIndex >= 0) {
                newForms[fIndex] = formContent;
            }
            else {
                newForms.push(formContent)
            }

            return { ...curForms, forms: newForms, };
        })
    }, [])

    const [forms, setForms] = useState({
        read: false,
        forms: null,
        error: false,
        setForm,
    });

    useEffect(() => {
        let timer = null;
        fetchForms().then((forms) => {
            timer = setTimeout(() => {
                setForms(p => ({ ...p, forms, read: true, error: false }))
            }, 500)
        })

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, []);

    return <FormsContext.Provider value={forms}>{props.children}</FormsContext.Provider>;
};

export { FormsProvider, FormsContext, prepareFormDate }