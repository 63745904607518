import DOMPurify from 'dompurify'
import { useTheme } from '@mui/material/styles';
// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

const CheckBox = (props) => {
    const { id, label, text, value, setValue, optional, error } = props

    const inputValue = !!+value || false;

    const inputLabel = label + (!optional ? "*" : "")

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }
    return (
        <FormControl error={!!error} variant="standard" color="primary" fullWidth sx={{ mt: 2 }}>
            {text && <Typography color={theme.palette.gray.main} fontSize={"0.8rem"} sx={{ mb: 1 }}>{text}</Typography>}
            <FormControlLabel
                control={<Checkbox
                    size="small"
                    name={id}
                    id={id}
                    checked={inputValue}
                    onChange={(event) => setInputValue(event.target.checked)}
                    sx={{ pt: 0 }}
                />
                }
                label={
                    <Typography className="question-title" color={!!error ? theme.palette.error.main : theme.palette.gray.main} fontSize={"0.8rem"}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputLabel) }}
                    />
                }
                sx={{ alignItems: "flex-start" }}
            />

        </FormControl >
    )
}

export default CheckBox;