import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
    typography: {
        fontFamily: '"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.5rem',
        },
        h2: {
            fontSize: '1.5rem',
        },
        h3: {
            fontSize: '1.2rem',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: '"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif',
                },

            },
        },
    },
    palette: {
        primary: {
            // light: '#324E8F',
            main: "#184590",
        },
        secondary: {
            // light: '#B094C9',
            main: "#494d7a",

        },
        // error: {
        //     main: "#494d7a",
        // },
        white: {
            main: "#ffffff",
            contrastText: "#666666"
        },
        gray: {
            light: "#E2DFDF",
            main: "#666666",
            contrastText: "#ffffff"
        },
        orange: {
            main: "#e57d40",
            contrastText: "#ffffff"
        },
        green: {
            main: "#9cb94d",
            contrastText: "#ffffff",
        },
        pink: {
            main: "#494d7a",
            contrastText: "#ffffff"
        },
        lightBlue: {
            main: '#324E8F',
            contrastText: "#ffffff"
        },
        red: {
            main: '#e85957',
            contrastText: "#ffffff"
        }
    },
});

const MaterialTheme = (props) => {
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default MaterialTheme;