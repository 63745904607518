
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { FixedSizeList } from 'react-window';
import epci from 'media/data/epci.json';
import communes from 'media/data/communes.json';


const InputAutoselect = (props) => {

    const { id, options, label, text, value, setValue, optional, error } = props

    const basedOptions = options.split('\r\n');
    let formattedOptions = basedOptions;
    if (basedOptions && basedOptions[0] === '[EPCI]') {
        formattedOptions = epci
    }
    else if (basedOptions && basedOptions[0] === '[COMMUNES]') {
        formattedOptions = communes
    }

    const inputValue = value || null

    const theme = useTheme();

    const setInputValue = (value) => {
        setValue(value);
    }

    return (
        <Box sx={{ mt: '1rem' }} id={id}>
            {text && <Typography color={theme.palette.gray.main} fontSize={"0.8rem"} sx={{ mb: 1 }}>{text}</Typography>}

            <InputLabel className="question-title" htmlFor={`autoselect-${id}`} sx={{ width: '100%', whiteSpace: "pre-line", transform: "scale(0.75)", position: 'relative', fontSize: "1rem", color: !!error ? theme.palette.error.main : theme.palette.gray.main }}>
                {label}{!optional && "*"}
            </InputLabel>

            <Box
                className="input-border-radius"
                sx={{
                    display: "flex", flexWrap: "wrap", alignItems: 'center',
                    // borderRadius: '0.6rem',
                    border: `1px solid ${props.light ? theme.palette.gray.light : theme.palette.gray.main}`
                }}>


                <FormControl error={!!error} variant="standard" color="primary" sx={{ width: '100%', }}>
                    {/* <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        // inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="controllable-states-demo"
                        options={['Option 1', 'Option 2', 'AA', 'AB', 'BB', 'BC']}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Controllable" />}
                    /> */}
                    <Autocomplete
                        value={inputValue}
                        onChange={(_, newValue) => setInputValue(newValue)}
                        options={formattedOptions}
                        disableClearable

                        renderInput={(params) => {
                            return <>
                                {/* <TextField
                                    {...params}
                                    name={`${id}`}
                                    sx={{ display: 'none' }}
                                    inputProps={{
                                        value: params.inputProps.value
                                    }}
                                /> */}
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        name: id,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            </>
                        }}

                        sx={{
                            width: '100%',
                            "& .MuiOutlinedInput-root": {
                                paddingTop: '4px',
                                paddingBottom: '5px',

                            },
                            "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
                                paddingRight: '24px',
                            },
                            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment ': {
                                right: '5px'
                            },
                            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                                padding: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            },
                        }}

                    />

                </FormControl >

            </Box>
        </Box>
    )
}

export default InputAutoselect;