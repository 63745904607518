import SvgIcon from '@mui/material/SvgIcon';

const EditIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g transform="translate(-1.5 -1.318), scale(1.5)">
                <path d="M12.981,18.007H3.526A2.028,2.028,0,0,1,1.5,15.981V6.526A2.028,2.028,0,0,1,3.526,4.5H8.253a.675.675,0,1,1,0,1.351H3.526a.676.676,0,0,0-.675.675v9.455a.676.676,0,0,0,.675.675h9.455a.676.676,0,0,0,.675-.675V11.253a.675.675,0,0,1,1.351,0v4.727A2.028,2.028,0,0,1,12.981,18.007Z" transform="translate(0 -1.749)" />
                <path d="M19.279,1.318a2.108,2.108,0,0,1,1.491,3.6l-6.416,6.416a.675.675,0,0,1-.314.178l-2.7.675a.675.675,0,0,1-.819-.819l.675-2.7a.675.675,0,0,1,.178-.314l6.416-6.416A2.094,2.094,0,0,1,19.279,1.318Zm-5.748,8.927,6.283-6.283a.757.757,0,0,0-1.071-1.071L12.46,9.174,12.1,10.6Z" transform="translate(-4.948)" />
            </g>
        </SvgIcon>
    );
}

export default EditIcon;