import { useContext, useEffect, useState, memo } from "react";
import { useParams, useNavigate, Link } from "react-router-dom"
import { useTheme } from '@mui/material/styles';
import { useIntl, FormattedMessage } from "react-intl";

import DOMPurify from 'dompurify'

import { useDate } from "GetDate";
import { FormsContext } from "GetForms";
import { AuthContext } from 'backendConnectors/auth'
import Database from "backendConnectors/database";

import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Loader from "components/loader";
import { getCalls } from "components/callsList"
import CallItem from "components/callsList/callItem"
import { useComputeDelay, StartCallButton } from "components/callsList/callItem";

const CallItemMemo = memo(CallItem);

const Call = () => {
    const navigate = useNavigate();
    const { callId } = useParams();
    const { forms } = useContext(FormsContext);
    const authContext = useContext(AuthContext);
    const intl = useIntl();

    const computeDelay = useComputeDelay();
    // const [date, setDate] = useState(ServerDate.serverTime);
    // const createNewCall = useCreateNewCall();

    const date = useDate()

    const theme = useTheme();

    const [currentForm, setCurrentForm] = useState(null);

    const [openProjects, setOpenProjects] = useState(null);
    const [openProjectsRead, setOpenProjectsRead] = useState(false);

    useEffect(() => {
        const f = forms.filter(f => f.status.formId === callId);
        if (f.length) {
            setCurrentForm(f[0])
        }
        else {
            navigate('/', { replace: true })
        }
    }, [callId, forms, navigate])

    useEffect(() => {
        if (!authContext.init) return;

        if (!authContext.user) {
            setOpenProjects([])
            setOpenProjectsRead(true)
            return;
        }

        const getOpenProjects = async () => {
            try {
                const projects = await Database.getOpenProjects();

                if ('open_projects' in projects) {
                    setOpenProjects(projects.open_projects)
                    setOpenProjectsRead(true)
                }
            } catch (error) {
                console.error(error)
            }
        }
        getOpenProjects();

    }, [authContext.init, authContext.user])

    if (!currentForm)
        return <Loader />
    // return <Loader startColor="#ffffffbb" stopColor="#ffffff50" />

    // get filtered opened calls
    const openedCalls = getCalls(forms, date, true).filter(f => f.status.formId !== callId).slice(0, 2);
    // console.log(openedCalls)
    const formOpened = (currentForm.status.deadline > date) && (currentForm.status.openingDate < date)

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4 }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%" }}>
                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>
                    {currentForm.status.title}
                </Typography>
                <Box className="dotted" sx={{ my: 4 }}></Box>

                {/* external */}
                <Box sx={{ display: "flex", gap: '2rem', flexDirection: { xs: "column", lg: "row" } }}>

                    <Box className="bordered" sx={{ flex: { xs: 'auto', lg: '12' }, display: "flex", flexDirection: 'column', backgroundColor: "#ffffff", borderRadius: '1rem', overflow: 'hidden', pb: 3 }}>

                        <Box sx={{ display: "flex", flexWrap: 'wrap' }}>
                            <Box sx={{ display: "flex", }}>
                                <Box >
                                    {authContext.init && formOpened &&
                                        <StartCallButton formId={callId}
                                            openProjects={openProjects ?? []} projectsRead={openProjectsRead ?? false}
                                            sx={{ borderRadius: 0, fontWeight: 700, height: '100%', maxWidth: "100%" }}
                                        />
                                    }
                                </Box>
                                <Box
                                    bgcolor={formOpened ? theme.palette.green.main : theme.palette.pink.main}
                                    color="#ffffff"
                                    display="grid"

                                    sx={{ px: 3, py: 2, borderRadius: '0 0 1rem 0', placeItems: "center" }}
                                >
                                    <Typography sx={{ flex: 1, }}>{formOpened ? intl.formatMessage({ id: "open", defaultMessage: "Open" }) : intl.formatMessage({ id: "closed", defaultMessage: "Closed" })}</Typography>
                                </Box>
                            </Box>
                            <Typography color={theme.palette.orange.main} sx={{ alignSelf: "center", flex: 1, fontSize: ".8rem", textAlign: "right", whiteSpace: "nowrap", px: 3, py: 3 }}>
                                {computeDelay(currentForm.status.deadline, date)}
                            </Typography>
                        </Box>

                        <Box sx={{ px: 6 }}>
                            <Typography variant="h3" color={theme.palette.secondary.main} sx={{ mt: 3 }}>
                                <FormattedMessage id="description" defaultMessage={"Description"} />
                            </Typography>
                            <Box className="dotted purple" sx={{ my: 3 }}></Box>
                            {/* sx={{ whiteSpace: 'pre-line' }} */}
                            <Box color={theme.palette.gray.main}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentForm.status.description) }}
                            >

                            </Box>
                        </Box>
                    </Box>

                    {openedCalls.length > 0 &&
                        <Box sx={{ flex: { xs: 'auto', lg: '5' } }}>
                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                <Typography variant="h3" color={theme.palette.primary.main} >
                                    <FormattedMessage id="openCalls" defaultMessage={"Open calls"} />
                                </Typography>

                                <Link to="/">
                                    <Button color='primary' variant="text" disableElevation sx={{ textTransform: 'none', px: 4, py: 1, borderRadius: 0, fontWeight: 700, height: '100%' }}>
                                        <FormattedMessage id="all" defaultMessage={"All"} />&nbsp;{'>'}
                                    </Button>
                                </Link>
                            </Box>
                            <Box className="dotted" sx={{ my: 3 }}></Box>

                            <Box sx={{ display: 'grid', gap: '2rem', gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: '1fr' } }}>
                                {openedCalls.map(f => {
                                    return <CallItemMemo
                                        key={f.status.formId}
                                        formId={f.status.formId}
                                        deadline={f.status.deadline}
                                        date={date}
                                        opened={true}
                                        openProjects={openProjects}
                                        projectsRead={openProjectsRead}
                                        title={f.status.title}
                                        description={f.status.shortDescription ?? f.status.description}
                                    />
                                })}
                            </Box>
                        </Box>
                    }
                </Box>
            </Container>
        </Container >
    )
}


export default Call;


