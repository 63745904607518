
import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useNavigate, } from "react-router";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { AuthContext } from 'backendConnectors/auth'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AccountMenu from "./accountMenu";
import Loader from "components/loader";
import AccountDashboard from "./pages/dashboard";
import AccountCalls from "./pages/calls";
import AccountProfile from "./pages/profile";
import AccountChangePassword from "./pages/changePassword";

import ExclamationMarkIcon from "components/icons/ExclamationMark";



const MyAccount = () => {
    const intl = useIntl();
    const [accountPage, setAccountPage] = useState(0)
    const authContext = useContext(AuthContext)
    const navigate = useNavigate()

    const pages = useMemo(() => [
        { title: intl.formatMessage({ id: "dashboard", defaultMessage: "Dashboard" }), comp: (props) => <AccountDashboard {...props} /> },
        { title: intl.formatMessage({ id: "yourApplications", defaultMessage: "Your applications" }), comp: (props) => <AccountCalls {...props} /> },
        { title: intl.formatMessage({ id: "yourProfile", defaultMessage: "Your profile" }), comp: (props) => <AccountProfile {...props} /> },
        { title: intl.formatMessage({ id: "changePassword", defaultMessage: "Change password" }), comp: (props) => <AccountChangePassword {...props} /> },
    ], [intl])

    const setPageByName = useCallback((name) => {
        const pi = pages.findIndex((p) => p.title === name)

        if (pi >= 0) {
            setAccountPage(pi)
        }
    }, [pages])

    useEffect(() => {
        if (!authContext.init) return

        if (!authContext.user) {
            navigate('/', { replace: true })
        }
    }, [authContext.init, authContext.user, navigate])

    if (!authContext.init || !authContext.user) {
        return (
            // <Loader startColor="#ffffffbb" stopColor="#ffffff50" />
            <Loader />
        )
    }

    return (
        <Container maxWidth={false} disableGutters sx={{ flex: 1, pb: 4 }}>
            <Container disableGutters maxWidth="xl" sx={{ px: "10%" }}>
                <Typography variant="h1" color="primary.main" sx={{ mt: '.5rem', mb: '1.5rem' }}>

                    <FormattedMessage id="myaccount" defaultMessage="My account" />
                </Typography>
                <Box className="dotted" sx={{ my: 4 }}></Box>

                <Grid container>
                    <Grid item xs={12} md={3} sx={{
                        // position: { xs: 'absolute', md: 'relative' }, left: 0, top: { xs: '5rem', md: 0 },
                        paddingRight: { xs: 0, md: '1rem' },
                        // maxWidth: 'min(25%, 300px)!important'
                    }}>
                        <AccountMenu {...{ accountPage, setAccountPage, pages: pages.map(p => p.title) }} />

                        {/* <ParticipateMenu formUpdated={formUpdated} formContent={formContent} tab={tab} setTab={setTab} section={section} setSection={setSection} /> */}
                    </Grid>
                    <Grid item xs={12} md={9} sx={{
                        paddingLeft: {
                            xs: 0, md: '1rem'
                        }
                    }}>
                        {!authContext.user.emailVerified && <Box sx={{
                            background: 'white',
                            px: '3rem',
                            py: '2rem',
                            borderRadius: '1rem', overflow: 'hidden',
                            mb: 3,
                        }}>
                            <Box display={"flex"} sx={{
                                border: "1px solid #666666",
                                borderRadius: 5,
                                padding: 2,
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'center', sm: 'flex-start' },
                            }}>
                                <Box display={"inline-flex"} >
                                    <ExclamationMarkIcon color={"orange"} sx={{ fontSize: '2.5rem' }} />
                                </Box>

                                <Box>
                                    <Typography color={t => t.palette.gray.main}
                                        sx={{
                                            paddingLeft: { xs: 0, sm: 2 },
                                            paddingTop: { xs: 2, sm: 0 },
                                            textAlign: { xs: 'center', sm: 'left' },
                                        }}>
                                        <FormattedMessage id="needConfirm" defaultMessage="To apply for a call, you need to confirm your email address. We sent you a link to confirm your email address. If you didn’t receive it, please check your spam folder or:" />
                                    </Typography>

                                    <Link to={`/requestValidateEmail`}>
                                        <Button color="green" disableElevation variant="contained"
                                            // onClick={() => { setPageByName('Your profile') }}
                                            sx={{ mt: 2, fontWeight: 700, textTransform: 'uppercase', fontSize: '.8rem', px: '1.5rem', py: '.5rem', borderRadius: '.5rem' }}>

                                            <FormattedMessage id="resendEmail" defaultMessage="Resend Email" />
                                        </Button>
                                    </Link>
                                </Box>


                            </Box>
                        </Box>}


                        <Box sx={{
                            background: 'white',
                            px: '3rem',
                            py: '2rem',
                            borderRadius: '1rem', overflow: 'hidden'
                        }}>
                            {pages[accountPage].comp({ setPageByName })}
                        </Box>

                    </Grid>
                </Grid>

            </Container>
        </Container>
    )
}
export default MyAccount;