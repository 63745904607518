import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext } from 'react-router-dom';

function useConfirmExit(confirmExit, when = true) {
    const { navigator } = useContext(UNSAFE_NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const push = navigator.push;

        navigator.push = async (...args) => {
            const result = await confirmExit();
            if (result !== false) {
                push(...args);
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}

export default useConfirmExit;