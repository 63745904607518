import SvgIcon from '@mui/material/SvgIcon';

const EditPageIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g transform="translate(-344.25 -147.531)">
                <path d="M8.078.005,2.636,5.448.612,3.424,0,4.036,2.636,6.672,8.69.617Z" transform="translate(344.5 147.776)" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
                <line x2="11.856" transform="translate(355.462 151.476)" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" />
                <line x2="19.876" transform="translate(347.441 158.143)" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" />
                <line x2="19.876" transform="translate(347.441 164.809)" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" />
            </g>
        </SvgIcon>
    );
}

export default EditPageIcon;