
import { useState, useCallback, useEffect } from 'react';
import { useIntl, FormattedMessage } from "react-intl";

import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import EditPageIcon from "components/icons/EditPage";


const ParticipateMenu = (props) => {
    const theme = useTheme()
    const { applyPageNav, allTabCompleted, goToSubmit } = props;

    const [expanded, setExpanded] = useState(props.tab);

    const { tabCompletion } = props

    // const switchTabAndSection = useCallback(({ tabId, sectionId }) => {
    //     setTab(tabId)
    //     setSection(sectionId)
    //     setDrawerState(false);
    //     formUpdated.current = false
    // }, [setTab, setSection, formUpdated])

    useEffect(() => {
        setExpanded(props.tab)
    }, [props.tab])


    const handleChangeExpanded =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

    const setTabAndSection = useCallback((tabId, sectionId) => {
        if (tabId === props.tab && sectionId === props.section) return;

        applyPageNav({ tab: tabId, section: sectionId });
        setDrawerState(false);

        // if (formUpdated.current) {
        //     createConfirm(() => {
        //         switchTabAndSection({ tabId, sectionId })
        //     }, {
        //         title: "You have unsaved modifications",
        //         message: "Are you sure you want to leave thise page?"
        //     })
        //     return
        // }

        // switchTabAndSection({ tabId, sectionId })
    }, [applyPageNav, props.tab, props.section])


    const [drawerState, setDrawerState] = useState(false);
    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setDrawerState(open);
            };

    const tabs = (props.formContent.content || {}).tabs

    const menuElementsProps = {
        allTabCompleted,
        tabCompletion,
        tabs,
        expanded,
        currentTab: props.tab,
        currentSection: props.section,
        handleChangeExpanded,
        setTabAndSection,
        goToSubmit,
    }

    return (

        <>
            <IconButton onClick={toggleDrawer(true)} size="large"
                sx={{
                    marginLeft: 'calc(-20px - 0.5rem)',
                    padding: '20px',
                    fontSize: '1.5rem',
                    display: { xs: 'inline-flex', md: 'none' }, color: theme.palette.secondary.main, backgroundColor: "#cccccc"
                }} >
                <EditPageIcon fontSize="inherit" sx={{
                    transform: "translateX( calc(5px + .5rem))"
                }} />
            </IconButton>
            <Drawer
                anchor="left"
                open={drawerState}
                onClose={toggleDrawer(false)}
                PaperProps={{ sx: { padding: '1rem 2rem' }, className: "" }}
            >
                <Box textAlign={"right"} position="sticky" top={0} zIndex={10} >
                    <IconButton onClick={toggleDrawer(false)} size="large"
                        sx={{

                            padding: '10px',
                            fontSize: '1.2rem',
                            color: theme.palette.secondary.main,
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: theme.palette.primary.main,
                            backgroundColor: "#ffffff"
                        }}
                    >
                        <MenuOpenIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                <MenuElements  {...menuElementsProps} withBorders />
            </Drawer>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {/* Desktop accordion */}
                <MenuElements {...menuElementsProps} withBorders />
            </Box >

        </>
    )
}

const MenuElements = ({ tabCompletion, allTabCompleted, tabs, expanded, currentTab, currentSection, handleChangeExpanded, setTabAndSection, goToSubmit, withBorders }) => {
    const theme = useTheme();
    const intl = useIntl();

    return (
        <>
            {
                tabs && tabs.map((t, tid) => {

                    const tComplete = tabCompletion[tid]


                    const isExpanded = (expanded === tid)// || (currentTab === tid)
                    // const isFilled = tid === 2
                    const isFilled = tComplete.indexOf(false) < 0;

                    return (

                        < Accordion key={t.title.replaceAll(' ', '_')} expanded={isExpanded}
                            onChange={handleChangeExpanded(tid)}
                            sx={{
                                background: 'transparent',
                                '&:before': {
                                    display: 'none',
                                }
                            }}
                            elevation={0}

                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                aria-controls={`tab${tid}panel1bh-content`}
                                id={`tab${tid}-header`}
                                className="tab__menu"
                                sx={{
                                    backgroundColor: isFilled ? theme.palette.green.main : theme.palette.primary.main,
                                    color: '#ffffff',
                                    marginTop: tid === 0 ? 0 : '1rem',
                                    // "&:first-child": {
                                    //     marginTop: 0,
                                    // },
                                    minHeight: 'auto',
                                    '&.Mui-expanded': {
                                        minHeight: 'auto'
                                    },
                                    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
                                        margin: '.8rem 0',
                                    },
                                    borderRadius: isExpanded ? 'calc(1/2*(1.2 * .8rem + 2*0.8rem)) calc(1/2 * (1.2 * .8rem + 2*0.8rem)) 0 0' : "calc(1/2*(1.2 * .8rem + 2*0.8rem))",
                                }}
                            >
                                <Typography fontWeight={700} lineHeight={1.2} fontSize={'.8rem'}>
                                    {t.title}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails
                                className={`${withBorders ? 'bordered' : ''}`}
                                sx={{
                                    padding: 0, background: "#ffffff", borderBottomLeftRadius: '2rem', borderBottomRightRadius: '2rem', overflow: "hidden",
                                    // border: withBorders ? `1px solid ${theme.palette.primary.main}` : '',
                                    borderTop: 'none'
                                }}>

                                <List component="nav" aria-label="Form navigation" sx={{ padding: 0 }}>
                                    {t.sections && t.sections.map((s, sid) => {
                                        // const secIsFilled = sid === 2;
                                        const secIsFilled = tComplete[sid];

                                        return (<ListItemButton
                                            key={s.title.replaceAll(' ', '')}
                                            selected={(currentTab === tid) && (currentSection === sid)}
                                            onClick={() => setTabAndSection(tid, sid)}
                                            sx={{
                                                '&.Mui-selected': {
                                                    backgroundColor: secIsFilled ? theme.palette.green.main : theme.palette.orange.main,
                                                    color: "#ffffff"
                                                },
                                                color: secIsFilled ? theme.palette.green.main : theme.palette.secondary.main,
                                                // paddingBlock: '6px'
                                            }}
                                        >
                                            <ListItemText className="tab_menu__list_item" primaryTypographyProps={{ fontSize: '0.8rem', fontWeight: secIsFilled ? 700 : 400 }} >
                                                &bull;{" "}{s.title}
                                            </ListItemText>
                                            {secIsFilled && <ListItemIcon sx={{ minWidth: 0, color: "inherit" }}>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            }
                                        </ListItemButton>)
                                    })
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion >
                    )
                })
            }

            <Tooltip title={!allTabCompleted ? intl.formatMessage({ id: "missingTab", defaultMessage: "Some required questions have not been answered." }) : ""} placement="top">
                <Box sx={{ mt: '1rem', }}>
                    <Button type="submit" variant="contained" color="orange" disableElevation disabled={!allTabCompleted}
                        onClick={goToSubmit}
                        sx={{
                            lineHeight: 1.2, fontSize: '.8rem', textTransform: "none", width: "100%", px: "1rem", py: ".8rem",
                            fontWeight: 700, borderRadius: "calc(1/2*(1.2 * .8rem + 2*0.8rem))", justifyContent: "flex-start",
                            "&.Mui-disabled": {
                                color: "#ffffff",
                                backgroundColor: theme.palette.orange.main,
                                opacity: .5,
                            },
                        }}
                    >

                        <FormattedMessage id="submitYourApplication" defaultMessage="Submit your application" />
                    </Button>
                </Box>
            </Tooltip >
        </>
    )
}


export default ParticipateMenu;