import SvgIcon from '@mui/material/SvgIcon';

const UserBoldIcon = (props) => {
    return (
        <SvgIcon {...props}>

            <path d="M30.123,31.3a1.208,1.208,0,0,1-1.208-1.208,8,8,0,0,0-16,0,1.208,1.208,0,0,1-2.417,0,10.446,10.446,0,0,1,6.34-9.6,5.808,5.808,0,0,1-1.658-2.562,6.249,6.249,0,0,1-.01-3.668,6.062,6.062,0,0,1,2.01-3.006A5.867,5.867,0,0,1,20.889,10h.029a5.934,5.934,0,0,1,5.76,4.317A5.906,5.906,0,0,1,24.993,20.5a10.446,10.446,0,0,1,6.338,9.6A1.208,1.208,0,0,1,30.123,31.3ZM20.916,21.1a9,9,0,0,1,9,9,.207.207,0,0,0,.061.147.208.208,0,0,0,.295,0,.207.207,0,0,0,.061-.147,9.444,9.444,0,0,0-6.507-8.968l-.982-.321.862-.571a4.9,4.9,0,0,0,2-2.583,5.181,5.181,0,0,0,.017-3.049,5.093,5.093,0,0,0-1.688-2.538A4.959,4.959,0,0,0,20.913,11h-.025a4.877,4.877,0,0,0-3.083,1.042,5.066,5.066,0,0,0-1.677,2.514,4.96,4.96,0,0,0,2,5.679l.858.571-.979.32A9.444,9.444,0,0,0,11.5,30.1a.207.207,0,0,0,.061.147.208.208,0,0,0,.295,0,.207.207,0,0,0,.061-.147,9,9,0,0,1,9-9Zm.01-.431h-.01a4.624,4.624,0,0,1,0-9.249h.02a4.709,4.709,0,0,1,3.428,1.515,4.765,4.765,0,0,1,0,6.213,4.713,4.713,0,0,1-3.429,1.52Zm-.02-8.249a3.624,3.624,0,0,0,0,7.249,3.735,3.735,0,0,0,2.713-1.185,3.765,3.765,0,0,0,0-4.882A3.731,3.731,0,0,0,20.906,12.416Z" transform="translate(-10.5 -10)" fill="currentColor" />
        </SvgIcon>
    );
}

export default UserBoldIcon;